import { api } from '@/plugins/api';
import { useAuth } from '@/auth';
import { ref } from 'vue';

export function useSubscription() {
    const { user, organization } = useAuth()
    const subscription = organization?.subscription || user?.subscription
    const billingPeriod = ref(subscription?.billing_period)
    const plan = ref(subscription?.plan)
    const startDate = ref(subscription?.start_date)
    const endDate = ref(subscription?.end_date)
    const status = ref(subscription?.status)
    const quantity = ref(subscription?.quantity)
    const features = ref(subscription?.settings?.features || {})
    const usage = ref({})

    async function updateSubscription(config) {
        const response = await api.post('/orders/checkout', config)
        if (response.data && response.data?.id === undefined) window.location.href = response.data;
        billingPeriod.value = response.data.billing_period
        plan.value = response.data.plan
        startDate.value = response.data.start_date
        endDate.value = response.data.end_date
        status.value = response.data.status
        quantity.value = response.data.quantity
    }

    const fetchUsage = async () => {
        try {
            const meters = (await api.get('/usage/meters')).data;
            for (const meter of meters) {
                usage.value[meter.name] = { used: meter.used, limit: meter.limit }
            }
        } catch (error) {
            console.error('Error fetching usage data:', error);
        }
    };

    async function isOutOfCreditsFor(name) {
        await fetchUsage()
        return usage.value[name].used >= usage.value[name].limit
    }

    return {
        subscription: {
            billingPeriod,
            plan,
            startDate,
            endDate,
            status,
            quantity,
            features,
            usage
        },
        updateSubscription,
        fetchUsage,
        isOutOfCreditsFor
    }
}