<script setup>
import { EditableArea, EditableCancelTrigger, EditableEditTrigger, EditableInput, EditablePreview, EditableRoot, EditableSubmitTrigger } from 'radix-vue'
import { defineModel, defineProps, defineEmits } from 'vue';
import { Button } from '@/components/ui/button';
import { PencilLineIcon, CheckIcon, XIcon } from 'lucide-vue-next';


defineProps({
  placeholder: {
    type: String,
    required: false,
    default: 'Add a description...'
  }
});

const description = defineModel({ type: String, default: '' });
const emit = defineEmits(['save']);

const onSubmit = (value) => {
  description.value = value;
  emit('save', value);
}


</script>

<template>
  <EditableRoot v-slot="{ isEditing }" :default-value="description" @submit="onSubmit"
    placeholder="Add a description..." class="flex flex-row gap-2 items-center group" submit-mode="both" auto-resize>
    <EditableEditTrigger v-if="!isEditing" as-child>
      <Button variant="link" size="icon" class="btn">
        <PencilLineIcon />
      </Button>
    </EditableEditTrigger>
    <div v-else class="flex gap-2">
      <EditableSubmitTrigger as-child>
        <Button variant="link" size="icon" class="btn">
          <CheckIcon />
        </Button>
      </EditableSubmitTrigger>
      <EditableCancelTrigger as-child>
        <Button variant="link" size="icon" class="btn">
          <XIcon />
        </Button>
      </EditableCancelTrigger>
    </div>
    <EditableArea>
      <EditablePreview class="text-muted-foreground" />
      <EditableInput class="w-full placeholder:text-muted-foreground" />
    </EditableArea>
  </EditableRoot>
</template>

<style scoped>
.btn {
  @apply h-4 w-4 text-muted-foreground;
}
</style>