<script setup>
import { ActionMenu } from '@/components/action-menu';
import { useLibrary } from '@/services/library';
import { defineProps, toRefs, provide, computed, toValue, defineModel } from 'vue';
import { useRepo } from '@/models';
import { Folder, Project } from '@/models';
import { useKVStore } from '@/stores';
import { ResourceIcon, ResourceName } from '.';

const props = defineProps({
    actionMenu: {
        type: String,
        required: false,
        default: 'context'
    },
    actionMenuDisabled: {
        type: Boolean,
        required: false,
        default: false
    },
    disableAttachments: {
        type: Boolean,
        required: false,
        default: false
    },
    items: {
        type: Array,
        required: false,
        default: () => []
    }
})

const item = defineModel('item', {
    required: true
});

const kvStore = useKVStore()

const { items } = toRefs(props);
const folderId = computed(() => {
    if (item.value instanceof Folder) {
        return item.value?.id || null;
    }
    return item.value?.parent_id || null;
});
const projectId = computed(() => {
    if (item.value instanceof Project) {
        return item.value?.id || null;
    }
    return item.value?.project_id || null;
});
const parentId = computed(() => {
    if (item.value instanceof Folder) {
        return item.value?.parent_id || null;
    }
    return folderId.value;
});

const { destroy, create, move, handleDrop, save } = useLibrary(projectId, folderId);

const onMove = computed(() => {
    if (!(item.value instanceof Project)) {
        return ({ selectedItems, projectId: pId, folderId: fId }) => {
            let parent
            selectedItems = toValue(selectedItems)
            if (selectedItems.length == 1 && selectedItems[0] instanceof Folder) {
                parent = selectedItems[0]
            } else {
                parent = useRepo(Folder).find(fId) || useRepo(Project).find(pId) || null;

            }
            if (items.value.length && items.value.some(el => el.id === item.value.id)) {
                move(parent, toValue(items));
            } else {
                move(parent, [toValue(item)]);
            }
        }
    }
    return null;
});

const onCreate = async (type) => {
    const entity = await create(type)
    if (type === 'folder') {
        if (items.value.length && items.value.some(el => el.id === item.value.id)) {
            move(entity, toValue(items))
        } else {
            move(entity, [toValue(item)])
        }
    } else if (!props.disableAttachments) {
        if (items.value.length && items.value.some(el => el.id === item.value.id) && items.value.some(el => ['DOCUMENT', 'FOLDER'].includes(el.type))) {
            kvStore.set(`${type}-${entity.id}-items`, toValue(items.value.filter(el => ['DOCUMENT', 'FOLDER'].includes(el.type))))
        } else if (['DOCUMENT', 'FOLDER'].includes(item.value.type)) {
            kvStore.set(`${type}-${entity.id}-items`, [toValue(item)])
        }
    }
}

provide('item', item);

const editableIcon = computed(() => {
    return (item.value instanceof Project)
});

async function onDelete() {
    if (item.value instanceof Project) {
        await useRepo(Project).api().delete(`/projects/${item.value.id}`, { delete: item.value.id })
    } else if (items.value.length && items.value.some(el => el.id === item.value.id)) {
        await Promise.all(items.value.map(el => destroy(el)))
    } else {
        await destroy(item.value)
    }
}
</script>

<template>
    <ActionMenu @update:state="$emit('update:state', $event)" @drop="handleDrop" :items="items" v-model:name="item.name"
        v-model:icon="item.icon" v-model:color="item.color" :variant="actionMenu" :disabled="actionMenuDisabled"
        @delete="onDelete" @create="onCreate" @move="onMove" :item="item" @rename="save(item)" @change-icon="save(item)"
        editable-name :editable-icon="editableIcon" :project-id="projectId" :folder-id="parentId" v-slot="slots">
        <slot v-bind="slots">
            <div>
                <ResourceIcon />
                <ResourceName />
            </div>
        </slot>
    </ActionMenu>
</template>