<script setup>
import { DateRangeFilter, PeopleFilter, ResourceTypeFilter } from '@/components/controls';
import { ResourceBreadcrumb } from '@/components/resources';
import { MainLayout, NavbarLayout, HeaderLayout } from '@/layouts';
import { useRoute } from 'vue-router';
import { reactive } from 'vue';
import { LibraryTable, UploadButton } from '../components';

const route = useRoute()
const filters = reactive({
    type: 'all',
    members: [],
    dateRange: {
        start: null,
        end: null
    }
})
</script>

<template>
    <MainLayout>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <ResourceBreadcrumb :project-id="route.params?.projectId" :folder-id="route.query?.folderId" />
                </template>
            </NavbarLayout>
        </template>
        <template #header>
            <HeaderLayout>
                <template #left-actions>
                    <UploadButton :project-id="route.params?.projectId" :folder-id="route.query?.folderId" />
                    <ResourceTypeFilter v-model="filters.type" />
                    <PeopleFilter v-model="filters.members" />
                    <DateRangeFilter v-model="filters.dateRange" />
                </template>
            </HeaderLayout>
        </template>
        <template #content>
            <LibraryTable :project-id="route.params?.projectId" :folder-id="route.query?.folderId" :filters="filters"
                selectable multiple />
        </template>
    </MainLayout>
</template>
