import { useRepo } from '@/models'
import { useQuery } from '@tanstack/vue-query'
import { toValue, computed } from 'vue'
import { Thread } from './models'
import { useKVStore } from '@/stores'

export function getThread(threadId) {
    const { isLoading } = useQuery({
        queryKey: ['threads', threadId],
        queryFn: async () => await useRepo(Thread).api().get(`/threads/${toValue(threadId)}`)
    })
    return {
        isLoading,
        thread: computed(() => useRepo(Thread).withAllRecursive().find(toValue(threadId)))
    }
}

export async function updateThread(thread) {
    await useRepo(Thread).api().patch(
        `/threads/${toValue(thread).id}`,
        toValue(thread)
    );
    return useRepo(Thread).withAllRecursive().find(toValue(thread).id);
}

export async function deleteThread(thread) {
    await useRepo(Thread).api().delete(
        `/threads/${toValue(thread).id}`,
        { delete: toValue(thread).id }
    );
}

export async function createThread({ name = null, projectId = null, parentId = null, attachments = [] } = {}, { router = null, ...options } = {}) {
    const response = await useRepo(Thread).api().post('/threads/', {
        name: toValue(name) || 'New Chat',
        project_id: toValue(projectId) || null,
        parent_id: toValue(parentId) || null
    })
    if (router) {
        router.push({ name: response.entities[0].project_id ? 'project-thread' : 'thread', params: { threadId: response.entities[0].id, projectId: response.entities[0].project_id }, query: options?.query })
    }
    const kvStore = useKVStore()
    const items = attachments.filter(item => ['DOCUMENT', 'FOLDER'].includes(item.type))
    if (items?.length) {
        kvStore.set(`thread-${response.entities[0].id}-items`, toValue(items))
    }
    return useRepo(Thread).withAllRecursive().find(response.entities[0].id);
}

export async function enterThread({ socket, thread }) {
    await socket.emit('enter_thread', toValue(thread).id)
}

export async function leaveThread({ socket, thread }) {
    await socket.emit('leave_thread', toValue(thread).id)
}

export async function startRun({ socket, thread, prompt, mode, attachments = [] }) {
    if (toValue(thread).isRunning || !toValue(prompt).trim()) return
    await socket.emit(
        'run',
        toValue(thread).id,
        toValue(mode),
        toValue(prompt),
        toValue(attachments)?.map(attachment => attachment.id)
    )
}

export async function cancelRun({ socket, thread, runId }) {
    console.warn('cancelRun', socket, toValue(thread), runId)
    throw new Error('Not implemented')
    // await socket.emit('cancel_run', { thread_id: toValue(thread).id, run_id: runId })
}

export const ThreadService = {
    enterThread,
    leaveThread,
    startRun,
    cancelRun,
    getThread,
    createThread,
    updateThread,
    deleteThread
}