import { Model } from 'pinia-orm'
import { DateCast } from '@/models/utils'

export class Regulation extends Model {
    static entity = 'regulations'

    static fields() {
        return {
            id: this.attr(null),
            coverage: this.string(''),
            kind: this.string(''),
            title: this.string(''),
            language: this.string(''),
            abbreviations: this.attr([]),
            areas_of_law: this.attr([]),
            areas_of_government: this.attr([]),
            body_of_government: this.attr(null),

            // Relationships
            children: this.hasMany(Consolidation, 'regulation_id')
        }
    }
}

export class Consolidation extends Model {
    static entity = 'consolidations'

    static fields() {
        return {
            id: this.attr(null),
            coverage: this.string(''),
            modified: this.attr(null),
            valid_from: this.attr(null),
            valid_to: this.attr(null),
            kind: this.string(''),
            text: this.string(''),
            number: this.string(''),
            title: this.string(''),
            local_title: this.string(''),
            regulation_id: this.attr(null),
            parent_id: this.attr(null),
            references: this.attr([]),

            // Relationships
            regulation: this.belongsTo(Regulation, 'regulation_id'),
            parent: this.belongsTo(Consolidation, 'parent_id'),
            children: this.hasMany(Consolidation, 'parent_id')
        }
    }

    get name() {
        return this.title
    }

    get display() {
        return this.preview
    }

    get preview() {
        return {
            title: this.title?.replace(/^([^,]*?)\d{2,}(?:[- ]\d+-\d+-\d+-\d+-\d+-\d+)?/g, '$1').replace(/\s*,\s*/g, ', ').trim(),
            description: this.regulation?.title,
            summary: this.text ? this.text.slice(0, 250) + '...' : 'No summary available',
            author: this.regulation?.body_of_government?.name,
            tags: this.regulation?.areas_of_law?.map(area => area.name)
        }
    }

    set preview(value) {
        this.title = value.title
        this.description = value.description
        this.summary = value.summary
        this.author = value.author
        this.tags = value.tags
    }

    get pageContent() {
        return this.text
    }

    set pageContent(value) {
        this.text = value
    }

    static casts() {
        return {
            modified: DateCast,
            valid_from: DateCast,
            valid_to: DateCast
        }
    }
}
