import { usePromptTemplate } from '@/services/threads/composables';
import { useSheetTemplate } from '@/services/sheets/composables';
import { toValue } from 'vue';

export function useTemplate(template) {
    if (toValue(template)?.type === 'SHEETTEMPLATE') {
        return useSheetTemplate(template)
    } else if (toValue(template)?.type === 'PROMPTTEMPLATE') {
        return usePromptTemplate(template)
    } else {
        return {
            template: null,
            isLoading: true,
            saveTemplate: async () => {},
            deleteTemplate: async () => {}
        }
    }
}