<script setup>
import { ref, watch, nextTick } from 'vue';
import { useConfirm } from './use-confirm';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

const { isOpen, message, confirm, cancel } = useConfirm();
const confirmButtonRef = ref(null);

watch(isOpen, (newValue) => {
  if (newValue) {
    nextTick(() => {
      confirmButtonRef.value?.focus();
    });
  }
});
</script>

<template>
  <Dialog :open="isOpen" @update:open="cancel">
    <DialogContent @escapeKeyDown="cancel">
      <DialogHeader>
        <DialogTitle>Bevestig actie</DialogTitle>
        <DialogDescription>
          {{ message }}
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button @click="cancel" variant="outline">Annuleer</Button>
        <Button @click="confirm" variant="default">Bevestigen</Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
