import { defineStore } from 'pinia';
import { ref, watch, computed } from 'vue';

export const useSidepanel = defineStore('sidepanel', () => {
    const isOpen = ref(false)
    const isFullscreen = ref(false)
    const tabs = ref([])
    const currentTab = ref(tabs.value[0]?.id)


    const close = async () => {
        if (tabs.value.length === 1) await removeTab(tabs.value[0])
        isOpen.value = false
    }

    const maxShownTabs = computed(() => {
        return isFullscreen.value ? 5 : 3
    })

    const shownTabs = computed(() => {
        if (tabs.value.length > maxShownTabs.value + 1) {
            return tabs.value.slice(0, maxShownTabs.value)
        }
        return tabs.value
    })
    
    const hiddenTabs = computed(() => {
        if (tabs.value.length > shownTabs.value.length) {
            return tabs.value.slice(shownTabs.value.length)
        }
        return []
    })

    function focusTab(tab) {
        currentTab.value = tab.id
        isOpen.value = true
        if (tabs.value.filter(t => t.id !== tab.id).length > 0) {
            tabs.value = tabs.value.filter(t => t.id !== tab.id)
            tabs.value.unshift(tab)
        }
    }

    async function addTab(tab = null) {
        // if (!tab) {
        //     tab = { id: nanoid(), type: 'new', name: 'New tab' }
        // }
        if (!tabs.value.find(t => t.id === tab.id)) {
            tabs.value.push(tab)
        }
        if (shownTabs.value.find(t => t.id === tab.id)) {
            currentTab.value = tab.id
            isOpen.value = true
        } else {
            focusTab(tab)
        }
    }

    async function removeTab(tab) {
        tabs.value = tabs.value.filter(t => t.id !== tab.id)
        if (currentTab.value === tab.id) {
            if (tabs.value.length === 0) {
                //await addTab()
                close()
            }
            currentTab.value = tabs.value[0]?.id
        }
    }

    watch(isOpen, (newVal) => {
        if (!newVal) {
            isFullscreen.value = false
        }
    })

    return { isOpen, isFullscreen, tabs, shownTabs, hiddenTabs, currentTab, addTab, removeTab, focusTab, close, maxShownTabs }
});