<script setup>
import { defineProps, ref, toRefs, computed, defineEmits } from 'vue'
import { DraggableItem } from '@/components/behaviour/draggable';
import { SelectableItem } from '@/components/behaviour/selectable';
import { FolderIcon, FolderOpenIcon } from 'lucide-vue-next';
import { ResourceItem, ResourceName, ResourceIcon } from '@/components/resources';
import { SidebarMenuSubItem, SidebarMenuSubButton, SidebarMenuButton, SidebarMenuItem, SidebarMenuSubAction, SidebarMenuAction } from '@/components/ui/sidebar';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { useRoute } from 'vue-router';
import LibraryTreeContent from './LibraryTreeContent.vue';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { ChevronRight, Loader2 } from 'lucide-vue-next';

const route = useRoute();

const props = defineProps({
    item: {
        type: Object,
        required: false,
        default: null
    },
    subButton: {
        type: Boolean,
        required: false,
        default: false
    },
    selectedItems: {
        type: Array,
        required: false,
        default: () => []
    }
})


const { item, selectedItems } = toRefs(props);
const isActive = computed(() => {
    return (
        route.name === 'project-documents'
        || route.name === 'documents'
        || route.name === 'project-document'
        || route.name === 'document'
        || route.name === 'project-sheet'
        || route.name === 'sheet'
        || route.name === 'project-thread'
        || route.name === 'thread'
    )
        && route.params?.projectId == item.value?.project_id
        && (
            route.query?.folderId === item.value?.id
            || route.params?.sheetId === item.value?.id
            || route.params?.threadId === item.value?.id
            || route.params?.documentId === item.value?.id
            || route.params?.resourceId === item.value?.id
        )
});
const isExpanded = ref(isActive.value)
const isFolder = computed(() => item.value?.type === 'FOLDER')
const emit = defineEmits(['open', 'drop'])


const onClick = async (event, toggleSelect) => {
    if (event?.shiftKey || event?.ctrlKey || event?.metaKey) {
        toggleSelect(event)
    } else {
        toggleSelect(event, false)
        emit('open', item)
        isExpanded.value = true
    }
}


const isEditing = ref(false)

const handleEdit = (state) => {
    isEditing.value = state == 'edit'
}
</script>

<template>
    <SelectableItem :item="item" v-slot="{ isSelected, toggleSelect }" :prevent-select="isEditing"
        data-prevent-parent-drop>
        <TooltipProvider>
            <Tooltip :disabled="isExpanded">
                <TooltipTrigger asChild>
                    <DraggableItem :item="item" v-slot="{ isDraggedOver }" @long-dragover="isExpanded = true"
                        :prevent-drag="isEditing">
                        <component @drop="emit('drop', item, $event)" v-if="isFolder"
                            :is="subButton ? SidebarMenuSubItem : SidebarMenuItem" :class="subButton ? 'pr-3' : 'pr-3'"
                            draggable="true">
                            <Collapsible v-model:open="isExpanded" class="group/collapsible" as-child>
                                <ResourceItem @update:state="handleEdit" :items="selectedItems" :item="item"
                                    @drop="emit('drop', item, $event)">
                                    <component :is="subButton ? SidebarMenuSubButton : SidebarMenuButton"
                                        :disabled="item.uploadStatus != 'done'"
                                        @click="(event) => onClick(event, toggleSelect)" :is-active="isActive"
                                        :size="size"
                                        :class="{ [subButton ? 'bg-muted' : 'bg-sidebar-accent/80']: isSelected || isDraggedOver }"
                                        class="-mr-2">
                                        <Loader2 class="w-4 h-4 animate-spin" v-if="item.uploadStatus == 'pending'" />
                                        <FolderIcon v-else-if="!isExpanded" :stroke-width="isActive ? 2.5 : 2" />
                                        <FolderOpenIcon v-else :stroke-width="isActive ? 2.5 : 2" />
                                        <ResourceName :class="{ 'pr-5': subButton }" />
                                    </component>
                                </ResourceItem>
                                <CollapsibleTrigger as-child>
                                    <component :is="subButton ? SidebarMenuSubAction : SidebarMenuAction"
                                        class="mr-3 hover:bg-accent" show-on-hover>
                                        <ChevronRight class="transition-transform duration-300"
                                            :class="isExpanded ? 'rotate-90' : ''" />
                                    </component>
                                </CollapsibleTrigger>
                                <CollapsibleContent class="-mr-[0.22rem]">
                                    <LibraryTreeContent :project-id="item.project_id" :folder-id="item.id"
                                        :sub-button="subButton" />
                                </CollapsibleContent>
                            </Collapsible>
                        </component>
                        <component @drop="emit('drop', item, $event)" v-else
                            :is="subButton ? SidebarMenuSubItem : SidebarMenuItem" :class="subButton ? 'pr-3' : 'pr-3'">
                            <ResourceItem @update:state="handleEdit" :items="selectedItems" :item="item"
                                @drop="emit('drop', item, $event)">
                                <component :is="subButton ? SidebarMenuSubButton : SidebarMenuButton"
                                    @click="(event) => onClick(event, toggleSelect)" :is-active="isActive" :size="size"
                                    :class="{ [subButton ? 'bg-muted' : 'bg-sidebar-accent/80']: isSelected || isDraggedOver }"
                                    :disabled="item.uploadStatus != 'done'">
                                    <Loader2 class="w-4 h-4 animate-spin" v-if="item.uploadStatus == 'pending'" />
                                    <ResourceIcon v-else :stroke-width="isActive ? 2.5 : 2" />
                                    <ResourceName :class="{ 'pr-5': subButton }" />
                                </component>
                            </ResourceItem>
                        </component>
                    </DraggableItem>
                </TooltipTrigger>
                <TooltipContent side="right">
                    {{ item.name }}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    </SelectableItem>
</template>