import { useRepo, Project } from '@/models';
import { useUpload } from '@/composables/use-upload';
import { useRouter, useRoute } from 'vue-router';
import { computed, toValue, toRef } from 'vue';
import { LibraryService } from '@/services/library/service';
import { Resource } from '../models'

export function useLibrary(projectId, folderId, { embedded = false } = {}) {
    const router = useRouter();
    const route = useRoute();
    projectId = toRef(projectId);
    folderId = toRef(folderId);
    let { handleUpload: upload } = useUpload(projectId, folderId);

    const folder = computed(() => {
        return useRepo(Resource).with('parent').find(toValue(folderId));
    });

    const project = computed(() => {
        return useRepo(Project).find(toValue(projectId));
    });

    const parent = computed(() => {
        return folder.value?.parent || project.value || undefined;
    });

    async function create(type, { name = 'New ' + type, autoOpen = true } = {}) {
        const resource = await LibraryService.create(toValue(projectId), toValue(folderId), type, { name });
        if (autoOpen) {
            open(resource);
        }
        return resource;
    }

    function open(target) {
        if (!embedded) {
            const routeType = toValue(target)?.type === 'FOLDER' ? 'documents' : toValue(target)?.type.toLowerCase();
            let routeName = 'project'
            if (!routeType) {
                routeName = toValue(projectId) ? 'project-documents' : 'documents';
            } else {
                routeName = toValue(projectId) ? 'project-' + routeType : routeType;
            }
            const route = { name: routeName, params: { projectId: toValue(projectId) } };
            if (toValue(target)?.type === 'FOLDER') {
                if (toValue(target)?.id) route.query = { folderId: toValue(target)?.id };
            } else if (toValue(target)?.type === 'DOCUMENT') {
                route.params.resourceId = toValue(target)?.id;
            } else {
                route.params[toValue(target)?.type.toLowerCase() + 'Id'] = toValue(target)?.id;
            }
            router.push(route);
        } else {
            if (toValue(target)?.type === 'FOLDER') {
                projectId.value = toValue(target)?.project_id;
                folderId.value = toValue(target)?.id;
            } else if (toValue(target) === null) {
                folderId.value = null;
            }
        }
    }

    async function destroy(target) {
        if (route.fullPath.includes(toValue(target).id)) {
            if (toValue(target)?.project_id) {
                router.push({ name: 'project', params: { projectId: toValue(target)?.project_id } });
            } else {
                router.push({ name: 'home' });
            }
        }
        await LibraryService.destroy(target);
    }

    const { isLoading, children, refetch } = LibraryService.listResources(projectId, folderId, embedded ? 'full' : 1);

    return {
        folder,
        parent,
        open,
        move: LibraryService.move,
        create,
        save: LibraryService.save,
        destroy,
        upload,
        children,
        isLoading,
        handleDrop: LibraryService.handleDrop,
        refresh: refetch
    };
}