<script setup>
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbLink, BreadcrumbPage, BreadcrumbSeparator, BreadcrumbEllipsis } from '@/components/ui/breadcrumb'
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '@/components/ui/dropdown-menu'
import { ActionMenu } from '@/components/action-menu'
import { ResourceItem, ResourceName, ResourceIcon } from '@/components/resources'
import { ChevronDown, FolderIcon, Library } from 'lucide-vue-next';
import * as icons from 'lucide-vue-next';
import { computed, defineModel, watch, defineProps, onMounted } from 'vue';
import { Project, Folder } from '@/models';
import { useLibrary, LibraryService } from '@/services/library';
import { Resource } from '@/services/library/models';
import { useRepo } from 'pinia-orm';

const projectRepo = useRepo(Project);
const folderRepo = useRepo(Folder);

defineProps({
    embedded: {
        type: Boolean,
        required: false,
        default: false
    },
    icon: {
        type: Function,
        required: false,
        default: Library
    },
    name: {
        type: String,
        required: false,
        default: "Library"
    }
})

const resource = defineModel('resource', {
    required: true
})

const projectId = defineModel('projectId', {
    type: String,
    required: false,
    default: null
})
const folderId = defineModel('folderId', {
    type: String,
    required: false,
    default: null
})

const library = useLibrary(projectId, folderId);

const project = computed(() => {
    if (resource.value) {
        return resource.value.project;
    }
    return projectRepo.find(projectId.value);
});

const folder = computed(() => {
    if (resource.value) {
        return resource.value.parent;
    }
    return folderRepo.withAllRecursive().find(folderId.value);
});

function getParents(folder, resource) {
    if (!folder.parent) {
        return resource ? [folder] : [];
    }
    return [...getParents(folder.parent, resource), folder.parent];
}

const parents = computed(() => getParents(folder.value, resource.value));

function updateLocation(pId, fId) {
    projectId.value = pId;
    folderId.value = fId;
}

onMounted(async () => {
    if (resource.value) {
        await LibraryService.listParents([resource.value]);
        resource.value = useRepo(Resource).withAllRecursive().find(resource.value.id);
    }
})

watch(resource, async (newVal, oldVal) => {
    if ((newVal && !oldVal) || (newVal?.id !== oldVal?.id) || (newVal?.parent_id !== oldVal?.parent_id) || (newVal?.project_id !== oldVal?.project_id)) {
        await LibraryService.listParents([newVal]);
        resource.value = useRepo(Resource).withAllRecursive().find(newVal.id);
    }
})
</script>

<template>
    <Breadcrumb>
        <BreadcrumbList>
            <template v-if="project">
                <BreadcrumbItem v-if="!embedded">
                    <BreadcrumbLink as-child>
                        <RouterLink :to="{
                            name: 'project',
                            params: { projectId: project.id }
                        }" class="flex flex-row space-x-2 items-center">
                            <component :is="icons[project.icon]" :class="`text-${project.color} w-5 h-5`" />
                            <span>{{ project.name }}</span>
                        </RouterLink>
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem v-else>
                    <BreadcrumbLink @click="updateLocation(project.id, null)"
                        class="flex flex-row space-x-2 items-center cursor-pointer">
                        <component :is="icons[project.icon]" :class="`text-${project.color} w-5 h-5`" />
                        <span>{{ project.name }}</span>
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
            </template>
            <template v-if="!folder">
                <BreadcrumbItem v-if="resource">
                    <BreadcrumbLink v-if="!embedded" as-child>
                        <RouterLink :to="{
                            name: project ? 'project-documents' : 'documents',
                            params: { projectId: project?.id }
                        }" class="flex flex-row space-x-2 items-center">
                            <component :is="icon" :class="`w-5 h-5`" />
                            <span>{{ name }}</span>
                        </RouterLink>
                    </BreadcrumbLink>
                    <BreadcrumbLink class="flex flex-row space-x-2 items-center cursor-pointer"
                        @click="updateLocation(project?.id, null)" v-else>
                        <component :is="icon" :class="`w-5 h-5`" />
                        <span>{{ name }}</span>
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem v-else-if="!embedded">
                    <ActionMenu :name="name" :icon="`${icon}`" color="primary" @create="library.create"
                        @upload="library.upload">
                        <BreadcrumbPage class="flex flex-row space-x-2 items-center cursor-pointer">
                            <component :is="icon" :class="`w-5 h-5`" />
                            <span class="select-none">{{ name }}</span>
                            <ChevronDown class="size-4 shrink-0 text-muted-foreground" />
                        </BreadcrumbPage>
                    </ActionMenu>
                </BreadcrumbItem>
                <BreadcrumbItem v-else>
                    <BreadcrumbPage class="flex flex-row space-x-2 items-center cursor-pointer">
                        <component :is="icon" :class="`w-5 h-5`" />
                        <span class="select-none">{{ name }}</span>
                    </BreadcrumbPage>
                </BreadcrumbItem>
            </template>
            <template v-else>
                <BreadcrumbItem>
                    <BreadcrumbLink v-if="!embedded" as-child>
                        <RouterLink :to="{
                            name: project ? 'project-documents' : 'documents',
                            params: { projectId: project?.id }
                        }" class="flex flex-row space-x-2 items-center">
                            <component :is="icon" :class="`w-5 h-5`" />
                            <span>{{ name }}</span>
                        </RouterLink>
                    </BreadcrumbLink>
                    <BreadcrumbLink class="flex flex-row space-x-2 items-center cursor-pointer"
                        @click="updateLocation(project?.id, null)" v-else>
                        <component :is="icon" :class="`w-5 h-5`" />
                        <span>{{ name }}</span>
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <template v-if="folder">
                    <template v-if="parents.length > 1">
                        <BreadcrumbSeparator />
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <BreadcrumbItem class="cursor-pointer">
                                    <BreadcrumbEllipsis class="h-7" />
                                </BreadcrumbItem>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent v-if="!embedded">
                                <DropdownMenuItem v-for="parent in parents.slice(0, -1)" :key="parent.id" asChild>
                                    <RouterLink :to="{
                                        name: parent.project ? 'project-documents' : 'documents',
                                        params: { projectId: parent.project?.id },
                                        query: { folderId: parent.id }
                                    }" class="flex flex-row space-x-2 items-center">
                                        <FolderIcon class="size-4 shrink-0" />
                                        <span>{{ parent.name }}</span>
                                    </RouterLink>
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                            <DropdownMenuContent v-else>
                                <DropdownMenuItem @click="updateLocation(parent.project_id, parent.id)"
                                    v-for="parent in parents.slice(0, -1)" :key="parent.id">
                                    <FolderIcon />
                                    <span>{{ parent.name }}</span>
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </template>
                    <template v-if="parents.length > 0 && !resource">
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbLink v-if="!embedded" as-child>
                                <RouterLink :to="{
                                    name: project ? 'project-documents' : 'documents',
                                    params: { projectId: project?.id },
                                    query: { folderId: parents[parents.length - 1].id }
                                }" class="flex flex-row space-x-2 items-center">
                                    <FolderIcon />
                                    <span>{{ parents[parents.length - 1].name }}</span>
                                </RouterLink>
                            </BreadcrumbLink>
                            <BreadcrumbLink class="flex flex-row space-x-2 items-center cursor-pointer" v-else
                                @click="updateLocation(parents[parents.length - 1].project_id, parents[parents.length - 1].id)">
                                <FolderIcon />
                                <span>{{ parents[parents.length - 1].name }}</span>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </template>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem v-if="!resource">
                        <ResourceItem v-if="!embedded" :item="folder" action-menu="dropdown">
                            <BreadcrumbPage
                                class="flex flex-row space-x-2 items-center cursor-pointer max-w-90 overflow-hidden">
                                <ResourceIcon />
                                <ResourceName class="max-w-80" />
                                <ChevronDown class="size-4 shrink-0 text-muted-foreground" />
                            </BreadcrumbPage>
                        </ResourceItem>
                        <BreadcrumbPage v-else class="flex flex-row space-x-2 items-center cursor-pointer">
                            <FolderIcon />
                            <span>{{ folder.name }}</span>
                        </BreadcrumbPage>
                    </BreadcrumbItem>
                    <template v-else>
                        <BreadcrumbItem>
                            <BreadcrumbLink v-if="!embedded" as-child>
                                <RouterLink :to="{
                                    name: project ? 'project-documents' : 'documents',
                                    params: { projectId: project?.id },
                                    query: { folderId: folder.id }
                                }" class="flex flex-row space-x-2 items-center">
                                    <FolderIcon />
                                    <span>{{ folder.name }}</span>
                                </RouterLink>
                            </BreadcrumbLink>
                            <BreadcrumbLink v-else @click="updateLocation(folder.project_id, folder.id)">
                                <FolderIcon />
                                <span>{{ folder.name }}</span>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </template>
                </template>
            </template>
            <template v-if="resource">
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                    <ResourceItem v-model:item="resource" action-menu="dropdown">
                        <BreadcrumbPage
                            class="flex flex-row space-x-2 items-center cursor-pointer max-w-90 overflow-hidden">
                            <ResourceIcon :class="`w-5 h-5`" />
                            <ResourceName class="max-w-80" />
                            <ChevronDown class="size-4 shrink-0 text-muted-foreground" />
                        </BreadcrumbPage>
                    </ResourceItem>
                </BreadcrumbItem>
            </template>
        </BreadcrumbList>
    </Breadcrumb>
</template>