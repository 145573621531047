<script setup>
import { Columns } from 'lucide-vue-next';
import { defineProps, toRefs } from 'vue';
import { useSheetTemplate } from '../composables';
import { Skeleton } from '@/components/ui/skeleton';

const props = defineProps({
    template: {
        type: Object,
        required: true,
    }
});

const { template } = toRefs(props)
const { isLoading } = useSheetTemplate(template)
</script>

<template>
    <div class="flex flex-row items-center gap-1">
        <Columns class="w-3 h-3 text-muted-foreground items-center" />
        <Skeleton v-if="isLoading" class="w-6 h-3 rounded-3xl" />
        <p v-else class="text-xs font-medium text-muted-foreground items-center">
            {{ template.columns?.length }} columns
        </p>
    </div>
</template>