<script setup>
import { useEditor, EditorContent } from '@tiptap/vue-3';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';
import Underline from '@tiptap/extension-underline';
import Highlight from '@tiptap/extension-highlight';
import TableRow from '@tiptap/extension-table-row';
import Table from '@tiptap/extension-table';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Paragraph from '@tiptap/extension-paragraph';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import HardBreak from '@tiptap/extension-hard-break';
import { defineProps, computed, watch, toValue } from 'vue';
import { Markdown } from 'tiptap-markdown'
import CitedText from './CitedText.js';
import Text from '@tiptap/extension-text';
import { Button } from '@/components/ui/button';
import { CopyIcon } from 'lucide-vue-next';
import { toast } from 'vue-sonner';

const props = defineProps({
    content: {
        type: Object,
        required: true
    },
    disableActions: {
        type: Boolean,
        default: false
    },
    enableFadeIn: {
        type: Boolean,
        default: false
    },
    menuPosition: {
        type: String,
        default: 'top'
    }
})

const parseNode = (node) => {
    if (Array.isArray(node)) return node.flatMap(parseNode);
    if (!(node && typeof node === 'object')) return node;
    if (node.type === 'text') {
        if (node.text.trim() === '') return null;
        const parsedNode = Object.assign({}, node);
        parsedNode.marks = []; // Temporary fix for sporadic formatting issues
        const citationMarks = node.marks?.filter((mark) => mark.type === 'citation');
        if (citationMarks?.length > 0) {
            const citationNodes = citationMarks
                .map((mark) => Object.assign({}, {
                    type: 'citation',
                    attrs: Object.assign({}, mark.attrs),
                }));
            return [parsedNode, ...citationNodes];
        }
        return parsedNode;
    }
    const parsedNode = Object.fromEntries(Object.entries(node).map(([key, value]) => [key, parseNode(value)]))
    if (parsedNode.content !== undefined) parsedNode.content = parsedNode.content.filter((node) => node !== null);
    return parsedNode;
}

const content = computed(() => parseNode(props.content));

const editor = useEditor({
    content: toValue(content),
    enableContentCheck: false,
    extensions: [
        Document,
        CitedText,
        Heading,
        HorizontalRule,
        Paragraph,
        Bold,
        Italic,
        Strike,
        Underline,
        Highlight,
        Table,
        TableRow,
        TableCell,
        TableHeader,
        BulletList,
        OrderedList,
        ListItem,
        Text,
        HardBreak,
        Markdown.configure({
            breaks: true
        })
    ],
    editable: false,
    onContentError: ({ error }) => {
        console.log(error)
    }
})

watch(
    () => content.value,
    (newContent) => {
        if (editor?.value && newContent && newContent.content.length > 0 && !editor.value.isCapturingTransaction)
            editor.value.commands.setContent(newContent)
    }
)

function copyText() {
    const text = editor.value?.getText({ textSerializers: { citation: () => "" } })
    if (!text) return
    navigator.clipboard.writeText(text)
    toast.success('Copied to clipboard')
}
</script>

<template>
    <div class="relative group">
        <div v-if="!disableActions"
            class="absolute left-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10"
            :class="{ '-top-8': menuPosition === 'top', '-bottom-6': menuPosition === 'bottom' }">
            <Button variant="link" size="icon" class="text-muted-foreground space-x-2" @click="copyText">
                <CopyIcon class="w-4 h-4 shrink-0" />
                <span>Copy</span>
            </Button>
        </div>
        <div class="tiptap" :class="{ 'fade-enabled': enableFadeIn }">
            <EditorContent :editor="editor" />
        </div>
    </div>
</template>

<style>
.tiptap {
    @apply text-left;
}

.tiptap>*:first-child {
    @apply mt-0;
}

/* Table-specific styling */
.tiptap table {
    @apply w-full border-collapse m-0 overflow-hidden table-fixed;
}

.tiptap table td,
.tiptap table th {
    @apply border border-border box-border p-2 align-top relative;
    min-width: 1em;
}

.tiptap table td>*,
.tiptap table th>* {
    @apply mb-0;
}

.tiptap table th {
    @apply bg-sidebar font-bold text-left;
}

.tiptap table .column-resize-handle {
    @apply bg-blue-500 absolute top-0 -right-0.5 -bottom-0.5 pointer-events-none;
    width: 4px;
}

.tiptap .tableWrapper {
    @apply my-6 overflow-x-auto;
}

.tiptap.resize-cursor {
    cursor: col-resize;
}

/* Add fade-in animation for new content */
.tiptap.fade-enabled p,
.tiptap.fade-enabled h1,
.tiptap.fade-enabled h2,
.tiptap.fade-enabled h3,
.tiptap.fade-enabled h4,
.tiptap.fade-enabled h5,
.tiptap.fade-enabled h6,
.tiptap.fade-enabled ul,
.tiptap.fade-enabled ol,
.tiptap.fade-enabled li,
.tiptap.fade-enabled table,
.tiptap.fade-enabled tr,
.tiptap.fade-enabled td,
.tiptap.fade-enabled th,
.tiptap.fade-enabled blockquote,
.tiptap.fade-enabled span,
.tiptap.fade-enabled a,
.tiptap.fade-enabled strong,
.tiptap.fade-enabled em,
.tiptap.fade-enabled strike,
.tiptap.fade-enabled u,
.tiptap.fade-enabled pre {
    animation: fadeIn 0.8s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(5px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
