<script setup>
import {
    useSidebar,
    Sidebar,
    SidebarHeader,
    SidebarContent,
    SidebarMenuItem,
    SidebarMenuButton,
    SidebarSeparator,
    SidebarMenu,
    SidebarGroup,
    SidebarGroupContent,
    SidebarFooter
} from '@/components/ui/sidebar';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useRouter, useRoute } from 'vue-router'
import { LayoutDashboard, Menu } from 'lucide-vue-next';
import { SettingsDialog } from '@/components/settings';
import { ProjectSidebarGroup } from '@/services/projects';
import { LibraryButton } from '@/services/library';
import NewButton from './NewButton.vue';
import { TemplatesButton } from '@/services/templates/components';
import { useAuth } from '@/auth';

const auth = useAuth();
const route = useRoute()
const router = useRouter()
const { open, toggleSidebar } = useSidebar();
</script>

<template>
    <Sidebar collapsible="icon" variant="inset">
        <SidebarHeader>
            <SidebarMenu>
                <SidebarMenuItem class="flex flex-row overflow-visible space-x-4 pb-2">
                    <SidebarMenuButton @click="toggleSidebar" tooltip="Menu"
                        class="overflow-visible w-fit h-fit rounded-full" size="xl">
                        <Menu class="-ml-0.5" />
                        <div v-if="open" class="flex items-center justify-start w-full">
                            <Avatar class="size-10 rounded-lg bg-no-repeat bg-contain bg-center bg-transparent"
                                style="background-image: var(--symbol);">
                                <AvatarFallback />
                            </Avatar>
                            <span class="truncate font-black text-left text-2xl leading-tight">Zeno</span>
                        </div>
                    </SidebarMenuButton>
                </SidebarMenuItem>
                <SidebarMenuItem class="flex flex-row space-x-1">
                    <NewButton />
                </SidebarMenuItem>
            </SidebarMenu>
        </SidebarHeader>
        <SidebarContent>
            <SidebarGroup>
                <SidebarGroupContent>
                    <SidebarMenu>
                        <SidebarMenuItem>
                            <SidebarMenuButton tooltip="Home" @click="router.push({ name: 'home' })" size="lg"
                                :is-active="route.name === 'home'">
                                <LayoutDashboard :stroke-width="route.name === 'home' ? 2.5 : 2" />
                                <span :class="{
                                    'font-semibold': route.name === 'home'
                                }">Home</span>
                            </SidebarMenuButton>
                        </SidebarMenuItem>
                        <SidebarMenuItem>
                            <TemplatesButton tooltip="Templates" size="lg" />
                        </SidebarMenuItem>
                        <SidebarMenuItem>
                            <LibraryButton tooltip="Library" size="lg" />
                        </SidebarMenuItem>
                    </SidebarMenu>
                </SidebarGroupContent>
            </SidebarGroup>
            <SidebarSeparator />
            <ProjectSidebarGroup />
        </SidebarContent>
        <SidebarFooter>
            <SidebarMenu>
                <SidebarMenuItem>
                    <SettingsDialog as-child>
                        <SidebarMenuButton tooltip="Settings" size="lg" class="h-12">
                            <Avatar :class="{
                                'border-[0.2rem] border-transparent bg-transparent rounded-xl ': auth.organization,
                                'rounded-full p-0 -ml-2.5': !open
                            }">
                                <AvatarImage class="bg-transparent" :src="auth.user.profile_picture_url"
                                    :alt="auth.user.email" />
                                <AvatarFallback>{{ auth.user.email.charAt(0) }}</AvatarFallback>
                            </Avatar>
                            <div v-if="open" class="flex flex-col justify-center">
                                <div class="flex flex-row items-center gap-1">
                                    <span class="text-xs font-regular text-muted-foreground">
                                        {{ auth.user.email }}
                                    </span>
                                </div>
                                <div class="flex flex-row items-center gap-1">
                                    <span v-if="auth.organization" class="text-xs font-semibold">
                                        {{ auth.organization.name }}
                                    </span>
                                    <span v-else-if="auth.user.first_name || auth.user.last_name"
                                        class="text-xs font-semibold">
                                        {{ auth.user.first_name }} {{ auth.user.last_name }}
                                    </span>
                                    <span v-else class="text-xs font-semibold">
                                        {{ auth.user.email }}
                                    </span>
                                </div>
                            </div>
                        </SidebarMenuButton>
                    </SettingsDialog>
                </SidebarMenuItem>
            </SidebarMenu>
        </SidebarFooter>
    </Sidebar>
</template>
