import { LetterText, SquareCheck, Hash, DollarSign, Calendar, Tag, Tags } from 'lucide-vue-next'

export const columnTypes = [
    { label: 'Text', value: 'text', icon: LetterText },
    { label: 'Yes/No', value: 'boolean', icon: SquareCheck },
    { label: 'Number', value: 'number', icon: Hash },
    { label: 'Currency', value: 'currency', icon: DollarSign },
    { label: 'Date', value: 'date', icon: Calendar },
    { label: 'Select', value: 'select', icon: Tag },
    { label: 'Multi-Select', value: 'multi_select', icon: Tags }
]