<script setup>
import { SidebarMenuButton } from '@/components/ui/sidebar';
import { Layers } from 'lucide-vue-next';
import { defineProps } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { ThreadService } from '@/services/threads';
import { SheetService } from '@/services/sheets';
import { TemplateDialog } from '.';

const router = useRouter()
const route = useRoute()

defineProps({
    size: {
        type: String,
        required: false,
        default: 'md'
    },
    tooltip: {
        type: String,
        required: false,
        default: 'Templates'
    }
})

const applyTemplate = async (template) => {
    if (template.type == 'PROMPTTEMPLATE') {
        await ThreadService.createThread({ name: template.name, projectId: route.params?.projectId || null, parentId: route.query?.folderId || null }, { router, query: { templateId: template.id } })
    } else {
        await SheetService.createFromTemplate({ template, projectId: route.params?.projectId || null, parentId: route.query?.folderId || null }, { router })
    }
}
</script>

<template>
    <TemplateDialog @apply="applyTemplate">
        <SidebarMenuButton :size="size" :tooltip="tooltip">
            <Layers />
            <span>
                {{ tooltip }}
            </span>
        </SidebarMenuButton>
    </TemplateDialog>
</template>