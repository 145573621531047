import { Model } from 'pinia-orm'
import { DateCast } from '@/models/utils'
import { Resource } from '@/services/library/models/ResourceHierarchy'

export class Document extends Resource {
    static entity = 'document'
    static baseEntity = 'resource'

    static fields() {
        return {
            ...super.fields(),
            type: this.string('DOCUMENT'),
            icon: this.string('File'),
            color: this.string('blue-500'),
            content: this.attr(''),
            content_type: this.string(''),
            upload_status: this.string(''),

            // Relationships
            parts: this.hasMany(DocumentPart, 'document_id'),
        }
    }

    get display() {
        return this.preview
    }

    get preview() {
        return {
            title: this.title,
            description: this.content_type,
            summary: this.content ? this.content.slice(0, 250) + '...' : 'No summary available',
            author: this.owner?.name,
            tags: this.tags
        }
    }

    get pageContent() {
        return this.content ?? this.parts?.map(part => part.page_content).join('\n')
    }
}

export class DocumentPart extends Model {
    static entity = 'document_part'

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            document_id: this.attr(null),
            page_content: this.string(''),
            headings: this.attr({}),
            index: this.number(0),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            document: this.belongsTo(Document, 'document_id'),
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}

export default Document;
