import { Model } from 'pinia-orm'
import { DateCast } from '@/models/utils'
import { Template } from '@/services/templates/models/TemplateHierarchy'
import { columnTypes } from './options'


export class SheetTemplate extends Template {
    static entity = 'sheettemplate'
    static baseEntity = 'template'

    static fields() {
        return {
            ...super.fields(),
            type: this.string('SHEETTEMPLATE'),

            // Relationships
            columns: this.hasMany(ColumnTemplate, 'template_id')
        }
    }

    get isUsable() {
        return this.columns.length > 0
    }
}

export class ColumnTemplate extends Model {
    static entity = 'columntemplate'

    static fields() {
        return {
            id: this.attr(null),
            template_id: this.attr(null),
            order: this.number(0),
            label: this.string(''),
            instructions: this.string(''),
            type: this.string('text'),
            options: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            template: this.belongsTo(SheetTemplate, 'template_id'),
        }
    }

    get icon() {
        return columnTypes.find(type => type.value === this.type)?.icon
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}

export default SheetTemplate;