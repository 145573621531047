<script setup>
import { Card, CardHeader, CardTitle, CardDescription, CardFooter } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { defineProps, defineEmits } from "vue";
import { ArrowRight, Clock } from "lucide-vue-next";
import { Badge } from "@/components/ui/badge";
import { SheetTemplateInfo } from '@/services/sheets'

defineProps({
    template: {
        type: Object,
        required: true,
    }
});

const emit = defineEmits(['click', 'apply']);
</script>

<template>
    <Card
        class="w-full h-full flex flex-col group hover:bg-accent transition-all duration-300 border-none cursor-pointer"
        @click="emit('click')">
        <CardHeader class="flex flex-row gap-2">
            <component :is="template.icon" class="w-6 h-6" />
            <div class="flex flex-col w-full gap-2">
                <div class="flex flex-row justify-between w-full items-center">
                    <CardTitle>{{ template.name }}</CardTitle>
                </div>

                <CardDescription class="line-clamp-3 mb-1" v-if="template.description">{{ template.description }}
                </CardDescription>
                <CardDescription class="line-clamp-3 mb-1" v-else>Describe the function of this template to increase
                    usability.</CardDescription>
                <div class="flex flex-row items-center gap-2">
                    <div v-if="!template.default" class="flex flex-row items-center gap-1">
                        <Clock class="w-3 h-3 text-muted-foreground items-center" />
                        <p class="text-xs font-medium text-muted-foreground">
                            {{ new Date(template.created_at).toLocaleString('nl-NL', { dateStyle: 'medium' }) }}
                        </p>
                    </div>
                    <SheetTemplateInfo v-if="template.type === 'SHEETTEMPLATE'" :template="template" />
                </div>
            </div>
        </CardHeader>
        <CardFooter class="flex justify-between items-center w-full mt-auto">
            <div class="flex items-center gap-2">
                <Avatar v-if="!template.default" class="h-8 w-8">
                    <AvatarImage :src="template.owner?.profile_picture_url" :alt="template.owner?.name" />
                    <AvatarFallback>{{ template.owner?.name?.charAt(0) ?? 'U' }}</AvatarFallback>
                </Avatar>
                <Avatar v-else class="size-10 rounded-lg bg-no-repeat bg-contain bg-center bg-transparent"
                    style="background-image: var(--symbol);">
                    <AvatarFallback />
                </Avatar>
                <div class="flex flex-col">
                    <p v-if="!template.default" class="text-sm font-medium">{{ template.owner?.name }}</p>
                    <Badge v-else variant="default" class="justify-end text-xs hover:bg-muted bg-muted">
                        <span class="text-xs text-primary">Curated by Zeno</span>
                    </Badge>
                    <p v-if="!template.default" class="text-xs text-muted-foreground">
                        {{ new Date(template.created_at).toLocaleString('nl-NL', { dateStyle: 'medium' }) }}
                    </p>

                </div>
            </div>

            <slot name="footer">
                <Button class="transition-all duration-300 text-muted-foreground hover:text-primary border-none "
                    variant="ghost" size="sm" @click.stop="emit('apply')">
                    <ArrowRight class="w-3 h-3 mr-1 " />
                    <span class="text-sm font-medium">Use template</span>
                </Button>
            </slot>
        </CardFooter>
    </Card>
</template>
