import './css/tailwind/tailwind.min.css';
import './main.css';

import { posthogPlugin, router, pinia } from '@/plugins';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { createApp } from 'vue';
import App from './App.vue';
import Citation from '@/components/answer/Citation.vue';

const app = createApp(App);

app.use(pinia);
app.use(posthogPlugin);
app.use(router);
app.use(VueQueryPlugin);

app.component('Citation', Citation);

app.mount('#app')