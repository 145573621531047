<script setup>
import { defineProps, defineEmits } from "vue";
import { PlusIcon, SaveIcon } from "lucide-vue-next";
import { Button } from "@/components/ui/button";
import TemplateCard from "./TemplateCard.vue";
import { useTemplates } from "../composables";
import * as Icons from "lucide-vue-next";
const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        required: true,
    },
    icon: {
        type: String,
        required: true,
    },
    color: {
        type: String,
        required: true,
    },
    onSaveAsTemplate: {
        type: Function,
        required: false
    }
});

const { templates, isLoading, createTemplate } = useTemplates(props.type);

const emit = defineEmits(['select', 'apply']);

async function handleCreateTemplate() {
    const template = await createTemplate();
    emit('select', template)
}

async function handleSaveAsTemplate() {
    const template = await props.onSaveAsTemplate();
    emit('select', template)
}
</script>

<template>
    <div class="flex flex-col gap-2">
        <div class="flex flex-row justify-between items-center pb-2">
            <div class="flex flex-row items-center gap-2">
                <component :is="Icons[icon]" :stroke-width="2.5" class="w-5 h-5" :class="color" />
                <h3 class="text-base font-semibold text-lg">{{ label }}</h3>
            </div>
            <div class="flex flex-row items-center gap-2">
                <Button v-if="onSaveAsTemplate" variant="outline" size="sm" @click="handleSaveAsTemplate"
                    class="flex flex-row items-center gap-2 ">
                    <SaveIcon class="w-4 h-4" />
                    <p class="text-sm">Save current sheet as template</p>
                </Button>
                <Button size="sm" @click="handleCreateTemplate" class="flex flex-row items-center gap-2 ">
                    <PlusIcon class="w-4 h-4" />
                    <p class="text-sm">Create</p>
                </Button>
            </div>
        </div>
        <div class="grid grid-cols-3 gap-4">
            <Loading v-if="isLoading" />
            <template v-else-if="templates.length > 0">
                <TemplateCard v-for="template in templates" :key="template.id" :template="template"
                    @click="emit('select', template)" @apply="emit('apply', template)" />
            </template>
            <template v-else>
                <div class="flex flex-col col-span-full items-center justify-center h-full w-full">
                    <p class="text-muted-foreground">No templates found</p>
                </div>
            </template>
        </div>
    </div>
</template>
