<script setup>
import { Shortcut, ShortcutGroup } from '@/components/shortcuts'
import { TemplateShortcut } from '@/services/templates'
import { useRepo, SheetTemplate, PromptTemplate } from '@/models'
import { Template } from '@/services/templates/models'
import { defineProps, computed, defineModel, onMounted, watch } from 'vue'
import { Grid2x2, LayoutTemplate, Upload, Text, ArrowLeft } from 'lucide-vue-next'
import { TemplateDialog } from '@/services/templates'
import { UploadButton } from '@/services/library'
import { useRouter, useRoute } from 'vue-router'
import { useQuery } from '@tanstack/vue-query'
import { SheetService } from '@/services/sheets'
import { cn } from '@/lib/utils'

const router = useRouter()
const route = useRoute()

const selectedShortcut = defineModel('shortcut', { type: Object, default: null })

const props = defineProps({
    types: { type: Array, default: () => ['threads', 'sheets', 'other'] },
    defaultOpen: { type: Boolean, default: false },
    alwaysOpen: { type: Boolean, default: false },
    defaultShortcut: { type: Object, default: null }
})

const templates = computed(() => {
    return {
        threads: [
            useRepo(PromptTemplate).where('default', true).where('stub', 'shortcut-1').first(),
            useRepo(PromptTemplate).where('default', true).where('stub', 'shortcut-2').first(),
            useRepo(PromptTemplate).where('default', true).where('stub', 'shortcut-3').first(),
        ].filter(p => p !== null).map(p => p.id),
        sheets: [
            useRepo(SheetTemplate).where('default', true).where('stub', 'shortcut-1').first(),
            useRepo(SheetTemplate).where('default', true).where('stub', 'shortcut-2').first(),
            useRepo(SheetTemplate).where('default', true).where('stub', 'shortcut-3').first(),
        ].filter(p => p !== null).map(p => p.id)
    }
})

const { isLoading } = useQuery({
    queryKey: ['shortcuts', props.types],
    queryFn: async () => await useRepo(Template).api().get('/templates/')
})

async function handleApply(shortcut) {
    if (shortcut instanceof PromptTemplate) {
        selectedShortcut.value = shortcut
    } else if (shortcut instanceof SheetTemplate) {
        const sheet = await SheetService.createFromTemplate({ template: shortcut })
        const routeConfig = {
            name: sheet.project_id ? 'project-sheet' : 'sheet',
            params: { sheetId: sheet.id, projectId: sheet.project_id },
        }
        router.push(routeConfig)
    }
}

const askAnythingShortcut = {
    name: 'Ask anything',
    description: 'Ask any legal question and get answers derived from up to date legal sources. This means that Zeno will use its legal knowledge and look up sources to find an answer. You can filter sources to make the answer more specific.',
    content: ''
}

onMounted(() => {
    if (props.defaultShortcut) {
        selectedShortcut.value = props.defaultShortcut
    }
})

watch(() => props.defaultShortcut, (newShortcut, oldShortcut) => {
    if (newShortcut && newShortcut.id !== oldShortcut?.id) {
        selectedShortcut.value = newShortcut
    }
})


</script>

<template>
    <div :class="cn(['flex flex-col space-y-2 h-full py-4 px-4', $attrs.class])" v-if="selectedShortcut">
        <div class="group flex flex-row items-center gap-2 cursor-pointer" @click="selectedShortcut = null">
            <ArrowLeft
                class="size-4 text-primary group-hover:text-primary group-hover:rotate-90 transition-all duration-300" />
            <p class="text-sm text-muted-foreground group-hover:text-primary transition-all duration-300">Go
                back</p>
        </div>
        <h1 class="text-xl text-left font-black">{{ selectedShortcut.name }}</h1>
        <p class="text-left text-sm text-muted-foreground">{{ selectedShortcut.description }}</p>
    </div>
    <div :class="cn(['flex flex-col space-y-4 h-full pb-4', $attrs.class])" v-if="!selectedShortcut && !isLoading">
        <ShortcutGroup v-if="types.includes('threads')" label="Chat with Zeno">
            <Shortcut @click="selectedShortcut = askAnythingShortcut" name="Ask anything"
                description="Ask any legal question and get answers derived from up to date legal sources">
                <Text class="text-yellow-500" />
            </Shortcut>
            <TemplateShortcut @apply="handleApply" v-for="id in templates.threads" :key="id" type="threads"
                :templateId="id" />
            <TemplateDialog v-if="types.length == 1" @apply="handleApply" :types="['threads']">
                <Shortcut name="Start from template" description="Start a chat from a template">
                    <LayoutTemplate class="text-primary" fill="currentColor" />
                </Shortcut>
            </TemplateDialog>
        </ShortcutGroup>
        <ShortcutGroup v-if="types.includes('sheets')" label="Sheet review">
            <Shortcut
                @click="SheetService.createSheet({ name: 'New Sheet', projectId: route.params?.projectId || null, folderId: route.query?.folderId || null })"
                name="Start blank sheet"
                description="Start a sheet review and analyze multiple documents at once in a tabular format">
                <Grid2x2 class="text-green-500" />
            </Shortcut>
            <TemplateShortcut @apply="handleApply" v-for="id in templates.sheets" :key="id" type="sheets"
                :templateId="id" />
        </ShortcutGroup>
        <ShortcutGroup v-if="types.includes('other')" label="Other actions">
            <TemplateDialog @apply="handleApply">
                <Shortcut name="Start from template" description="Start a chat or sheet review from a template">
                    <LayoutTemplate class="text-primary" fill="currentColor" />
                </Shortcut>
            </TemplateDialog>
            <UploadButton>
                <Shortcut name="Upload documents" description="Upload documents to use them with Zeno">
                    <Upload class="text-primary" />
                </Shortcut>
            </UploadButton>
        </ShortcutGroup>
    </div>
</template>