<script setup>
import { DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Layers } from "lucide-vue-next";
import { defineModel, defineProps } from "vue";
import TemplateGroup from "./TemplateGroup.vue";

const selectedTemplate = defineModel();

defineProps({
    types: {
        type: Array,
        required: true
    },
    onSaveAsTemplate: {
        type: Function,
        required: false
    }
})

const labels = {
    threads: 'Assistant templates',
    sheets: 'Sheet templates'
}

const icons = {
    threads: 'Component',
    sheets: 'Grid2x2'
}

const colors = {
    threads: 'text-blue-900',
    sheets: 'text-emerald-700'
}
</script>

<template>
    <DialogHeader>
        <DialogTitle>
            <div class="flex flex-row items-center">
                <Layers class="size-5 mr-2 shrink-0" />
                <span class="text-xl font-semibold">
                    Templates
                </span>
            </div>
        </DialogTitle>
        <DialogDescription>
            <div class="flex flex-row items-center">
                <span class="text-sm text-muted-foreground">
                    Select a template to use
                </span>
            </div>
        </DialogDescription>
    </DialogHeader>
    <TemplateGroup v-for="type in types" :key="type" :label="labels[type]" :type="type" :icon="icons[type]"
        :color="colors[type]" @select="selectedTemplate = $event" @apply="$emit('apply', $event)"
        @save-as-template="onSaveAsTemplate" />
</template>
