<script setup>
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import { Paperclip, File } from 'lucide-vue-next'
import { useRoute } from 'vue-router'
import { ref, defineModel, defineProps } from 'vue'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { LibraryTableDialog, LibraryService } from '@/services/library'
import { toast } from 'vue-sonner'

defineProps({
    maxDocuments: { type: Number, required: false },
    minDocuments: { type: Number, required: false }
})

const attachments = defineModel({ type: Array, default: () => [] })
const isOpen = ref(false)

const confirmSelection = async (selectedItems) => {
    try {
        toast.info('Adding attachments...')

        const flattenedDocuments = selectedItems.length > 0 ? await LibraryService.listDescendants(selectedItems, { entities: ['document'] }) : []
        const newCount = flattenedDocuments.length

        attachments.value = flattenedDocuments
        isOpen.value = false

        if (newCount > 0) {
            toast.success(`Successfully added ${newCount} attachment${newCount === 1 ? '' : 's'}`)
        } else {
            toast.info('No attachments selected')
        }
    } catch (error) {
        console.error(error)
        toast.error('Failed to add attachments')
    }
}

const route = useRoute()
</script>

<template>
    <TooltipProvider :delayDuration="0">
        <Tooltip>
            <TooltipTrigger as-child>
                <Button @click="isOpen = true" :disabled="maxDocuments == 0" variant="ghost" size="sm"
                    class="rounded-full hover:bg-background/90 border-none hover:outline-none hover:border-none relative space-x-2"
                    :class="{ 'bg-background rounded-full shadow-sm': attachments.length || isOpen }">
                    <Paperclip class="text-primary w-5 h-5" stroke-width="1.5" />
                    <span class="text-sm text-primary font-normal">Attach documents</span>
                    <Badge v-if="attachments.length" variant="ghost"
                        class="bg-secondary border-none text-white font-bold h-4 w-4 items-center justify-center absolute -right-0.5 -bottom-0.5 z-50">
                        {{ attachments.length }}
                    </Badge>
                </Button>
            </TooltipTrigger>
            <TooltipContent v-if="maxDocuments == 0">
                <p>Attachments are not yet supported for this use case</p>
            </TooltipContent>
            <TooltipContent v-else-if="attachments.length" class="flex flex-col items-center space-y-2 py-2">
                <span class="text-xs text-muted-foreground text-left w-full">Attachments</span>
                <div v-for="attachment in attachments" :key="attachment.id"
                    class="flex flex-row w-full justify-start items-center">
                    <File class="h-4 w-4 mr-2" />
                    <span>{{ attachment.name || attachment.title }}</span>
                </div>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
    <LibraryTableDialog :default-project-id="route.params?.projectId" :default-folder-id="null" v-model:open="isOpen"
        @submit="({ selectedItems }) => confirmSelection(selectedItems)" :default-selected="attachments"
        :filters="{ type: 'documents' }" selectable multiple allow-upload />
</template>