<script setup>
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogClose,
    DialogDescription
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { ResourceBreadcrumb } from '@/components/resources';
import LibraryTable from './LibraryTable.vue';
import { ref, defineProps, defineEmits, defineModel, toRef, watch } from 'vue';
import { UploadButton } from '@/services/library/components';
import * as icons from 'lucide-vue-next';
import {
    Popover,
    PopoverContent,
    PopoverTrigger
} from '@/components/ui/popover';
import { XIcon, ArrowUpRightIcon } from 'lucide-vue-next';

const props = defineProps({
    title: {
        type: String,
        required: false,
        default: 'Select Resources'
    },
    defaultProjectId: {
        type: String,
        required: false,
        default: null
    },
    defaultFolderId: {
        type: String,
        required: false,
        default: null
    },
    selectable: {
        type: [Boolean, String],
        required: false,
        default: false
    },
    multiple: {
        type: Boolean,
        required: false,
        default: false
    },
    keepSelection: {
        type: Boolean,
        required: false,
        default: false
    },
    filters: {
        type: Object,
        required: false,
    },
    defaultSelected: {
        type: Array,
        required: false,
        default: () => []
    },
    minSelected: {
        type: Number,
        required: false,
        default: 0
    },
    allowUpload: {
        type: Boolean,
        required: false,
        default: false
    },
    stopPropagateSelection: {
        type: Boolean,
        required: false,
        default: false
    }
});

const selectedItems = toRef(props.defaultSelected);
const projectId = toRef(props.defaultProjectId);
const folderId = toRef(props.defaultFolderId);
const emit = defineEmits(['submit']);
const open = defineModel('open', { default: false });
const popoverOpen = ref(false);

watch(() => props.defaultSelected, (newSelected, oldSelected) => {
    const areArraysEqual = (arr1, arr2) => {
        // Handle null/undefined cases
        if (!arr1 && !arr2) return true;
        if (!arr1 || !arr2) return false;

        const ids1 = new Set(arr1.map(item => item.id));
        const ids2 = new Set(arr2.map(item => item.id));
        return ids1.size === ids2.size &&
            [...ids1].every(id => ids2.has(id));
    };

    if (!areArraysEqual(newSelected, oldSelected)) {
        selectedItems.value = newSelected || [];  // Ensure we always set an array
    }
}, { immediate: true })

watch(() => props.defaultProjectId, (newProjectId, oldProjectId) => {
    if (newProjectId !== oldProjectId) {
        projectId.value = newProjectId;
    }
}, { immediate: true })

watch(() => props.defaultFolderId, (newFolderId, oldFolderId) => {
    if (newFolderId !== oldFolderId) {
        folderId.value = newFolderId;
    }
}, { immediate: true })

const submit = () => {
    emit('submit', {
        selectedItems: selectedItems.value,
        projectId: projectId.value,
        folderId: folderId.value
    });
    selectedItems.value = props.defaultSelected;
    open.value = false;
}

const close = (v) => {
    if (v) return;
    if (open.value) open.value = false;
    selectedItems.value = props.defaultSelected;
    projectId.value = props.defaultProjectId;
    folderId.value = props.defaultFolderId;
}
</script>

<template>
    <Dialog v-model:open="open" @update:open="close">
        <DialogTrigger as-child>
            <slot />
        </DialogTrigger>
        <DialogContent class="max-w-4xl overflow-y-auto p-0">
            <DialogHeader class="flex flex-col gap-4 pt-2 px-2">
                <ResourceBreadcrumb v-model:project-id="projectId" v-model:folder-id="folderId" embedded />
                <UploadButton v-if="allowUpload" :project-id="projectId" :folder-id="folderId" />
                <DialogTitle v-if="$slots.title" class="px-4">
                    <slot name="title" />
                </DialogTitle>
                <DialogDescription v-if="$slots.description" class="px-4">
                    <slot name="description" />
                </DialogDescription>
            </DialogHeader>
            <div class="overflow-y-auto h-[25rem] border-b">
                <LibraryTable v-model="selectedItems" :propagate-selection="!stopPropagateSelection"
                    v-model:project-id="projectId" v-model:folder-id="folderId" v-bind="props"
                    :selectable="selectable ? 'checkbox' : false" :multiple="multiple" embedded />
            </div>
            <DialogFooter class="flex justify-between gap-2 px-6 pb-6 pt-2">
                <div class="flex justify-start gap-2">
                    <slot name="footer">
                        <Popover v-model:open="popoverOpen">
                            <PopoverTrigger>
                                <Button variant="link"
                                    class="text-sm text-muted-foreground whitespace-nowrap p-0 h-auto">
                                    {{selectedItems.filter(i => props.filters?.type?.includes(i.type.toLowerCase()
                                        + 's')).length}} selected
                                    <ArrowUpRightIcon class="h-4 w-4 ml-1" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent align="start" side="top" class="w-fit min-w-[15rem]">
                                <div class="flex items-center justify-between w-full pl-2 pr-6">
                                    <h1 class="font-bold">Selected items</h1>
                                    <Button variant="link" size="xs" class="text-muted-foreground h-6 w-6"
                                        @click="selectedItems = []; popoverOpen = false">
                                        Clear all
                                    </Button>
                                </div>
                                <div v-for="item in selectedItems" :key="item.id"
                                    class="flex items-center justify-between gap-2 p-1 rounded hover:bg-muted group">
                                    <div class="flex items-center gap-1">
                                        <component :is="icons[item.icon]" :class="`text-${item.color}`" class="h-4" />
                                        <span class="text-sm">{{ item.name }}</span>
                                    </div>
                                    <Button variant="link" size="icon" class="h-6 w-6"
                                        @click="selectedItems = selectedItems.filter(i => i.id !== item.id)">
                                        <XIcon
                                            class="text-muted-foreground h-4 w-4 opacity-0 group-hover:opacity-100" />
                                    </Button>
                                </div>
                            </PopoverContent>
                        </Popover>
                    </slot>
                </div>
                <div class="flex justify-end gap-2">
                    <DialogClose as-child>
                        <Button variant="outline">Cancel</Button>
                    </DialogClose>
                    <slot :disabled="selectedItems.length < minSelected" :selectedItems="selectedItems" :close="close"
                        name="submit">
                        <Button @click="submit" :disabled="selectedItems.length < minSelected">Submit</Button>
                    </slot>
                </div>
            </DialogFooter>
        </DialogContent>
    </Dialog>
</template>
