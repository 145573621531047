<script setup>
import { watch, defineProps, defineExpose, onMounted, onBeforeUnmount, defineModel, ref, computed } from 'vue';
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Heading from '@tiptap/extension-heading'
import { Markdown } from 'tiptap-markdown'
import Focus from '@tiptap/extension-focus'
import Toolbar from './Toolbar.vue'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'

const content = defineModel('content', {
    type: String,
    required: true
})

const props = defineProps({
    defaultSelectedCommentId: {
        type: String,
        required: false,
        default: null
    },
    editable: {
        type: Boolean,
        required: false,
        default: false
    },
    defaultSelectedText: {
        type: String,
        required: false,
        default: null
    }
})
const selectedCommentId = ref(props.defaultSelectedCommentId)

const parseNode = (node) => {
    if (Array.isArray(node)) return node.flatMap(parseNode);
    if (!(node && typeof node === 'object')) return node;
    if (node.type === 'text') {
        if (node.text.trim() === '') return null;
        const parsedNode = Object.assign({}, node);
        parsedNode.marks = []; // Temporary fix for sporadic formatting issues
        const citationMarks = node.marks?.filter((mark) => mark.type === 'citation');
        if (citationMarks?.length > 0) {
            const citationNodes = citationMarks
                .map((mark) => Object.assign({}, {
                    type: 'citation',
                    attrs: Object.assign({}, mark.attrs),
                }));
            return [parsedNode, ...citationNodes];
        }
        return parsedNode;
    }
    const parsedNode = Object.fromEntries(Object.entries(node).map(([key, value]) => [key, parseNode(value)]))
    if (parsedNode.content !== undefined) parsedNode.content = parsedNode.content.filter((node) => node !== null);
    return parsedNode;
}

const parsedContent = computed(() => parseNode(content.value));

const editor = useEditor({
    editorProps: {
        attributes: {
            class: 'text-left p-8 mx-auto focus:outline-none text-primary bg-transparent h-full max-w-[50svw] rounded-b-2xl'
        }
    },
    content: parsedContent.value,
    extensions: [
        StarterKit,
        Heading.configure({
            levels: [1, 2, 3, 4]
        }),
        ListItem,
        OrderedList,
        BulletList,
        Table,
        TableRow,
        TableCell,
        TableHeader,
        Markdown.configure({
            breaks: true
        }),
        Focus.configure({
            className: 'has-focus',
            mode: 'shallowest',
        }),
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
        Highlight.configure({ multicolor: true }),
        Image.configure({
            allowBase64: true
        }),
        Link
    ],
    autofocus: true,
    editable: props.editable,
    onCreate: ({ editor }) => {
        if (props.defaultSelectedText) {
            const range = String(content.value).indexOf(props.defaultSelectedText)
            editor.setEditable(true)
            editor.chain().focus().setTextSelection({
                from: range - 300,
                to: range + props.defaultSelectedText.length + 300
            }).setMark("highlight", { color: "yellow" }).scrollIntoView().run()
            setTimeout(() => {
                editor.setEditable(props.editable)
            }, 10)
        }
    },
})

onMounted(() => {
    if (editor.value) {
        editor.value.on('transaction', ({ transaction }) => {
            const commentClicked = transaction.getMeta('commentClicked')
            if (commentClicked) selectedCommentId.value = commentClicked.id
        })
    }
})

onBeforeUnmount(() => {
    editor.value?.destroy()
})

watch(() => content.value, (newContent) => {
    if (newContent !== editor.value.getJSON()) {
        editor.value.commands.setContent(newContent, false)
        if (props.defaultSelectedText) {
            const range = content.value.indexOf(props.defaultSelectedText)
            editor.value.chain().focus().setTextSelection({
                from: range,
                to: range + props.defaultSelectedText.length
            }).setMark("highlight").scrollIntoView().run()
        }
    }
})

const bubbleMenuRef = ref(null)

defineExpose({ editor })

function shouldShow({
    editor,
    element,
    view
}) {
    // const { doc, selection } = state
    // const { empty } = selection

    // // Sometime check for `empty` is not enough.
    // // Doubleclick an empty paragraph returns a node size of 2.
    // // So we check also for an empty text size.
    // const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection)

    // When clicking on a element inside the bubble menu the editor "blur" event
    // is called and the bubble menu item is focussed. In this case we should
    // consider the menu as part of the editor and keep showing the menu
    const isChildOfMenu = element?.contains(document.activeElement)

    const hasEditorFocus = view.hasFocus() || isChildOfMenu

    if (!hasEditorFocus || !editor.isEditable) {
        return false
    }

    return true
}

function getReferenceClientRect() {
    const el = window.document.querySelector('.has-focus')
    return el?.getBoundingClientRect()
}
</script>

<template>
    <BubbleMenu ref="bubbleMenuRef" :editor="editor" :should-show="shouldShow"
        :tippy-options="{ duration: 300, placement: 'top-start', offset: [-10, 15], followCursor: false, getReferenceClientRect }"
        v-if="editor">
        <Toolbar :editor="editor" />
    </BubbleMenu>
    <EditorContent :editor="editor" />
</template>

<style>
/* .has-focus {
    @apply rounded-md ring-2 ring-blue-500/90 ring-offset-background ring-offset-8 transition-all ease-out duration-150;
} */

/* Basic editor styles */
.tiptap {
    @apply caret-secondary;

    /* Use direct child selector for first-child */
    >*:first-child {
        margin-top: 0;
    }

    /* List styles */
    ul,
    ol {
        padding: 0 1rem;
        margin: 1.25rem 1rem 1.25rem 1.5rem;
        /* Increased left margin */
    }

    ul {
        list-style-type: disc;
        /* Add bullet points for unordered lists */
    }

    ol {
        list-style-type: decimal;
        /* Add numbers for ordered lists */
    }

    ul li,
    ol li {
        margin-bottom: 0.3em;
        /* Add some space between list items */
    }

    ul li p,
    ol li p {
        margin-top: 0.1em;
        margin-bottom: 0.1em;
    }

    /* Heading styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.3;
        text-wrap: pretty;
        font-weight: 600;
        margin-bottom: 0.5rem;
        margin-top: 2rem;
    }

    h1,
    h2 {
        margin-top: 1.2rem;
        margin-bottom: 1rem;
    }

    h1 {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.2rem;
    }

    h3 {
        font-size: 1rem;
    }

    h4,
    h5,
    h6 {
        font-size: 0.8rem;
    }

    p {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }

    mark {
        background-color: #fa9fa5;
        border-radius: 0.1rem;
        box-decoration-break: clone;

    }

    /* Code and preformatted text styles */
    code {
        background-color: var(--purple-light);
        border-radius: 0.4rem;
        color: var(--black);
        font-size: 0.85rem;
        padding: 0.25em 0.3em;
    }

    pre {
        background: var(--black);
        border-radius: 0.5rem;
        color: var(--white);
        font-family: 'JetBrainsMono', monospace;
        margin: 1rem 0;
        padding: 0.75rem 1rem;
    }

    pre code {
        background: none;
        color: inherit;
        font-size: 0.8rem;
        padding: 0;
    }

    blockquote {
        border-left: 3px solid var(--gray-3);
        margin: 1.5rem 0;
        padding-left: 1rem;
    }

    hr {
        border: none;
        border-top: 1px solid #e0e0e0;
        margin: 1rem 0;
    }
}

/* New styles for comments */
.ProseMirror .tiptap-comment {
    background-color: rgba(255, 212, 0, 0.2);
    border-bottom: 2px solid rgba(255, 212, 0, 0.5);
    cursor: pointer;
}

.ProseMirror .comment-number {
    font-size: 0.7em;
    font-weight: bold;
    background-color: rgba(255, 212, 0, 0.5);
    color: #333;
    padding: 0 4px;
    border-radius: 8px;
    vertical-align: super;
    margin-left: 2px;
}

.comment-number-card {
    font-size: 0.9em;
    font-weight: bold;
    background-color: rgba(255, 212, 0, 0.5);
    color: #333;
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 2px;
}


.comment-item {
    transition: all 0.2s ease;
}

/* .comment-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

/* Add these new styles for better responsiveness */
.ProseMirror {
    min-height: 100%;
    max-height: none;
    /* Remove the max-height constraint */
    overflow-y: visible;
    /* Let the parent container handle scrolling */
}

.comment-item,
.chat-item {
    max-width: 100%;
    word-wrap: break-word;
}

/* Add this to your existing styles */
.tiptap {
    height: 100%;
    overflow-y: auto;
}

/* Custom scrollbar styles */
.EditorContent::-webkit-scrollbar {
    width: 8px;
}

.EditorContent::-webkit-scrollbar-track {
    background: transparent;
}

.EditorContent::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.EditorContent::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.chat-item {
    width: 100%;
}

.zeno-message,
.user-message {
    max-width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.user-message {
    float: right;
    clear: both;
}

/* New styles for fade-out effect */
.editor-container {
    position: relative;
}

.editor-content {
    position: relative;
    overflow-y: auto;
    max-height: calc(100vh - 55px);
    /* Adjust this value as needed */
}
</style>
