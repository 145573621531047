<script setup>
import Suggestions from './Suggestions.vue'
import Progress from './Progress.vue'
import { Answer } from '@/components/answer'
import { defineProps, toRefs } from 'vue'

const props = defineProps(['run', 'isLast'])
const { run, isLast } = toRefs(props)
</script>

<template>
    <div class="flex flex-col w-full p-4 space-y-4">
        <Progress v-if="!run?.answer && run?.status !== 'failed'" :plan="run?.plan" />
        <div v-if="run?.status === 'failed'">
            <div class="text-red-500">
                <span>There was an error generating your answer, please try again.</span>
            </div>
        </div>
        <Answer :content="run?.answer" :disable-actions="run?.status !== 'completed'"
            :enable-fade-in="run?.status === 'running'" />
        <Suggestions v-if="isLast && (run?.status === 'completed') && run?.suggested_follow_ups?.length > 0"
            @submit="$emit('submit', $event)" :suggestions="run?.suggested_follow_ups" />
    </div>
</template>

<style scoped>
.sticky {
    position: sticky;
    max-height: calc(100vh - 120px);
    /* Account for navbar and searchbar */
    overflow-y: auto;
}

@media (max-width: 768px) {
    .reference-list-hidden-mobile {
        display: none;
    }
}
</style>