import { api } from '@/plugins/api'
import { ref, watch } from 'vue'

export function useCompletions() {
    const prompt = ref('')
    const completion = ref('')
    const isLoading = ref(false)
    const currentController = ref(null)
    
    async function getCompletion() {
        isLoading.value = true
        if (currentController.value) {
            currentController.value.abort()
        }
        currentController.value = new AbortController()
        
        try {
            await api.get(
                '/completions/',
                {
                    params: {
                        prompt: prompt.value
                    },
                    responseType: 'text',
                    signal: currentController.value.signal,
                    onDownloadProgress: (progressEvent) => {
                        const text = progressEvent.event.target.responseText
                        try {
                            if (text !== completion.value) {
                                completion.value = text
                            }
                        } catch (e) {
                            console.error('Error processing completion chunk:', e)
                        }
                    }
                }
            )
        } catch (error) {
            if (error.name !== 'CanceledError') {
                console.error('Error fetching completion:', error)
            }
        } finally {
            prompt.value = ''
            isLoading.value = false
        }
    }

    watch(prompt, async (newPrompt) => {
        if (newPrompt) {
            await getCompletion()
        }
    })

    return { prompt, completion, isLoading }
}