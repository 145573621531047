<script setup>
import { MainLayout, NavbarLayout, HeaderLayout } from '@/layouts'
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage } from '@/components/ui/breadcrumb'
import { Box, Plus, List } from 'lucide-vue-next'
import { useRouter } from 'vue-router'
import { Button } from '@/components/ui/button'
import { CreateProjectDialog } from '../components'
import { DataTable } from '@/components/data-table'
import { useProjects } from '../composables'

const router = useRouter()

const { projects, isLoading } = useProjects()

function open(project) {
    router.push({ name: 'project', params: { projectId: project.id } })
}
</script>

<template>
    <MainLayout>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <Breadcrumb>
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <BreadcrumbPage class="flex flex-row space-x-2 items-center">
                                    <List />
                                    <span>All Projects</span>
                                </BreadcrumbPage>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </template>
            </NavbarLayout>
        </template>
        <template #header>
            <HeaderLayout>
                <template #left-actions>
                    <CreateProjectDialog>
                        <Button size="sm" variant="default" class="flex flex-row space-x-2 items-center rounded-3xl">
                            <Plus class="w-4 h-4 flex-shrink-0" :stroke-width="2.5" />
                            <span>New Project</span>
                        </Button>
                    </CreateProjectDialog>
                </template>
            </HeaderLayout>
        </template>
        <template #content>
            <DataTable :is-loading="isLoading" :items="projects || []" time-column="Created" selectable multiple
                context-menu @dblclick="open" searchable>
                <template #is-empty>
                    <Box class="size-12" />
                    <span>Nothing here yet...</span>
                </template>
            </DataTable>
        </template>
    </MainLayout>
</template>
