import { useThreadSocket } from './use-thread-socket';
import { ThreadService } from '../service';

export function useThread(threadId) {
    const {
        thread,
        isLoading
    } = ThreadService.getThread(threadId)

    const {
        startRun,
        cancelRun,
    } = useThreadSocket(thread)

    return {
        thread,
        isLoading,
        startRun,
        cancelRun,
        saveThread: async () => await ThreadService.updateThread(thread),
        deleteThread: async () => await ThreadService.deleteThread(thread),
    }
}