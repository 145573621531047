<script setup>
import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Input } from '@/components/ui/input'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { toast } from 'vue-sonner'
import { defineModel, ref } from 'vue'
import { api } from '@/plugins/api'
import { useAuth } from '@/auth'

const auth = useAuth()
const members = defineModel()
const inviteEmail = ref('')

async function inviteMember() {
    if (!inviteEmail.value) {
        toast.error('Voer een email adres in')
        return
    }
    try {
        await api.post(`/users/invite`, inviteEmail.value)
        toast.success('Uitnodiging verstuurd naar ' + inviteEmail.value)
        inviteEmail.value = ''
    } catch (error) {
        toast.error('Er is iets misgegaan bij het uitnodigen van een lid')
    }
}

async function handleRoleChange(memberId, newRole) {
    try {
        await api.patch(`/organizations/memberships/${memberId}`, { role: newRole })
        toast.success('Rol succesvol bijgewerkt')
    } catch (error) {
        toast.error('Er is iets misgegaan bij het wijzigen van de rol')
    }
}
</script>

<template>
    <div class="flex flex-col space-y-4 w-full">
        <div v-if="auth.role === 'owner' || auth.role === 'admin'" class="flex w-full max-w-sm items-center gap-1.5">
            <Input id="email" type="email" v-model="inviteEmail" placeholder="Stuur uitnodiging..." />
            <Button type="submit" @click="inviteMember">
                Uitnodigen
            </Button>
        </div>
        <ScrollArea class="h-full w-[325px]">
            <div v-for="member in members" :key="member.id"
                class="flex items-center justify-between space-x-4 space-y-2 min-w-0">
                <Avatar class="rounded-lg flex-shrink-0">
                    <AvatarImage :src="member.profile_picture_url" />
                    <AvatarFallback>{{ member.first_name[0] }}{{ member.last_name[0] }}</AvatarFallback>
                </Avatar>
                <div class="min-w-0 flex-1">
                    <p class="text-sm font-medium leading-none truncate">
                        {{ member.first_name }} {{ member.last_name }}
                    </p>
                    <p class="text-xs text-muted-foreground truncate">
                        {{ member.email }}
                    </p>
                </div>
                <Select :value="member.role"
                    :disabled="(auth.role !== 'owner' && auth.role !== 'admin') || member.role === 'owner'"
                    @update:modelValue="(role) => handleRoleChange(member.id, role)">
                    <SelectTrigger class="w-[110px]">
                        <SelectValue :placeholder="member.role.charAt(0).toUpperCase() + member.role.slice(1)" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="member">Member</SelectItem>
                        <SelectItem value="admin">Admin</SelectItem>
                        <!-- <SelectItem value="owner">Owner</SelectItem> -->
                    </SelectContent>
                </Select>
            </div>
        </ScrollArea>
    </div>
</template>
