<script setup>
import { defineModel, inject, defineProps } from 'vue'
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetDescription,
} from "@/components/ui/sheet"
import Cell from './Cell.vue'
import { ScrollArea } from '@/components/ui/scroll-area'
import { BrainCircuit, Text } from 'lucide-vue-next'
import { Badge } from '@/components/ui/badge'
import { Answer } from '@/components/answer'
import { useRepo } from '@/models'
import { Cell as CellModel } from '../models'

const repo = useRepo(CellModel)

const open = defineModel('open', { type: Boolean, default: false })
defineProps({
    row: {
        type: Object,
        required: true
    }
})
const container = inject('container')
</script>

<template>
    <Sheet v-if="container !== null && row" v-model:open="open" class="max-w-full">
        <SheetContent side="right" class="w-[40%] max-h-screen overflow-y-auto min-w-[40%]"
            :container="container.container">
            <SheetHeader>
                <SheetTitle>{{ row.attachment.name }}</SheetTitle>
                <SheetDescription>
                    Row overview and responses
                </SheetDescription>
            </SheetHeader>

            <ScrollArea class="mt-6 space-y-4">
                <div v-for="cell in repo.with('column').where('row_id', row.id).orderBy('column.order').get()"
                    :key="cell.id" class="space-y-6 border-t border-border">
                    <div class="space-y-2">
                        <div class="flex items-center gap-2 text-sm text-muted-foreground mt-4 mb-2">
                            <component :is="cell.column.icon" class="w-6 h-6 shrink-0" />
                            <h1 class="text-lg text-left font-black">{{ cell.column.label }} </h1>
                        </div>
                        <p class="text-[16px] text-muted-foreground text-left">{{
                            cell.column.instructions }}</p>

                        <div v-if="cell.column.options" class="text-left flex flex-col gap-2">
                            <p class="text-xs text-muted-foreground">Options:</p>
                            <Badge variant="outline" class="text-xs w-fit justify-start"
                                v-for="option in cell.column.options" :key="option">
                                {{ option }}
                            </Badge>
                        </div>
                    </div>
                    <div class="space-y-2">
                        <div class="flex items-center gap-2 text-md text-muted-foreground mt-2">
                            <Text class="w-4 h-4" />
                            <span class="text-[16px]">Answer</span>
                        </div>
                        <Cell :cell="cell" :type="cell.column.type" :isTextWrapped="false" :isReadOnly="true" />
                    </div>
                    <div class="space-y-2 pb-4">
                        <div class="flex items-center gap-2 text-md text-muted-foreground mt-2">
                            <BrainCircuit class="w-4 h-4 " />
                            <span class="text-[16px] ">Reasoning</span>
                        </div>
                        <Answer class="whitespace-pre-wrap break-words text-sm" :content="cell.reasoning"
                            menu-position="bottom" />
                    </div>
                </div>
            </ScrollArea>
        </SheetContent>
    </Sheet>
</template>
