import { getTemplates, createTemplate as createTemplateService, updateTemplate, deleteTemplate, getType } from '../service'
import { computed } from 'vue'

export function useTemplates(type) {
    const { templates, isLoading, isFetching, isError, error } = getTemplates()
    return {
        templates: computed(() => templates.value.filter(template => getType(template) === type)),
        isLoading,
        isFetching,
        isError,
        error,
        createTemplate: ({ name = "New template", description = null } = {}) => createTemplateService({ type, name, description }),
        updateTemplate,
        deleteTemplate
    }
}