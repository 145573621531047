import { useQuery } from '@tanstack/vue-query'
import { useRepo } from '@/models'
import { SheetTemplate, ColumnTemplate } from '@/services/sheets/models'
import { toValue, computed } from 'vue'
import { TemplateService } from '@/services/templates/service'
import { toast } from 'vue-sonner'

export function useSheetTemplate(template) {
    const templateId = computed(() => toValue(template).id);
    const { data, isLoading } = useQuery({
        queryKey: ['templates', 'sheets', templateId],
        queryFn: async () => {
            const response = await useRepo(SheetTemplate).api().get(`/sheets/templates/${toValue(templateId)}`)
            return useRepo(SheetTemplate).withAllRecursive().find(response.entities[0].id)
        }
    })

    const addColumn = async (columnSpec) => {
        await useRepo(ColumnTemplate).api().post(`/sheets/templates/${toValue(templateId)}/columns/`, toValue(columnSpec))
    }

    const updateColumn = async (columnNumber, columnSpec) => {
        await useRepo(ColumnTemplate).api().patch(`/sheets/templates/${toValue(templateId)}/columns/${toValue(columnNumber)}`, toValue(columnSpec))
    }

    const deleteColumn = async (column) => {
        await useRepo(ColumnTemplate).api().delete(`/sheets/templates/${toValue(templateId)}/columns/${toValue(column).order}`, { delete: toValue(column).id })
    }

    const saveTemplate = async (data) => {
        const originalTemplate = toValue(template)
        const updatedTemplate = toValue(data)
        
        // Update template metadata if changed
        if (originalTemplate.name !== updatedTemplate.name || 
            originalTemplate.description !== updatedTemplate.description) {
            await useRepo(SheetTemplate).api().patch(
                `/sheets/templates/${toValue(templateId)}`,
                {
                    name: updatedTemplate.name,
                    description: updatedTemplate.description
                }
            )
        }

        // Handle column changes
        const originalColumns = originalTemplate.columns || []
        const updatedColumns = updatedTemplate.columns || []

        // Process columns sequentially by index
        for (let i = 0; i < updatedColumns.length; i++) {
            const updatedColumn = updatedColumns[i]
            const originalColumn = originalColumns[i]

            if (originalColumn) {
                // Column exists at this index - update if changed
                if (JSON.stringify(originalColumn) !== JSON.stringify(updatedColumn)) {
                    await updateColumn(originalColumn.order, updatedColumn)
                }
            } else {
                // No column at this index - add new column
                await addColumn(updatedColumn)
            }
        }

        // Delete any remaining original columns
        if (originalColumns.length > updatedColumns.length) {
            for (let i = updatedColumns.length; i < originalColumns.length; i++) {
                await deleteColumn(originalColumns[i])
            }
        }
        toast.success('Template saved successfully')
    }

    return {
        template: data,
        isLoading,
        addColumn,
        updateColumn,
        deleteColumn,
        saveTemplate,
        deleteTemplate: async () => await TemplateService.deleteTemplate(template)
    }
}