import { Model } from 'pinia-orm'
import { columnTypes } from './options'
import { Document } from '@/models'
import { Resource } from '@/services/library/models/ResourceHierarchy'
import { DateCast } from '@/models/utils'

export class Sheet extends Resource {
    static entity = 'sheet'
    static baseEntity = 'resource'

    static fields() {
        return {
            ...super.fields(),
            type: this.string('SHEET'),
            icon: this.string('Grid2x2'),
            color: this.string('emerald-700'),

            // Relationships
            columns: this.hasMany(Column, 'sheet_id'),
            rows: this.hasMany(Row, 'sheet_id'),
            cells: this.hasMany(Cell, 'sheet_id'),
            attachments: this.belongsToMany(Document, Row, 'sheet_id', 'attachment_id'),
        }
    }

    get isRunning() {
        return this.cells.some(cell => cell.status === 'running')
    }

    get progress() {
        if (!this.cells?.length) return 0
        const totalCells = this.cells.length
        const completedCells = this.cells.filter(cell => cell.status === 'completed').length
        return Math.round((completedCells / totalCells) * 100)
    }

    get failedCells() {
        return this.cells.filter(cell => cell.status === 'failed')
    }
}

export class Column extends Model {
    static entity = 'columns'

    static fields() {
        return {
            id: this.attr(null),
            order: this.number(null),
            label: this.string(''),
            instructions: this.string(''),
            type: this.string('text'),
            options: this.attr(null),
            sheet_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            sheet: this.belongsTo(Sheet, 'sheet_id'),
            cells: this.hasMany(Cell, 'column_id')
        }
    }

    get icon() {
        return columnTypes.find(type => type.value === this.type)?.icon
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}

export class Row extends Model {
    static entity = 'rows'

    static fields() {
        return {
            id: this.attr(null),
            order: this.number(null),
            sheet_id: this.attr(null),
            attachment_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            sheet: this.belongsTo(Sheet, 'sheet_id'),
            attachment: this.belongsTo(Document, 'attachment_id'),
            cells: this.hasMany(Cell, 'row_id')
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}

export class Cell extends Model {
    static entity = 'cells'

    static fields() {
        return {
            id: this.attr(null),
            status: this.string('pending'),
            reasoning: this.string(null),
            value: this.attr(null),
            sheet_id: this.attr(null),
            column_id: this.attr(null),
            row_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            sheet: this.belongsTo(Sheet, 'sheet_id'),
            column: this.belongsTo(Column, 'column_id'),
            row: this.belongsTo(Row, 'row_id')
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}

export default Sheet;