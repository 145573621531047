<script setup>
import { defineModel, defineProps, provide, toRefs } from 'vue'
import { useSelectable } from './use-selectable'

const props = defineProps({
    items: {
        type: Array,
        required: false,
        default: () => []
    },
    selectMode: {
        type: String,
        required: false,
        default: 'replace'
    },
    multiple: {
        type: Boolean,
        required: false,
        default: false
    },
    propagateSelection: {
        type: Boolean,
        required: false,
        default: false
    }
})
const selectedItems = defineModel('selectedItems', { default: () => [] })

const { items } = toRefs(props)
const { toggleSelect, isSelected, toggleSelectAll, isAllSelected } = useSelectable(items, selectedItems, { multiple: props.multiple, selectMode: props.selectMode, propagateSelection: props.propagateSelection })

provide('toggleSelect', toggleSelect)
provide('isSelected', isSelected)
provide('items', items)
</script>

<template>
    <slot :selectedItems="selectedItems" :toggleSelectAll="toggleSelectAll" :isAllSelected="isAllSelected" />
</template>
