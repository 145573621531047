<script setup>
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import { defineModel, defineProps, defineEmits, computed, watch, ref } from 'vue'
import { ArrowUp, Loader } from 'lucide-vue-next';
import { ModeTabs } from '@/components/command-input'
import Attachments from './Attachments.vue'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'
import { Dropzone } from '@/components/behaviour/dropzone'
import { LibraryService } from '@/services/library'
import { toast } from 'vue-sonner'

const emit = defineEmits(['submit'])
const props = defineProps({
    defaultOpen: { type: Boolean, default: false },
    defaultMode: { type: String, default: 'review' },
    defaultShortcut: { type: Object, default: null },
    isRunning: { type: Boolean, default: false },
    strict: { type: Boolean, default: false }
})

const modes = {
    'review': {
        placeholder: 'Attach documents and start asking questions',
        minDocuments: 1,
        maxDocuments: null,
        beta: false,
        name: 'Document Review'
    },
    'draft': {
        placeholder: 'Give clear instructions and examples to start drafting',
        minDocuments: 0,
        maxDocuments: null,
        beta: true,
        name: 'Drafting'
    },
    'research': {
        placeholder: 'Describe your legal issue in detail to start your research',
        minDocuments: 0,
        maxDocuments: null,
        beta: true,
        name: 'Legal Research'
    }
}

const mode = ref(props.defaultMode)
const shortcut = ref(props.defaultShortcut)
const prompt = ref(props.defaultShortcut?.content || '')
const shortcutsOpen = ref(props.defaultOpen)
const attachments = defineModel('attachments', { type: Array, default: () => [] })

const modeInfo = computed(() => modes[mode.value ?? 'review'])
const disableSend = computed(() => {
    if (!modeInfo.value) return true
    if (!props.strict) return false
    if (attachments.value) {
        if (modeInfo.value?.maxDocuments !== null && attachments.value.length > modeInfo.value.maxDocuments) {
            return true
        }
        if (modeInfo.value?.minDocuments && attachments.value.length < modeInfo.value.minDocuments) {
            return true
        }
    }
    return false
})

const preventAttachments = computed(() => {
    return modeInfo.value?.maxDocuments === 0
})

function submit(event) {
    if (props.isRunning || disableSend.value || !prompt.value.trim() || (event.shiftKey && event.key === 'Enter')) return
    emit('submit', {
        prompt,
        attachments,
        mode,
        name: shortcut?.value?.name || null
    })
    attachments.value = []
    shortcut.value = null
    prompt.value = ''
    shortcutsOpen.value = false
}

async function handleDrop(event) {
    if (preventAttachments.value) return
    if (!event) return
    try {
        toast.info('Adding attachments...')
        const items = JSON.parse(event.dataTransfer?.getData('text/plain'))
        const documents = await LibraryService.listDescendants(items, { entities: ['document'] })
        if (documents.length > 0) {
            if (!attachments.value) attachments.value = []
            const nonDuplicateItems = documents.filter(item => !attachments.value.some(i => i.id === item.id))
            const newCount = nonDuplicateItems.length

            if (newCount > 0) {
                attachments.value = [...new Set([...attachments.value, ...nonDuplicateItems])]
                toast.success(`Successfully added ${newCount} attachment${newCount === 1 ? '' : 's'}`)
            } else {
                toast.info('No new attachments to add')
            }
        } else {
            toast.info('No attachments found')
        }
    } catch (error) {
        console.error(error)
        toast.error('Failed to add attachments')
    }
}

watch(mode, (newMode) => {
    if (newMode === 'research') attachments.value = []
})

watch(shortcut, (newShortcut) => {
    if (newShortcut) prompt.value = newShortcut.content
})
</script>

<template>
    <div :class="cn(['bg-background', $attrs.class])">
        <div class="flex flex-row items-center w-full">
            <ModeTabs v-model:mode="mode" v-model:shortcut="shortcut" v-model:open="shortcutsOpen" />
        </div>
        <Dropzone v-slot="{ handleDragEnter, handleDragOver }" @drop="handleDrop" :disabled="preventAttachments">
            <div class="flex flex-col w-full p-2 bg-accent dark:bg-accent/50 rounded-3xl">
                <Textarea @dragstart="preventAttachments ? null : handleDragEnter"
                    @drop.prevent="preventAttachments ? null : handleDrop"
                    @dragover="preventAttachments ? null : handleDragOver" class="min-h-[75px] h-fit w-full text-primary my-1 outline-none shadow-none rounded-lg px-3.5 text-md resize-none 
                           bg-transparent
                           placeholder:text-neutral-500 dark:placeholder:text-neutral-400
                           transition-all duration-200" id="textarea" v-model="prompt" @keydown.enter="submit($event)"
                    :placeholder="modeInfo?.placeholder" />
                <div class="flex justify-between items-center px-2">
                    <div class="flex items-center space-x-1">
                        <Attachments v-model="attachments" :max-documents="modeInfo?.maxDocuments"
                            :min-documents="modeInfo?.minDocuments" />
                    </div>
                    <TooltipProvider :delayDuration="0">
                        <Tooltip>
                            <TooltipTrigger class="flex flex-row items-center justify-center space-x-1">
                                <div v-if="modeInfo.beta" class="w-full">
                                    <Badge variant="beta" class=" text-xs font-medium">Beta mode</Badge>
                                </div>
                                <Button v-if="!isRunning" variant="ghost" size="icon"
                                    class="rounded-full hover:bg-background/90 border-none hover:outline-none hover:border-none relative space-x-2 flex-shrink-0"
                                    @click.prevent="submit($event)" :disabled="disableSend">
                                    <ArrowUp :stroke-width="2.3" />
                                </Button>
                                <Button v-else size="icon" class="rounded-full flex-shrink-0 h-8 w-8 
                                           bg-neutral-900/80 dark:bg-neutral-100/80 
                                           hover:bg-neutral-900/90 dark:hover:bg-neutral-100/90 
                                           border-none hover:outline-none hover:border-none">
                                    <Loader class="h-3.5 w-3.5 animate-spin text-white dark:text-neutral-900"
                                        :stroke-width="4" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent v-if="disableSend">
                                <p v-if="attachments?.length < modeInfo?.minDocuments">
                                    Minimum {{ modeInfo?.minDocuments }} document(s) required
                                </p>
                                <p v-else>
                                    Please ensure you meet the required conditions for this use case
                                </p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
            </div>
        </Dropzone>
    </div>
</template>