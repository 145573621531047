<script setup>
import { Button } from '@/components/ui/button'
import { LayoutTemplate, WrapText, AlignJustify, Download, Plus, FileUp, Loader2, RotateCcw } from 'lucide-vue-next'
import { Cell, ColumnHeader, AttachmentCell, AttachmentHeader, NewColumnHeader, NewRowFooter, RowOverview } from '../components'
import { useSheet } from '../composables'
import { ref, h, computed, watch, provide, toValue, onMounted } from 'vue'
import { MainLayout, HeaderLayout, NavbarLayout } from '@/layouts'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip'
import {
    FlexRender,
    getCoreRowModel,
    useVueTable
} from '@tanstack/vue-table'
import {
    Table,
    TableHeader,
    TableBody,
    TableFooter,
    TableHead,
    TableRow,
    TableCell,
} from '@/components/ui/table'
import {
    Progress,
} from '@/components/ui/progress'
import { Loading } from '@/components/loading'
import { ResourceBreadcrumb } from '@/components/resources'
import { LibraryTableDialog } from '@/services/library'
import { TemplateDialog } from '@/services/templates'
import { useRoute } from 'vue-router'
import { LibraryService } from '@/services/library'
import { toast } from 'vue-sonner'
import { useKVStore } from '@/stores';

const kvStore = useKVStore()

const rowOverviewOpen = ref(false)
const route = useRoute()
const sheetId = computed(() => route.params?.sheetId)

const isTextWrapped = ref(false)
const {
    sheet,
    addColumn,
    removeColumn,
    updateColumn,
    isLoading,
    addRow,
    removeRow,
    exportSheet,
    applyTemplate,
    saveAsTemplate,
    generateTitle,
    runCells
} = useSheet(sheetId)

const isDocumentDialogOpen = ref(false)
const rowSelection = ref([])

const confirmSelection = async (selectedItems) => {
    try {
        toast.info('Adding documents to sheet...');

        const flattenedDocuments = selectedItems.length > 0 ? await LibraryService.listDescendants(selectedItems, { entities: ['document'] }) : []

        // If sheet is new, generate a title
        if ((toValue(sheet)?.name === 'New Sheet' || toValue(sheet)?.name === 'Untitled') && !toValue(sheet)?.rows.length) {
            generateTitle(flattenedDocuments)
        }

        // Add new documents
        const newDocuments = flattenedDocuments.filter(d => !toValue(sheet)?.rows.some(row => row.attachment_id === d.id));
        const promises = [addRow(...newDocuments)];

        // Remove documents not in selection
        const removedRows = toValue(sheet)?.rows.filter(d => !flattenedDocuments.some(item => item.id === d.attachment_id));
        promises.push(...removedRows.map(row => removeRow(row)));

        await Promise.all(promises);

        // Show appropriate success message
        const numRemoved = removedRows.length;
        const numAdded = newDocuments.length;
        if (numRemoved > 0 || numAdded > 0) {
            const addedMsg = numAdded > 0 ? `added ${numAdded} document${numAdded === 1 ? '' : 's'}` : '';
            const removedMsg = numRemoved > 0 ? `removed ${numRemoved} document${numRemoved === 1 ? '' : 's'}` : '';
            const msg = [addedMsg, removedMsg].filter(Boolean).join(' and ');
            toast.success(`Successfully ${msg}`);
        } else {
            toast.info('No changes made to sheet');
        }
    } catch (error) {
        console.error(error);
        toast.error('Failed to update sheet documents');
    }
}

const columnDefs = ref([])

watch(
    () => toValue(sheet)?.columns,
    (newVal, oldVal) => {
        // Skip if first run with no old value
        if (!oldVal) {
            columnDefs.value = createColumnDefs(newVal)
            return
        }

        // Check if columns changed (length or content)
        const columnsChanged = !newVal || !oldVal ||
            newVal.length !== oldVal.length ||
            newVal.some((column, index) => {
                const oldColumn = oldVal[index]
                return !oldColumn || !(column.id === oldColumn.id && column.label === oldColumn.label && column.type === oldColumn.type && column.options === oldColumn.options && column.instructions === oldColumn.instructions)
            })

        // Only update if relevant changes detected
        if (columnsChanged) {
            columnDefs.value = createColumnDefs(newVal)
        }
    },
    { immediate: true }
)

// Extract column creation logic to a separate function
function createColumnDefs(columns) {
    return [
        {
            id: 'attachment',
            header: () => h(AttachmentHeader),
            accessorKey: 'attachment',
            cell: info => h(AttachmentCell, {
                key: `attachment-${info.getValue().id}`,
                attachment: info.getValue().attachment,
                onDelete: () => removeRow(info.getValue())
            }),
            footer: () => h(NewRowFooter, {
                onClick: () => isDocumentDialogOpen.value = true
            }),
        },
        ...(columns?.map(column => ({
            id: column.id,
            accessorKey: column.id,
            header: () => h(ColumnHeader, {
                key: `header-${column.id}`,
                modelValue: column,
                onSubmit: (data) => updateColumn(column.order, data),
                onDelete: () => removeColumn(column)
            }),
            cell: info => h(Cell, {
                key: `cell-${info.getValue()?.id || 'empty'}-${column.id}`,
                cell: info.getValue(),
                type: column.type,
                isTextWrapped: isTextWrapped.value,
                onRetry: () => runCells(info.getValue()?.id)
            }),
        })) || []),
        {
            id: 'new',
            header: () => h(NewColumnHeader, {
                onSubmit: addColumn
            }),
        }
    ]
}

const memoizedData = computed(() => {
    const currentSheet = toValue(sheet)
    if (!currentSheet?.rows) return []

    return currentSheet.rows.map(row => {
        return computed(() => {
            const columnMap = new Map(currentSheet.columns?.map(col => [col.id, col]) || [])
            const cellMap = new Map(currentSheet.cells?.map(cell => [`${cell.row_id}-${cell.column_id}`, cell]))

            const rowData = {
                row,
                attachment: row,
            }

            columnMap.forEach((_, columnId) => {
                rowData[columnId] = cellMap.get(`${row.id}-${columnId}`)
            })

            return rowData
        }).value
    })
})

const table = useVueTable({
    get data() {
        return memoizedData.value
    },
    get columns() {
        return columnDefs.value
    },
    state: {
        get rowSelection() {
            return rowSelection.value
        }
    },
    enableMultiRowSelection: false,
    onRowSelectionChange: updater => {
        rowSelection.value = updater(rowSelection.value)
        if (Object.keys(rowSelection.value).length > 0) {
            rowOverviewOpen.value = true
        } else {
            rowOverviewOpen.value = false
        }
    },
    getCoreRowModel: getCoreRowModel(),
    enableVirtualization: true,
    getVirtualItemCount: rows => rows.length,
    getVirtualOffset: () => 0,
    getVirtualItemSize: () => 48,
})

watch(rowOverviewOpen, (newVal) => {
    if (!newVal) {
        rowSelection.value = {}
    }
})

const handleAttachmentDrop = async (event) => {
    isDraggingOver.value = false
    draggedItemType.value = null

    try {
        const droppedData = JSON.parse(event.dataTransfer?.getData('text/plain'));
        toast.info('Adding documents to sheet...');

        const documents = await LibraryService.listDescendants(droppedData, { entities: ['document'] })
        if ((toValue(sheet)?.name === 'New Sheet' || toValue(sheet)?.name === 'Untitled') && !toValue(sheet)?.rows.length) {
            generateTitle(documents)
        }
        const newDocuments = documents.filter(doc => !toValue(sheet)?.attachments?.some(attachment => attachment.id === doc.id))
        await addRow(...newDocuments);

        const numAdded = newDocuments.length;
        if (numAdded > 0) {
            toast.success(`Successfully added ${numAdded} document${numAdded === 1 ? '' : 's'}`);
        } else {
            toast.info('No new documents to add');
        }
    } catch (error) {
        console.error(error);
        toast.error('Failed to add documents to sheet');
    }
}

// Add these new refs near the top with other refs
const isDraggingOver = ref(false)
const draggedItemType = ref(null)

// Modified content drag enter handler
const handleAttachmentDragEnter = (event) => {
    const hasTextPlainType = Array.from(event.dataTransfer?.types || []).includes('text/plain')

    if (hasTextPlainType) {
        isDraggingOver.value = true
        draggedItemType.value = 'unknown'
    }
}

const handleAttachmentDragLeave = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
        isDraggingOver.value = false
        draggedItemType.value = null
    }
}

const container = ref(null)
provide('container', container)


async function loadItems() {
    const items = kvStore.get(`sheet-${toValue(sheetId)}-items`)
    if (items) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        await confirmSelection(toValue(items))
        kvStore.remove(`sheet-${toValue(sheetId)}-items`)
    }
}

// Add a new ref to track the selected row data
const selectedRowData = computed(() => {
    const selectedIds = Object.keys(rowSelection.value || {})
    if (!selectedIds.length) return null

    // Get the first selected row's data
    const selectedRowIndex = parseInt(selectedIds[0])
    return table.getRowModel().rows[selectedRowIndex]?.original.row || null
})

onMounted(loadItems)
watch(sheetId, loadItems)

// Move cell style computation to a memoized function
const getCellStyle = computed(() => (columnId, isWrapped) => ({
    overflow: 'hidden',
    textOverflow: isWrapped ? 'clip' : 'ellipsis',
    whiteSpace: isWrapped ? 'normal' : 'nowrap',
    maxWidth: columnId === 'new' ? 'auto' : '500px',
    height: 'auto',
    transition: 'max-height 0.2s ease-out, padding 0.2s ease-out, max-width 0.2s ease-out, background-color 0.2s ease-out',
    maxHeight: isWrapped ? '500px' : '24px',
}))
</script>

<template>
    <MainLayout ref="container">
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <ResourceBreadcrumb :folder-id="sheet?.parent_id" :project-id="sheet?.project_id"
                        v-model:resource="sheet" />
                </template>
            </NavbarLayout>
        </template>
        <template #header>
            <HeaderLayout>
                <template #left-actions>
                    <Button size="xs" class="flex flex-row space-x-2 rounded-full items-center"
                        @click="isDocumentDialogOpen = true">
                        <Plus class="w-4 h-4 flex-shrink-0" />
                        <span>Add Documents</span>
                    </Button>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <TemplateDialog :types="['sheets']" @apply="applyTemplate"
                                    @save-as-template="saveAsTemplate">
                                    <Button size="xs" variant="outline"
                                        class="flex flex-row space-x-2 rounded-full items-center">
                                        <LayoutTemplate class="w-4 h-4 flex-shrink-0" />
                                        <span>Templates</span>
                                    </Button>
                                </TemplateDialog>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Use a template to add columns to your sheet</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <div class="flex flex-row space-x-2 items-center text-muted-foreground text-sm pl-2"
                        v-if="!isLoading && sheet?.isRunning">
                        <Loader2 class="w-4 h-4 flex-shrink-0 animate-spin" />
                        <span>Running</span>
                    </div>
                    <Button v-else-if="sheet?.failedCells.length" size="xs" variant="ghost"
                        class="flex flex-row space-x-2 rounded-lg items-center"
                        @click="runCells(...sheet?.failedCells.map(cell => cell.id))">
                        <RotateCcw class="w-4 h-4 flex-shrink-0" />
                        <span>Retry {{ sheet?.failedCells.length }} cells</span>
                    </Button>
                </template>
                <template #right-actions>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button size="xs" variant="ghost"
                                    class="flex flex-row space-x-2 rounded-lg items-center"
                                    @click="isTextWrapped = !isTextWrapped">
                                    <WrapText v-if="!isTextWrapped" class="w-4 h-4 flex-shrink-0" />
                                    <AlignJustify v-else class="w-4 h-4 flex-shrink-0" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Toggle text wrapping in cells</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button size="xs" variant="ghost"
                                    class="flex flex-row space-x-2 rounded-lg items-center" @click="exportSheet">
                                    <Download class="w-4 h-4 flex-shrink-0" />
                                    <span>Export</span>
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Export sheet to Excel</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </template>
            </HeaderLayout>
            <Progress v-if="sheet?.isRunning" v-model="sheet.progress"
                class="w-[106%] rounded-none h-0.5 transition-opacity duration-300 -mx-3 mt-2.5 -mb-3" :class="{
                    'opacity-100': sheet.progress !== 100,
                    'opacity-0': sheet.progress === 100
                }" />
        </template>
        <template #content>
            <div class="h-full relative rounded-b-xl" v-if="!isLoading" @dragover.prevent
                @dragenter.prevent="handleAttachmentDragEnter($event)"
                @dragleave.prevent="handleAttachmentDragLeave($event)"
                @drop.prevent.stop="handleAttachmentDrop($event)">
                <Transition enter-active-class="transition-opacity duration-300"
                    leave-active-class="transition-opacity duration-300" enter-from-class="opacity-0"
                    leave-to-class="opacity-0">
                    <div v-if="isDraggingOver" class="absolute inset-0 bg-background/10 rounded-b-xl backdrop-blur-sm z-50 
                        flex items-center justify-center">
                        <div
                            class="w-[99.9%] h-[99.8%] flex items-center justify-center text-xl font-medium text-primary border-2 rounded-b-xl border-dotted border-primary/10">
                            <FileUp class="w-6 h-6 mr-2" />
                            <span>Drop to add to sheet</span>
                        </div>
                    </div>
                </Transition>
                <Table class="w-full" borderClass="h-full overflow-auto pb-1 border-t">
                    <TableHeader class="sticky top-0 z-40 bg-background">
                        <TableRow v-for="(headerGroup, index) in table.getHeaderGroups()" :key="headerGroup.id"
                            class="relative">
                            <TableHead
                                class="text-primary p-0 m-0 h-fit relative table-cell-transition border-b border-border"
                                :class="{
                                    'border-r': headerGroup.headers.length - 1 !== index,
                                    'sticky z-30 border-b pl-2 border-l': header.column.id === 'attachment'
                                }" v-for="header in headerGroup.headers" :key="header.id" :style="{
                                    width: header.column.id === 'attachment' ? '80px' :
                                        header.column.id === 'new' ? 'auto' : '120px',
                                    maxWidth: header.column.id === 'attachment' ? '100px' :
                                        header.column.id === 'new' ? 'none' : '300px',
                                    minWidth: header.column.id === 'new' ? '150px' : 'auto',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }">
                                <FlexRender :render="header.column.columnDef.header" :props="header.getContext()" />
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <template v-for="row in table.getRowModel().rows" :key="row.id">
                            <TableRow class="relative transition-colors"
                                :data-state="row.getIsSelected() ? 'selected' : undefined">
                                <template v-for="(cell, index) in row.getVisibleCells()" :key="cell.id">
                                    <TableCell
                                        class="border-b p-2 m-0 h-12 border-border text-xs font-light table-cell-transition"
                                        :class="{
                                            'border-r': table.getHeaderGroups()[0].headers.length - 1 !== index,
                                            'sticky z-20 bg-background text-primary': cell.column.id === 'attachment',
                                            'cursor-pointer hover:bg-accent border-l': index === 0
                                        }" @click="index === 0 ? row.toggleSelected() : null">
                                        <div class="items-center" :style="getCellStyle(cell.column.id, isTextWrapped)">
                                            <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()"
                                                :memo="['cell', cell.getValue()?.id, cell.column.id, isTextWrapped]" />
                                        </div>
                                    </TableCell>
                                </template>
                            </TableRow>
                        </template>
                    </TableBody>
                    <TableFooter class="bg-background">
                        <TableRow>
                            <TableCell class="h-auto" v-for="(footer, index) in table.getFooterGroups()[0].headers"
                                :key="footer.id" :class="{
                                    'sticky left-0 z-20 bg-background text-primary shadow-[2px_0_4px_-2px_rgba(0,0,0,0.1)] rounded-bl-xl': index === 0,
                                    'border-r-none rounded-br-xl': index === table.getFooterGroups()[0].headers.length - 1
                                }">
                                <FlexRender class="h-[1rem]" v-if="footer.column.columnDef.footer"
                                    :render="footer.column.columnDef.footer" :props="footer.getContext()" />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
            <Loading v-else />
            <RowOverview v-if="container !== null" :row="selectedRowData" v-model:open="rowOverviewOpen" />
            <LibraryTableDialog :default-project-id="sheet?.project_id" :default-folder-id="sheet?.folder_id"
                v-model:open="isDocumentDialogOpen" @submit="({ selectedItems }) => confirmSelection(selectedItems)"
                :default-selected="sheet?.attachments" :filters="{ type: 'documents' }" selectable multiple />
        </template>
    </MainLayout>
</template>

<style scoped>
.table-cell-transition {
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        background-color 0.2s ease,
        width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
}

.backdrop-blur-sm {
    backdrop-filter: blur(8px);
}
</style>
