import { useRepo, Folder, Document } from '@/models';
import { ref, toValue } from 'vue';
import { toast } from 'vue-sonner';


export function useUpload(projectId, folderId) {
    const folderRepo = useRepo(Folder);
    const documentRepo = useRepo(Document);

    const files = ref([]);
    const isUploading = ref(false);

    async function readEntry(entry, path = '') {
        // Ignore dot files and directories
        if (entry.name.startsWith('.')) {
            return;
        }

        if (entry.isFile) {
            return new Promise((resolve) => {
                entry.file(async (file) => {
                    const fileWithPath = new File([file], file.name, {
                        type: file.type,
                        lastModified: file.lastModified
                    });
                    fileWithPath._path = path + file.name;
                    files.value.push(fileWithPath);
                    resolve();
                });
            });
        } else if (entry.isDirectory) {
            const dirReader = entry.createReader();
            const entries = [];
            
            let batch;
            do {
                batch = await new Promise((resolve, reject) => {
                    dirReader.readEntries(
                        (results) => resolve(results),
                        (error) => reject(error)
                    );
                });
                entries.push(...batch);
            } while (batch.length > 0);

            const folderPath = path + entry.name + '/';
            await Promise.all(
                entries.map((entry) => readEntry(entry, folderPath))
            );
        }
    }

    async function processItems(items) {
        const promises = [];
        for (const item of items) {
            if (item instanceof File) {
                // Ignore dot files
                if (!item.name.startsWith('.')) {
                    files.value.push(item);
                }
            } else {
                const entry = item.webkitGetAsEntry?.() || 
                             item.getAsEntry?.() || 
                             item;
                
                if (entry && !entry.name?.startsWith('.')) {
                    promises.push(readEntry(entry));
                }
            }
        }
        await Promise.all(promises);
    }

    function readFileAsBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result.split(',')[1]);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    }

    async function pollDocumentStatus(documentId) {
        let document;
        try {
            do {
                const response = await documentRepo.api().get(`/documents/${documentId}`);
                document = response.entities[0];
                await new Promise(resolve => setTimeout(resolve, 5000));
            } while (document.upload_status == 'pending');
        } catch (error) {
            documentRepo.destroy(documentId);
            toast.error(`Failed to process ${document.name}`);
        }
    }

    const upload = async (items) => {
        try {
            files.value = [];
            await processItems(items);

            if (files.value.length === 0) return;

            isUploading.value = true;
            toast.info(`Uploading ${files.value.length} files`);

            // Group files by their directory path
            const filesByLevel = files.value.reduce((acc, file) => {
                const fullPath = file._path || file.webkitRelativePath || file.name;
                const pathParts = fullPath.split('/');
                
                if (pathParts.length > 1) {
                    // Remove the filename from the path
                    pathParts.pop();
                    const dirPath = pathParts.join('/');
                    acc[dirPath] = acc[dirPath] || [];
                    acc[dirPath].push(file);
                } else {
                    // Root level files
                    acc[''] = acc[''] || [];
                    acc[''].push(file);
                }
                return acc;
            }, {});

            // Keep existing folder creation logic
            const folderCache = new Map();
            folderCache.set('', toValue(folderId));

            // Create folders sequentially to ensure proper nesting
            for (const path of Object.keys(filesByLevel)) {
                if (!path) continue;

                const pathParts = path.split('/');
                let currentPath = '';

                for (const part of pathParts) {
                    currentPath = currentPath ? `${currentPath}/${part}` : part;
                    if (!folderCache.has(currentPath)) {
                        const parentPath = currentPath.split('/').slice(0, -1).join('/');
                        const parentId = folderCache.get(parentPath || '');
                        
                        const response = await folderRepo.api().post('/folders/', {
                            name: part,
                            project_id: toValue(projectId),
                            parent_id: parentId
                        });
                        folderCache.set(currentPath, response.entities[0].id);
                    }
                }
            }

            // Upload files with correct parent folders
            const uploadPromises = [];

            for (const [path, filesInPath] of Object.entries(filesByLevel)) {
                const folderId = folderCache.get(path);
                
                for (const file of filesInPath) {
                    uploadPromises.push((async () => {
                        const response = await documentRepo.api().post('/documents/', {
                            name: file.name,
                            project_id: toValue(projectId),
                            parent_id: folderId,
                            content: await readFileAsBase64(file),
                            content_type: file.name.split('.').pop().toLowerCase()
                        });
                        return pollDocumentStatus(response.entities[0].id);
                    })());
                }
            }

            // Process all uploads in parallel
            await Promise.all(uploadPromises);
            toast.success('Files uploaded successfully');
        } catch (error) {
            console.error('Upload error:', error);
            toast.error('Failed to upload files');
        } finally {
            isUploading.value = false;
            files.value = [];
        }
    };

    const handleUpload = async (event) => {
        await upload(event.dataTransfer ? event.dataTransfer.items : event.target.files);
    };

    return { upload, handleUpload, isUploading };
}