import { Model } from 'pinia-orm'
import { DateCast } from '@/models/utils'
import { User } from '@/models/User'
import { Resource } from '@/services/library/models/ResourceHierarchy'
import { Document } from '@/services/documents/models/Document'

export class Thread extends Resource {
    static entity = 'thread'
    static baseEntity = 'resource'

    static fields() {
        return {
            ...super.fields(),
            type: this.string('THREAD'),
            settings: this.attr({}),
            icon: this.string('Component'),
            color: this.string('blue-900'),

            // Relationships
            runs: this.hasMany(Run, 'thread_id'),
            owner: this.belongsTo(User, 'user_id')
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }

    get isRunning() {
        return this.runs?.some(run => run.status === 'running')
    }

    get isLocked() {
        return this.isRunning
    }
}

export class RunAttachment extends Model {
    static entity = 'run_attachment'
    static primaryKey = ['run_id', 'attachment_id']
    static fields() {
        return {
            run_id: this.attr(null),
            attachment_id: this.attr(null)
        }
    }
}

export class Run extends Model {
    static entity = 'run'
    static fields() {
        return {
            id: this.attr(null),
            thread_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            status: this.string('running'),
            mode: this.string(null),
            prompt: this.string(null),
            answer: this.attr(null),
            suggested_follow_ups: this.attr(null),
            

            // Relationships
            attachments: this.belongsToMany(Document, RunAttachment, 'run_id', 'attachment_id'),
            thread: this.belongsTo(Thread, "thread_id"),
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}


export default Thread;