
import { useRepo, Thread, Run } from '@/models'
import { onMounted, watch, onUnmounted, toValue } from 'vue'
import { useSocket } from '@/composables'
import { ThreadService } from '../service'
import { debounce } from 'lodash'

export function useThreadSocket(thread) {
    const socket = useSocket('threads')
    let pendingRuns = new Map();

    const processPendingRuns = debounce(() => {
        const runsToSave = Array.from(pendingRuns.values());
        if (runsToSave.length) {
            useRepo(Run).save(runsToSave);
            pendingRuns.clear();
        }
    }, 10);

    onMounted(async () => {
        const promises = [
            socket.on('thread', (data) => useRepo(Thread).save(data)),
            socket.on('run', (data) => {
                pendingRuns.set(data.id, data);
                processPendingRuns();
            })
        ]
        if (toValue(thread)?.id) promises.push(ThreadService.enterThread({ socket, thread }))
        await Promise.all(promises)
    })

    watch(thread, async (newThread, oldThread) => {
        const newThreadId = toValue(newThread)?.id
        const oldThreadId = toValue(oldThread)?.id
        if (newThreadId !== oldThreadId) {
            if (newThreadId) await ThreadService.enterThread({ socket, thread: newThread })
            if (oldThreadId) await ThreadService.leaveThread({ socket, thread: oldThread })
        }
    }, { immediate: true, deep: true })

    onUnmounted(async () => {
        if (toValue(thread)?.id) await ThreadService.leaveThread({ socket, thread })
    })

    return {
        startRun: async ({ prompt, mode, attachments = [] }) => await ThreadService.startRun({ socket, thread, prompt, mode, attachments }),
        cancelRun: async (runId) => await ThreadService.cancelRun({ socket, thread, runId }),
    }
}