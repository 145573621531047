<script setup>
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage } from '@/components/ui/breadcrumb';
import { MainLayout, NavbarLayout, HeaderLayout } from '@/layouts';
import { Zap, Rss, Plus, LayoutDashboard } from 'lucide-vue-next';
import { reactive } from 'vue'
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { EmbeddedVideo } from '@/components/embedded-video'
import { ActionCard } from '@/components/controls'
import { useRepo } from '@/models'
import { useRoute, useRouter } from 'vue-router'
import { useQuery } from '@tanstack/vue-query'
import { useAuth } from '@/auth'
import { LibraryTable } from '@/services/library'
import { CreateProjectDialog } from '@/services/projects';
import { Template } from '@/services/templates/models'
import { ThreadService } from '@/services/threads'
import { SheetService } from '@/services/sheets'

const auth = useAuth()
const route = useRoute()
const router = useRouter()



const { isLoading } = useQuery({
    queryKey: ['shortcuts'],
    queryFn: async () => await useRepo(Template).api().get('/templates/')
})

const filters = reactive({
    type: 'all',
    members: [],
    dateRange: {
        start: null,
        end: null
    }
})
</script>

<template>
    <MainLayout>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <Breadcrumb>
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <BreadcrumbPage class="flex flex-row space-x-2 items-center ">
                                    <LayoutDashboard class="w-5 h-5" />
                                    <span>{{ auth.user?.first_name }}'s Workspace</span>
                                </BreadcrumbPage>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </template>
            </NavbarLayout>
        </template>
        <template #header>
            <HeaderLayout>
                <template #left-actions>
                    <div class="flex flex-row items-center space-x-2">
                        <Zap class="text-primary size-5" />
                        <span class="text-left text-lg text-primary font-semibold">Acties</span>
                    </div>
                </template>
                <template #right-actions>
                    <CreateProjectDialog>
                        <Button variant="outline" size="sm" class="flex items-center gap-2">
                            <Plus class="w-4 h-4" />
                            <span>Create project</span>
                        </Button>
                    </CreateProjectDialog>
                </template>
            </HeaderLayout>
        </template>
        <template #content>
            <div class="flex flex-col w-full space-y-4 " v-if="!isLoading">
                <div class="flex-col px-4 space-y-4 h-full">
                    <Grid class=" grid auto-rows-[minmax(225px,auto)] grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                        <EmbeddedVideo class="hidden" title="Getting started with the Assistant"
                            description="Assistant tutorial video." videoId="7lnfhTeSv5Q">
                            <template #left-badge>
                                <Badge variant="default" class="text-xs font-medium">Video</Badge>
                            </template>
                        </EmbeddedVideo>
                        <ActionCard
                            @click="ThreadService.createThread({ name: 'Document Review' }, { router, query: { mode: 'review' } })"
                            title="Document Review" description="Chat with your documents.">
                            <template #left-badge>
                                <Badge variant="assistant" class="text-xs font-medium">Assistant</Badge>
                            </template>
                        </ActionCard>
                        <ActionCard
                            @click="ThreadService.createThread({ name: 'Drafting' }, { router, query: { mode: 'draft' } })"
                            title="Drafting" description="Draft text and documents.">
                            <template #left-badge>
                                <Badge variant="assistant" class="text-xs font-medium">Assistant</Badge>
                            </template>
                            <template #right-badge>
                                <Badge variant="beta" class="text-xs font-medium">Beta</Badge>
                            </template>
                        </ActionCard>
                        <ActionCard
                            @click="ThreadService.createThread({ name: 'Legal Research' }, { router, query: { mode: 'research' } })"
                            title="Legal Research" description="Search through legal sources.">
                            <template #left-badge>
                                <Badge variant="assistant" class="text-xs font-medium">Assistant</Badge>
                            </template>
                            <template #right-badge>
                                <Badge variant="beta" class="text-xs font-medium">Beta</Badge>
                            </template>
                        </ActionCard>
                        <ActionCard @click="SheetService.createSheet({ name: 'Sheet Review' }, { router })"
                            title="Sheet Review" description="Tabular review of documents.">
                            <template #left-badge>
                                <Badge variant="sheet" class="text-xs font-medium">Sheets</Badge>
                            </template>

                        </ActionCard>
                    </Grid>
                </div>
                <div class="flex flex-row justify-start items-center px-4">
                    <Rss class="text-primary mr-2 w-5 h-5" />
                    <span class="text-left text-lg text-primary font-semibold">Activity</span>
                </div>
               
                    <LibraryTable :project-id="route.params?.projectId" :folder-id="route.query?.folderId"
                        :filters="filters" selectable multiple />
 
            </div>
        </template>
    </MainLayout>
</template>