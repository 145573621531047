<script setup>
import { ref, inject } from 'vue';
import { Button } from '@/components/ui/button';
import { toast } from 'vue-sonner';
import { Toaster } from '@/components/ui/sonner';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { Textarea } from '@/components/ui/textarea'

const feedback = ref('');
const posthog = inject("posthog")

async function submit(sendFeedback) {
  let val;
  if (sendFeedback) {
    val = feedback.value.trim();
    if (!val) {
      toast.error('Vul alsjeblieft je feedback in voordat je het instuurt.');
      return;
    }
  }
  else {
    val = 'Ja ik wil graag een speciaal aanbod.';
  }
  try {
    posthog.capture(
      'survey sent',
      {
        $survey_id: '01958a8b-99af-0000-16ef-a0534dfee227',
        $survey_response: val,
      }
    )
    feedback.value = '';

    if (sendFeedback) {
      toast.success('Bedankt voor je feedback!');
    }
    else {
      toast.success('Bedankt, we komen zo snel mogelijk terug!');
    }
  } catch (error) {
    toast.error('Er is iets misgegaan bij het versturen van de feedback, probeer het opnieuw.');
  }
}
</script>

<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-background max-w-3xl mx-auto">
    <div class="w-[5rem] h-[5rem] mb-4 bg-no-repeat bg-contain bg-center mx-auto"
      style="background-image: var(--symbol);"></div>
    <h1 class="text-2xl font-bold mb-2 px-4 pb-4">We groeien en willen jou meenemen.</h1>

    <div class="flex flex-col items-center justify-center italic">
      <p class="text-muted-foreground px-4">
        Onze app groeit sneller dan ooit. Grote kantoren sluiten zich aan en benutten onze innovatieve functionaliteiten om efficiënter en slimmer te werken. Dit succes brengt ons op een belangrijk punt: om dezelfde kwaliteit en service te blijven bieden, stoppen we met gratis gebruik.
      </p>
      <br>
      <p class="text-muted-foreground px-4">
        Ons team werkt keihard om deze groei in goede banen te leiden en tegelijkertijd de waarde van de app continu te vergroten.
        Om die reden richten we ons volledig op abonnementen waarmee we jou als professional optimaal kunnen ondersteunen.
      </p>
      <br>
      <p class="text-muted-foreground px-4">
        We geloven dat onze app jouw werk gemakkelijker en effectiever maakt.
        Daarom willen we je een exclusief aanbod doen om onderdeel te blijven van onze community. 
      </p>
      <br>
      <div class="flex gap-4 mt-4">
        <Button @click="submit(false)" variant="default" size="lg">
        <p class="font-bold">Ja, stuur mij een speciaal aanbod.</p>
      </Button>
      
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button variant="outline" size="lg">
            <p class="font-bold">Nee, bedankt!</p>
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <div class="absolute top-4 right-4">
            <AlertDialogCancel class="p-1 hover:bg-background hover:text-secondary border-none rounded-full">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                  fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
              </svg>
            </AlertDialogCancel>
          </div>
          <AlertDialogHeader>
            <AlertDialogTitle>Geef ons jouw feedback</AlertDialogTitle>
            <AlertDialogDescription class="flex flex-col gap-4">
              <p class="text-sm">
                Jouw feedback is van onschatbare waarde voor ons. 
                Het stelt ons in staat om inzicht te krijgen in hoe we de app beter kunnen laten aansluiten op jouw wensen en werkwijzen.
              </p>
              <Textarea v-model="feedback" placeholder="Typ hier jouw feedback" class="border rounded-lg" />
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Annuleren</AlertDialogCancel>
            <AlertDialogAction asChild>
              <Button @click="submit(true)" variant="default">
                Inzenden
              </Button>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      </div>
    </div>
  </div>
  <Toaster />
</template>
