<script setup>
import { HoverCard, HoverCardTrigger, HoverCardContent } from '@/components/ui/hover-card';
import { Badge } from '@/components/ui/badge';
import { defineProps, computed } from 'vue';
import { useRepo, Case, Document, Consolidation } from '@/models'
import { useSidepanel } from '@/components/sidepanel'
import { CaseIcon, RegulationIcon } from '@/components/icons'
import { FileIcon, ArrowUpRight } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'

const props = defineProps({
    dataCitationId: {
        type: String,
        required: true
    },
    dataCitationType: {
        type: String,
        required: true
    },
    dataCitationTitle: {
        type: String,
        required: true
    },
    dataCitationExcerpt: {
        type: String,
        required: true
    },
    dataCitationOrder: {
        type: Number,
        required: true
    },
})

const { addTab } = useSidepanel()

const icon = computed(() => {
    switch (props.type) {
        case 'case': return CaseIcon;
        case 'regulation': return RegulationIcon;
        case 'document': return FileIcon;
        default: return null;
    }
})

const getResource = computed(() => {
    switch (props.dataCitationType) {
        case 'case': return id => useRepo(Case).api().get(`/cases/${id}`, {
            dataTransformer: (response) => {
                response.data.id = response.data.uid
                return response.data
            }
        });
        case 'regulation': return id => useRepo(Consolidation).api().get(`/regulations/consolidations/${id}`, {
            dataTransformer: (response) => {
                response.data.id = response.data.uid
                return response.data
            }
        });
        case 'document': return id => useRepo(Document).api().get(`/documents/${id}`);
        default: return null;
    }
})

async function open() {
    const response = await getResource.value(props.id)
    addTab(response.entities[0])
}

</script>

<template>
    <sot />
    <HoverCard :openDelay="0" :closeDelay="10">
        <HoverCardTrigger asChild>
            <Badge @click="open" variant="outline" class="cursor-pointer bg-backgroundSecondary hover:bg-accent mr-1">
                <component :is="icon" class="h-3 w-3 mr-1" />
                <p>{{ dataCitationOrder + 1 }}</p>
            </Badge>
        </HoverCardTrigger>
        <HoverCardContent class="w-80 rounded-lg pb-2">
            <div class="flex flex-col justify-between space-y-1">
                <h4 class="text-sm font-semibold">
                    {{ dataCitationTitle }}
                </h4>
                <p class="prose text-sm leading-tight break-all line-clamp-5 w-full">{{ dataCitationExcerpt }}</p>
                <div class="flex flex-row justify-between items-center space-x-2 w-full">
                    <Badge @click="open" variant="outline"
                        class="cursor-pointer bg-backgroundSecondary hover:bg-accent mr-1 w-fit h-fit">
                        <component :is="icon" class="h-3 w-3 mr-1" />
                        <p>{{ dataCitationOrder + 1 }}</p>
                    </Badge>
                    <Button @click="open" variant="link" size="xs"
                        class="flex flex-row gap-1 items-center text-muted-foreground pr-0">
                        <span>Show more</span>
                        <ArrowUpRight class="h-4 w-4" />
                    </Button>
                </div>
            </div>
        </HoverCardContent>
    </HoverCard>
</template>