import { Model } from 'pinia-orm'
import { DateCast } from '@/models/utils'
import { User, Organization } from '@/models'
import { SheetTemplate, PromptTemplate } from './TemplateHierarchy'

export class Template extends Model {
    static entity = 'template'

    static types() {
        return {
            TEMPLATE: Template,
            SHEETTEMPLATE: SheetTemplate,
            PROMPTTEMPLATE: PromptTemplate,
        }
    }

    static fields() {
        return {
            id: this.attr(null),
            type: this.string('TEMPLATE'),
            name: this.string(''),
            description: this.string(''),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            stub: this.string(''),
            default: this.boolean(false),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            
            // Relationships
            owner: this.belongsTo(User, 'user_id'),
            organization: this.belongsTo(Organization, 'organization_id'),
        }
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}

export default Template;