<script setup>
import { ChevronUpIcon, ChevronDownIcon, LayoutTemplate, ArrowRight, ComponentIcon } from 'lucide-vue-next'
import { TabsContent, TabsList, TabsRoot, TabsTrigger } from 'radix-vue'
import { TemplateDialog } from '@/services/templates/components'
import { defineModel, onMounted, watch } from 'vue'
import { Button } from '@/components/ui/button'
import { PromptTemplate } from '@/services/threads/models'
import { useRepo } from '@/models'

const shortcuts = [
  { mode: 'review', name: 'Samenvatten', content: 'Vat de inhoud van de bijgevoegde documenten overzichtelijk samen zonder verlies van belangrijke informatie' },
  { mode: 'review', name: 'Documenten vergelijken', content: 'Vergelijk de inhoud van de bijgevoegde documenten en geef overzichtelijk de verschillen en overeenkomsten weer' },
  { mode: 'review', name: 'Chronologie van gebeurtenissen', content: 'Maak een chronologie van de gebeurtenissen uit de bijgevoegde documenten' },
  { mode: 'draft', name: 'Memo opstellen', content: 'Schrijf een memo op basis van de volgende instructies:' },
  { mode: 'draft', name: 'Herformuleren', content: 'Herformuleer de tekst' },
  { mode: 'draft', name: 'Tekst vereenvoudigen', content: 'Herschrijf de tekst naar een meer leesbare en begrijpelijkere vorm' },
  { mode: 'research', name: 'Research rechtspraak (BETA)', content: 'Vind relevante jurisprudentie voor de volgende situatie:' },
  { mode: 'research', name: 'Research wetgeving (BETA)', content: 'Vind relevante wetgeving voor de volgende situatie:' },
  { mode: 'research', name: 'Analyseer ECLI rechtspraak.nl (BETA)', content: 'Analyzeer <vul hier het ECLI nummer volledig in>. Vat uitgebreid samen en zet overzichtelijk uiteen wat de belangrijkste feiten, overwegingen en de conclusies zijn' }
]

const mode = defineModel('mode', { type: String, default: 'review' })
const shortcut = defineModel('shortcut', { type: Object, default: () => { } })
const open = defineModel('open', { type: Boolean, default: false })

function handleShortcutClick(clickedShortcut) {
  shortcut.value = clickedShortcut
  open.value = false
}

async function applyTemplate(template) {
  const response = await useRepo(PromptTemplate).api().get(`/threads/templates/${template.id}`)
  shortcut.value = {
    mode: response.entities[0].mode,
    name: response.entities[0].name,
    content: response.entities[0].content
  }
  open.value = false
}

function getModeName(mode) {
  switch (mode) {
    case 'review':
      return 'Document Review'
    case 'draft':
      return 'Drafting'
    case 'research':
      return 'Legal Research'
    default:
      return null
  }
}

function getModeIcon(mode) {
  switch (mode) {
    case 'review':
      return ComponentIcon
    case 'draft':
      return ComponentIcon
    case 'research':
      return ComponentIcon
    default:
      return ComponentIcon
  }
}

watch(shortcut, (newVal) => {
  if (newVal) {
    mode.value = newVal.mode
  }
})

onMounted(() => {
  if (shortcut.value?.mode) {
    mode.value = shortcut.value.mode
  }
})
</script>

<template>
  <TabsRoot v-model="mode" class="w-full">
    <div v-if="open">
      <TabsContent v-for="option in shortcuts.filter(s => s.mode === mode)" :key="option.mode" :value="option.mode">
        <div @click="handleShortcutClick(option)"
          class="cursor-pointer bg-transparent hover:bg-accent border border-border group flex flex-row w-full gap-2 bg-sidebar rounded-full p-4 mt-2 ">
          <div class="flex justify-between items-center w-full">
            <div class="flex flex-row items-center gap-2">
              <ArrowRight
                class="w-4 h-4 text-muted-foreground group-hover:text-primary group-hover:rotate-45 transition-all duration-300" />
              <p class="text-sm font-normal">{{ option.name }}</p>
            </div>
            <component :is="getModeIcon(option.mode)"
              class="w-4 h-4 text-muted-foreground group-hover:text-primary  " />
          </div>
        </div>
      </TabsContent>
    </div>
    <div class="flex items-center gap-2 ">
      <Button @click="open = !open"
        class="h-9 justify-end rounded-full px-3 hover:bg-accent inline-flex items-center space-x-1 text-muted-foreground"
        :class="{ 'bg-accent text-primary border border-border': open }" variant="ghost" size="sm">
        <span class="text-xs font-normal">Shortcuts</span>
        <ChevronUpIcon v-if="open" class="w-4 h-4" />
        <ChevronDownIcon v-else class="w-4 h-4" />
      </Button>
      <TabsList aria-label="tabs modes" class="flex flex-row flex-wrap gap-2 py-2">
        <TabsTrigger v-for="option in ['review', 'draft', 'research']" :key="option" :value="option" class="
              data-[state=active]:bg-blue-100/60 dark:data-[state=active]:bg-blue-900/30 data-[state=active]:text-blue-900 dark:data-[state=active]:text-blue-500 data-[state=active]:border data-[state=active]:border-blue-100 dark:data-[state=active]:border-blue-800
              hover:bg-accent hover:text-primary h-9 rounded-full px-3 space-x-2 inline-flex items-center justify-center text-primary whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50
              transition-all duration-300">
          <component v-if="mode == option" :is="getModeIcon(mode)" class="w-4 h-4" />
          <p class="text-sm font-normal">{{ getModeName(option) }}</p>
        </TabsTrigger>
      </TabsList>
      <TemplateDialog :types="['threads']" @apply="applyTemplate">
        <Button variant="ghost" size="sm"
          class="h-9 justify-end rounded-full  px-3 hover:bg-accent inline-flex items-center space-x-1 text-muted-foreground">
          <LayoutTemplate class="w-4 h-4" />
          <span class="text-xs font-normal">Templates</span>
        </Button>
      </TemplateDialog>
    </div>
  </TabsRoot>
</template>
