import { useRepo } from '@/models';
import { Template } from './models';
import { useQuery } from '@tanstack/vue-query';
import { computed, toValue } from 'vue';


export function getType(template) {
    return {
        'PROMPTTEMPLATE': 'threads',
        'SHEETTEMPLATE': 'sheets'
    }[toValue(template).type]
}


export async function createTemplate({ name, type, description = null }) {
    const response = await useRepo(Template).api().post(`/${toValue(type)}/templates/`, { 
        name, 
        description,
    });
    return response.entities[0];
}

export async function updateTemplate(template) {
    const response = await useRepo(Template).api().patch(
        `/${toValue(getType(template))}/templates/${toValue(template).id}`, 
        toValue(template)
    );
    return response.entities[0];
}

export async function deleteTemplate(template) {
    await useRepo(Template).api().delete(
        `/${toValue(getType(template))}/templates/${toValue(template).id}`, 
        { delete: toValue(template).id }
    );
}

export function getTemplate(type, templateId) {
    const { isLoading, isFetching, isError, error } = useQuery({
        queryKey: ['template', type, templateId],
        queryFn: async () => toValue(templateId) ? await useRepo(Template).api().get(`/${toValue(type)}/templates/${toValue(templateId)}`) : null,
    });
    return { 
        template: computed(() => useRepo(Template).withAllRecursive().find(toValue(templateId)) || null), 
        isLoading, 
        isFetching, 
        isError, 
        error 
    };
}

export function getTemplates() {
    const { isLoading, isFetching, isError, error } = useQuery({
        queryKey: ['templates'],
        queryFn: async () => await useRepo(Template).api().get('/templates/'),
    });
    return { templates: computed(() => useRepo(Template).withAllRecursive().orderBy('default', 'desc').orderBy('stub', 'asc').orderBy('created_at', 'desc').get()), isLoading, isFetching, isError, error };
}

export const TemplateService = {
    getType,
    createTemplate,
    updateTemplate,
    deleteTemplate,
    getTemplate,
    getTemplates
};