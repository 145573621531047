import { VueNodeViewRenderer } from '@tiptap/vue-3';
import Mention from '@tiptap/extension-mention';
import CitedTextComponent from './CitedText.vue';

export const CitedText = Mention.extend({
    name: 'citation',
    addAttributes() {
        return {
            ...this.parent?.(),
            order: {
                default: 0,
            },
            type: {
                default: null,
            },
            id: {
                default: null,
            },
            excerpt: {
                default: null,
            },
            title: {
                default: null,
            },
        }
    },
    addNodeView() {
        return VueNodeViewRenderer(CitedTextComponent)
    }
})

export default CitedText;