import { useRepo } from '@/models'
import { Sheet, Column, Row, SheetTemplate } from './models'
import { useQuery } from '@tanstack/vue-query'
import { toValue, computed } from 'vue'
import { useKVStore } from '@/stores'
import * as XLSX from 'xlsx'


export function getSheet(sheetId) {
    const { isLoading } = useQuery({
        queryKey: ['sheets', sheetId],
        queryFn: async () => await useRepo(Sheet).api().get(`/sheets/${toValue(sheetId)}`)
    })
    return {
        isLoading,
        sheet: computed(() => useRepo(Sheet).with('columns').with('rows', (query) => query.with('attachment')).with('cells').find(toValue(sheetId)))
    }
}

export async function updateSheet(sheet) {
    await useRepo(Sheet).api().patch(
        `/sheets/${toValue(sheet).id}`,
        toValue(sheet)
    );
    return useRepo(Sheet).with('columns').with('rows', (query) => query.with('attachment')).with('cells').find(toValue(sheet).id);
}

export async function deleteSheet(sheet) {
    await useRepo(Sheet).api().delete(
        `/sheets/${toValue(sheet).id}`,
        { delete: toValue(sheet).id }
    );
}

export async function createSheet({ name = null, projectId = null, parentId = null, attachments = [], columns = [] } = {}, { router = null } = {}) {
    const response = await useRepo(Sheet).api().post('/sheets/', {
        name: toValue(name) || 'New Sheet',
        project_id: toValue(projectId) || null,
        parent_id: toValue(parentId) || null,
        attachments: [],
        columns: toValue(columns) || []
    })
    const items = toValue(attachments)?.filter(item => ['DOCUMENT', 'FOLDER'].includes(item.type)) || []
    if (items?.length) {
        const kvStore = useKVStore()
        kvStore.set(`sheet-${response.entities[0].id}-items`, items)
    }
    if (router) {
        router.push({ name: response.entities[0].project_id ? 'project-sheet' : 'sheet', params: { sheetId: response.entities[0].id, projectId: response.entities[0].project_id } })
    }
    return useRepo(Sheet).with('columns').with('rows', (query) => query.with('attachment').with('cells')).find(response.entities[0].id);
}

export async function enterSheet({ socket, sheet }) {
    await socket.emit('enter_sheet', toValue(sheet).id)
}

export async function leaveSheet({ socket, sheet }) {
    await socket.emit('leave_sheet', toValue(sheet).id)
}

export async function runCells({ socket, sheet, cellIds }) {
    await socket.emit('run_cells', toValue(sheet).id, toValue(cellIds))
}

export async function addColumn({ socket, sheet, columnSpecs }) {
    await socket.emit('add_columns', toValue(sheet).id, toValue(columnSpecs))
}

export async function updateColumn({ socket, sheet, columnNumber, columnSpec }) {
    const spec = toValue(columnSpec)
    await socket.emit('update_column', toValue(sheet).id, columnNumber, { label: spec.label, type: spec.type, instructions: spec.instructions, options: spec.options })
}

export async function removeColumn({ sheet, column }) {
    const columnId = toValue(column).id
    const columnOrder = toValue(column).order
    useRepo(Column).destroy([columnId])
    await useRepo(Column).api().delete(`/sheets/${toValue(sheet).id}/columns/${columnOrder}`)
}

export async function addRow({ socket, sheet, attachments }) {
    await socket.emit('add_rows', toValue(sheet).id, toValue(attachments).map(attachment => attachment.id))
}

export async function removeRow({ sheet, row }) {
    const rowId = toValue(row).id
    const rowOrder = toValue(row).order
    useRepo(Row).destroy([rowId])
    await useRepo(Row).api().delete(`/sheets/${toValue(sheet).id}/rows/${rowOrder}`)
}

export function exportToExcel({ sheet, name = null }) {
    const excelData = toValue(sheet).rows.map(row => ({
        Document: row.attachment.name,
        ...Object.fromEntries(
            toValue(sheet).columns.map(column => [
                column.label,
                row.cells.find(cell => cell.column_id === column.id)?.value
            ])
        )
    }))
    const ws = XLSX.utils.json_to_sheet(excelData)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, `${toValue(sheet).name || name || 'sheet'}.xlsx`)
}

export async function saveAsTemplate({ sheet, template = null }) {
    if (!toValue(template)) {
        const response = await useRepo(SheetTemplate).api().post(`/sheets/${toValue(sheet).id}/save-as-template/`)
        return response.entities[0]
    } else {
        await useRepo(SheetTemplate).api().patch(`/sheets/templates/${toValue(template).id}/update-from-sheet/`, {
            sheet_id: toValue(sheet).id
        })
        return toValue(template)
    }
}

export async function applyTemplate({ socket, sheet, template }) {
    await socket.emit('apply_template', toValue(sheet).id, toValue(template).id)
}

export async function createFromTemplate({ template, parentId = null, projectId = null }, { router = null, attachments = [] } = {}) {
    const response = await useRepo(Sheet).api().post(`/sheets/templates/${toValue(template).id}/create-sheet/`, {
        parent_id: toValue(parentId),
        project_id: toValue(projectId)
    })
    const items = toValue(attachments)?.filter(item => ['DOCUMENT', 'FOLDER'].includes(item.type)) || []
    if (items?.length) {
        const kvStore = useKVStore()
        kvStore.set(`sheet-${response.entities[0].id}-items`, items)
    }
    if (router) {
        router.push({ name: response.entities[0].project_id ? 'project-sheet' : 'sheet', params: { sheetId: response.entities[0].id, projectId: response.entities[0].project_id } })
    }
    return response.entities[0]
}

export const SheetService = {
    enterSheet,
    leaveSheet,
    addColumn,
    updateColumn,
    removeColumn,
    addRow,
    removeRow,
    exportToExcel,
    saveAsTemplate,
    getSheet,
    updateSheet,
    deleteSheet,
    createSheet,
    applyTemplate,
    createFromTemplate,
    runCells
}