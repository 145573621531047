<script setup>
import { defineProps, toRefs } from 'vue'
import AIMessage from './AIMessage.vue'
import HumanMessage from './HumanMessage.vue'

const props = defineProps(['run', 'isLast'])
const { run, isLast } = toRefs(props)
</script>

<template>
  <div ref="scrollContainer" class="relative space-y-4 bg-transparent">
    <HumanMessage :run="run" />
    <AIMessage :run="run" @submit="$emit('submit', $event)" :isLast="isLast" />
  </div>
</template>