<script setup>
import { TagsInput, TagsInputInput, TagsInputItem, TagsInputItemDelete, TagsInputItemText } from '@/components/ui/tags-input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form'
import { defineEmits, defineProps } from 'vue'
import { Trash2, HelpCircle } from 'lucide-vue-next'
import { Textarea } from '@/components/ui/textarea'
import { toTypedSchema } from '@vee-validate/zod'
import { Button } from '@/components/ui/button'
import { columnTypes } from '../models/options'
import { Input } from '@/components/ui/input'
import { useForm } from 'vee-validate'
import * as z from 'zod'

const props = defineProps({
    onCancel: { type: Function },
    disabled: { type: Boolean, required: false, default: false },
    defaultValue: { type: Object, required: false }
})

const emit = defineEmits(['submit', 'cancel', 'delete'])

const formSchema = toTypedSchema(z.object({
    label: z.string()
        .min(1, 'Label is required')
        .max(100, 'Label must be less than 100 characters'),
    instructions: z.string()
        .min(1, 'Instructions are required')
        .max(10000, 'Instructions must be less than 10000 characters'),
    type: z.enum(columnTypes.map(type => type.value), {
        required_error: 'Please select a response format',
    }),
    options: z.array(z.string()).optional()
}).superRefine((data, ctx) => {
    if (['select', 'multi_select'].includes(data.type) && (!data.options || data.options.length === 0)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Options are required for select and multi-select types',
            path: ['options']
        })
    }
}))

const form = useForm({
    validationSchema: formSchema,
    initialValues: {
        label: props.defaultValue?.label ?? '',
        instructions: props.defaultValue?.instructions ?? '',
        type: props.defaultValue?.type ?? 'text',
        options: props.defaultValue?.options ?? []
    },
})

const onSubmit = form.handleSubmit((values) => emit('submit', values))
</script>

<template>
    <form @submit="onSubmit" class="w-full space-y-4" :disabled="disabled">
        <FormField name="label" v-slot="{ field }">
            <FormItem>
                <div class="flex items-center gap-2">
                    <FormLabel>Label</FormLabel>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger @click.prevent.stop>
                                <HelpCircle class="w-4 h-4 text-muted-foreground" />
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>The column header that will appear in your sheet</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                <FormControl>
                    <Input v-model="field.value" @update:modelValue="field.onChange" placeholder="Enter column label"
                        :disabled="disabled" />
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>

        <FormField name="instructions" v-slot="{ field }">
            <FormItem>
                <div class="flex items-center gap-2">
                    <FormLabel>Instructions</FormLabel>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger @click.prevent.stop>
                                <HelpCircle class="w-4 h-4 text-muted-foreground" />
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>The instructions that will be sent to Zeno for each row</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                <FormControl>
                    <Textarea v-model="field.value" @update:modelValue="field.onChange" placeholder="Enter prompt text"
                        class="flex min-h-[80px] min-w-[300px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground"
                        :disabled="disabled" />
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>

        <FormField name="type" v-slot="{ field }">
            <FormItem>
                <div class="flex items-center gap-2">
                    <FormLabel>Type</FormLabel>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger @click.prevent.stop>
                                <HelpCircle class="w-4 h-4 text-muted-foreground" />
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>The type of response that Zeno will provide</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                <Select v-model="field.value" @update:modelValue="field.onChange" :disabled="disabled">
                    <FormControl>
                        <SelectTrigger>
                            <SelectValue placeholder="Select format" />
                        </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                        <SelectItem v-for="type in columnTypes" :key="type.value" :value="type.value">
                            <div class="flex items-center gap-2">
                                <component :is="type.icon" class="w-4 h-4 flex-shrink-0" />
                                <span>{{ type.label }}</span>
                            </div>
                        </SelectItem>
                    </SelectContent>
                </Select>
                <FormMessage />
            </FormItem>
        </FormField>

        <FormField v-if="form.values.type === 'select' || form.values.type === 'multi_select'" name="options"
            v-slot="{ field }">
            <FormItem>
                <div class="flex items-center gap-2">
                    <FormLabel>Options</FormLabel>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger @click.prevent.stop>
                                <HelpCircle class="w-4 h-4 text-muted-foreground" />
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Add options that Zeno should select from</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                <FormControl>
                    <TagsInput v-model="field.value" @update:modelValue="field.onChange">
                        <TagsInputItem v-for="option in field.value" :key="option" :value="option">
                            <TagsInputItemText />
                            <TagsInputItemDelete />
                        </TagsInputItem>
                        <TagsInputInput placeholder="Type an option and press Enter..." />
                    </TagsInput>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>

        <div v-if="!disabled" class="flex flex-row justify-between items-center">
            <Button class=" text-xs flex flex-row items-center space-x-2" variant="ghost"
                @click.prevent.stop="emit('delete')">
                <Trash2 class="w-3 h-3 flex-shrink-0 text-muted-foreground" />
                <span class="text-xs text-muted-foreground">Delete</span>
            </Button>
            <div class="flex gap-2">
                <Button v-if="onCancel" type="button" variant="outline" @click="emit('cancel')">Cancel</Button>
                <Button type="submit">Submit</Button>
            </div>
        </div>
        <div v-else class="flex gap-2 justify-end">
            <Button v-if="onCancel" type="button" variant="outline" @click="emit('cancel')">Cancel</Button>
            <Button type="submit" :disabled="disabled">Submit</Button>
        </div>
    </form>
</template>
