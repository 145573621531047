<script setup>
import { Button } from '@/components/ui/button'
import { SaveIcon } from 'lucide-vue-next'
import { Select, SelectItem, SelectTrigger, SelectContent, SelectValue } from '@/components/ui/select'
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import * as z from 'zod'
import { defineProps, defineEmits, watch } from 'vue'
import { Input } from '@/components/ui/input'

const props = defineProps({
    initialValues: { type: Object, required: true },
    disabled: { type: Boolean, default: false }
})
const emit = defineEmits(['submit'])

const formSchema = toTypedSchema(z.object({
    name: z.string().min(1, 'Name is required'),
    description: z.string().min(1, 'Description is required'),
    content: z.string()
        .min(1, 'Content is required')
        .max(10000, 'Content must be less than 10000 characters'),
    mode: z.enum(['draft', 'review', 'research'], {
        required_error: 'Please select a mode',
    }),
}))

const form = useForm({
    validationSchema: formSchema,
    initialValues: {
        name: props.initialValues?.name ?? '',
        description: props.initialValues?.description ?? '',
        content: props.initialValues?.content ?? '',
        mode: props.initialValues?.mode ?? 'review',
    },
})

const onSubmit = form.handleSubmit((data) => {
    emit('submit', data)
    form.resetForm({
        values: data,
        touched: false,
        dirty: false
    })
})

watch(
    () => props.initialValues,
    (newValues, oldValues) => {
        if (newValues && newValues?.id !== oldValues?.id) {
            form.resetForm({
                values: newValues,
                touched: false,
                dirty: false,
            })
        }
    },
    { deep: true }
)
</script>

<template>
    <form @submit="onSubmit" class="flex flex-col gap-4 h-full">
        <div class="grid grid-cols-2 gap-6 h-full">
            <!-- Left Column -->
            <div class="flex flex-col gap-4">
                <FormField name="name" v-slot="{ field }">
                    <FormItem>
                        <FormLabel>Name</FormLabel>
                        <p class="text-sm text-muted-foreground mb-2">
                            Give your template a clear, descriptive name for easy reference
                        </p>
                        <FormControl>
                            <Input v-model="field.value" @update:modelValue="field.onChange" :disabled="disabled"
                                placeholder="Enter template name..." />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                </FormField>

                <FormField name="description" v-slot="{ field }">
                    <FormItem>
                        <FormLabel>Description</FormLabel>
                        <p class="text-sm text-muted-foreground mb-2">
                            Provide a brief summary of what this template is used for
                        </p>
                        <FormControl>
                            <Textarea v-model="field.value" @update:modelValue="field.onChange" :disabled="disabled"
                                placeholder="Enter template description..." class="min-h-[120px]" />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                </FormField>

                <FormField name="mode" v-slot="{ field }">
                    <FormItem>
                        <FormLabel>Mode</FormLabel>
                        <p class="text-sm text-muted-foreground mb-2">
                            Select how this template will be used in conversations
                        </p>
                        <Select v-model="field.value" @update:modelValue="field.onChange" :disabled="disabled">
                            <FormControl>
                                <SelectTrigger>
                                    <SelectValue placeholder="Select a mode" />
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                                <SelectItem value="draft">Draft</SelectItem>
                                <SelectItem value="review">Review</SelectItem>
                                <SelectItem value="research">Research</SelectItem>
                            </SelectContent>
                        </Select>
                        <FormMessage />
                    </FormItem>
                </FormField>
            </div>

            <!-- Right Column -->
            <div class="flex flex-col gap-4">
                <FormField name="content" v-slot="{ field }">
                    <FormItem class="h-full">
                        <FormLabel>Instructions</FormLabel>
                        <p class="text-sm text-muted-foreground mb-2">
                            Write the template instructions that will guide the Zeno's responses
                        </p>
                        <FormControl>
                            <Textarea v-model="field.value" @update:modelValue="field.onChange" :disabled="disabled"
                                placeholder="Write your instructions for Zeno here..." class="min-h-[300px]" />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                </FormField>
            </div>
        </div>

        <div v-if="!disabled || $slots.default" class="flex items-center gap-2">
            <slot :disabled="!form.meta.value.dirty || form.meta.value.isSubmitting">
                <Button type="submit" :disabled="!form.meta.value.dirty || form.meta.value.isSubmitting"
                    variant="outline" size="sm">
                    <SaveIcon class="w-4 h-4 mr-2" />
                    Save
                </Button>
            </slot>
        </div>
    </form>
</template>
