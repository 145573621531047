import { Model } from 'pinia-orm';
import { DateCast } from '@/models/utils'
import { User } from '@/models/User'

export class Project extends Model {
    static entity = 'projects'
    static fields() {
        return {
            id: this.attr(null),
            icon: this.string('Box'),
            color: this.string('foreground'),
            status: this.string('active'),
            name: this.string('Untitled'),
            description: this.string(''),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            owner: this.belongsTo(User, 'user_id')
        }
    }

    get uploadStatus() {
        return 'done'
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}
