<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useRepo, Project } from '@/models';
import { TemplateDialog } from '@/services/templates';
import { LibraryTableDialog, LibraryService } from '@/services/library'
import { SheetService } from '@/services/sheets';
import { ThreadService } from '@/services/threads';
import { computed, defineEmits } from 'vue';
import { ActionCard } from '@/components/controls'
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { CopyIcon, ReplaceAllIcon } from 'lucide-vue-next';

const route = useRoute()
const router = useRouter()

const projectId = computed(() => route.params?.projectId)
const project = computed(() => useRepo(Project).find(projectId.value))

const applyTemplate = async (template) => {
    if (template.type == 'PROMPTTEMPLATE') {
        await ThreadService.createThread({ name: template.name, projectId }, { router, query: { templateId: template.id } })
    } else {
        await SheetService.createFromTemplate({ template, projectId }, { router })
    }
}

const emit = defineEmits(['refresh'])

const copy = async (selectedItems, close) => {
    await LibraryService.copy(project.value, selectedItems, ['document'])
    emit('refresh')
    close()
}

const move = async (selectedItems, close) => {
    await LibraryService.move(project.value, selectedItems)
    close()
}
</script>

<template>
    <div class="flex flex-col w-full  ">
        <Grid class="grid auto-rows-[minmax(225px,auto)] grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <ActionCard @click="ThreadService.createThread({ projectId }, { router })" title="Start assistant"
                description="Ask the assistant to help you with your project.">
                <template #left-badge>
                    <Badge variant="assistant" class="text-xs font-medium">Assistant</Badge>
                </template>
            </ActionCard>
            <ActionCard @click="SheetService.createSheet({ projectId }, { router })" title="Start sheet"
                description="Use the sheet to do bulk analysis on your documents.">
                <template #left-badge>
                    <Badge variant="sheet" class="text-xs font-medium">Sheet</Badge>
                </template>
            </ActionCard>
            <TemplateDialog @apply="applyTemplate">
                <ActionCard title="Start from template" description="Select a template to analyze specific use cases.">
                    <template #left-badge>
                        <Badge variant="default" class="text-xs font-medium">Templates</Badge>
                    </template>
                </ActionCard>
            </TemplateDialog>
            <LibraryTableDialog min-selected="1" multiple selectable="checkbox" :filters="{ type: 'documents' }"
                stop-propagate-selection>
                <ActionCard title="Import documents" description="Import documents from your shared library.">
                    <template #left-badge>
                        <Badge variant="default" class="text-xs font-medium">Documents</Badge>
                    </template>
                </ActionCard>
                <template #submit="{ disabled, selectedItems, close }">
                    <Button @click="copy(selectedItems, close)" :disabled="disabled">
                        <CopyIcon class="h-4 w-4 mr-2" />
                        <span>Copy</span>
                    </Button>
                    <Button @click="move(selectedItems, close)" :disabled="disabled">
                        <ReplaceAllIcon class="h-4 w-4 mr-2" />
                        <span>Move</span>
                    </Button>
                </template>
            </LibraryTableDialog>
        </Grid>
    </div>
</template>