<script setup>
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { useSidebar, SidebarMenuButton } from '@/components/ui/sidebar';
import { Plus, Upload, Grid2x2, ComponentIcon } from 'lucide-vue-next';
import { useRouter, useRoute } from 'vue-router';
import { UploadButton } from '@/services/library/components';
import { SheetService } from '@/services/sheets';
import { ThreadService } from '@/services/threads';

const router = useRouter()
const route = useRoute()

const { open } = useSidebar()
</script>

<template>
    <DropdownMenu>
        <DropdownMenuTrigger asChild>
            <SidebarMenuButton tooltip="New" variant="outline" size="xl"
                class="rounded-full border-border w-fit items-center justify-center bg-primary text-primary-foreground pr-3 hover:bg-primary/90 hover:text-primary-foreground  transition-all duration-500">
                <Plus :stroke-width="2.5" :class="{ 'ml-[0.45rem]': !open }" />
                <span class="font-semibold">New</span>
            </SidebarMenuButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent side="right" align="start" class="w-fit bg-sidebar-accent rounded-xl">
            <DropdownMenuItem class="flex flex-row items-center rounded-xl cursor-pointer"
                @click="ThreadService.createThread({ name: 'New Chat', projectId: route.params?.projectId, parentId: route.query?.folderId }, { router })">
                <ComponentIcon class="mr-2 h-5 w-5 text-blue-500" />
                <span class="text-base">Assistant</span>
            </DropdownMenuItem>
            <DropdownMenuItem class="flex flex-row items-center rounded-xl cursor-pointer"
                @click="SheetService.createSheet({ name: 'New Sheet', projectId: route.params?.projectId, parentId: route.query?.folderId }, { router })">
                <Grid2x2 class="mr-2 h-5 w-5 text-emerald-700" />
                <span class="text-base">Sheet</span>
            </DropdownMenuItem>
            <UploadButton :project-id="route.params?.projectId" :folder-id="route.query?.folderId">
                <DropdownMenuItem class="flex flex-row items-center rounded-xl cursor-pointer w-full">
                    <Upload class="mr-2 w-5 h-5" />
                    <span class="text-base">Upload</span>
                </DropdownMenuItem>
            </UploadButton>
        </DropdownMenuContent>
    </DropdownMenu>
</template>