<script setup>
import { defineProps, defineEmits, ref, defineExpose } from 'vue'
import { FileUp } from 'lucide-vue-next'
import { cn } from '@/lib/utils'

const emit = defineEmits(['drop'])
const props = defineProps({
    text: { type: String, default: 'Drop to add documents' },
    disabled: { type: Boolean, default: false },
    noOverlay: { type: Boolean, default: false }
})

// Add new refs for drag and drop state
const isDraggingOver = ref(false)
const draggedItemType = ref(null)
const isInternalDrag = ref(false)
const dropzone = ref(null)

// Add debounce timer ref
const dragDebounceTimer = ref(null)
const previousStateChange = ref(false)

// Add debounced state change function
const setDraggingState = (value) => {
    if (previousStateChange.value !== value) {
        clearTimeout(dragDebounceTimer.value)
        previousStateChange.value = value
        dragDebounceTimer.value = setTimeout(() => {
            isDraggingOver.value = value
            if (!value) draggedItemType.value = null
        }, 1) // 50ms debounce delay
    }
}

// Add handlers for drag and drop
const handleDragEnter = (event) => {
    // Prevent all drag events if disabled
    if (props.disabled) {
        event.preventDefault()
        return
    }

    event.preventDefault()

    // Ignore if this is an internal drag operation
    if (isInternalDrag.value) return

    // Check if this is likely an external document drag
    const hasTextPlainType = Array.from(event.dataTransfer?.types || []).includes('text/plain')

    if (hasTextPlainType) {
        setDraggingState(true)
        draggedItemType.value = 'unknown'
    }
}

const handleDragOver = (event) => {
    // Prevent all drag events if disabled
    if (props.disabled) {
        event.preventDefault()
        return
    }
    handleDragEnter(event)
}

const handleDragLeave = (event) => {
    // Prevent all drag events if disabled
    if (props.disabled) {
        event.preventDefault()
        return
    }

    event.preventDefault()

    // Only hide overlay if we're leaving the container
    // Check if the related target is outside the container or null
    const related = event.relatedTarget
    if (!related || (!event.currentTarget.contains(related) && related !== event.currentTarget)) {
        setDraggingState(false)
    }
}

// Add a new handler for drop events
const handleDrop = (event) => {
    if (props.disabled) {
        event.preventDefault()
        return
    }
    if (event.target.closest('[data-prevent-parent-drop]')) {
        return
    }

    emit('drop', event)
    handleDragLeave(event)
}

defineExpose({
    isDraggingOver,
    handleDragEnter,
    handleDragOver,
    handleDragLeave
})
</script>

<template>
    <div ref="dropzone" class="relative" @dragenter.prevent.capture="handleDragEnter"
        @dragleave.prevent.capture="handleDragLeave" @dragover.prevent.capture="handleDragOver"
        @drop.prevent.capture="handleDrop">
        <!-- Drag overlay -->
        <Transition enter-active-class="transition-opacity duration-300"
            leave-active-class="transition-opacity duration-300" enter-from-class="opacity-0"
            leave-to-class="opacity-0">
            <div v-if="isDraggingOver"
                :class="cn(['bg-background/10 rounded-3xl backdrop-blur-sm', $attrs.class, 'absolute inset-0 flex items-center justify-center pointer-events-none z-50'])">
                <div
                    :class="cn(['border-2 rounded-3xl border-dotted border-primary/10', $attrs.class, 'w-full h-full flex items-center justify-center'])">
                    <slot name="overlay">
                        <FileUp class="w-6 h-6 mr-2" :class="{ 'opacity-0': noOverlay }" />
                        <span class="text-xl font-medium text-primary" :class="{ 'opacity-0': noOverlay }">{{ text
                        }}</span>
                    </slot>
                </div>
            </div>
        </Transition>
        <slot />
    </div>

</template>

<style scoped>
.backdrop-blur-sm {
    backdrop-filter: blur(8px);
}
</style>