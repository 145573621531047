import { Model } from 'pinia-orm'
import { DateCast } from '@/models/utils'
import { User, Organization, Project } from '@/models'
import { Sheet, Thread, Folder, Document } from './ResourceHierarchy'

export class Resource extends Model {
    static entity = 'resource'

    static types() {
        return {
            RESOURCE: Resource,
            SHEET: Sheet,
            THREAD: Thread,
            FOLDER: Folder,
            DOCUMENT: Document,
        }
    }

    static fields() {
        return {
            id: this.attr(null),
            type: this.string('RESOURCE'),
            name: this.string(''),
            description: this.string(''),
            created_at: this.attr(null),
            updated_at: this.attr(null),
            project_id: this.attr(null),
            parent_id: this.attr(null),
            user_id: this.attr(null),
            organization_id: this.attr(null),
            
            // Relationships
            owner: this.belongsTo(User, 'user_id'),
            organization: this.belongsTo(Organization, 'organization_id'),
            parent: this.belongsTo(Resource, 'parent_id'),
            children: this.hasMany(Resource, 'parent_id'),
            project: this.belongsTo(Project, 'project_id'),
        }
    }


    get uploadProgress() {
        if (this.children?.some(child => child?.uploadStatus == 'pending')) {
            return this.children.reduce(
                (acc, child) => acc + (child?.uploadStatus != 'pending' ? 1 : 0), 0
            ) / this.children.length * 100
        }
        return 100
    }

    get uploadErrors() {
        return this.children.filter(child => child?.upload_status == 'failed').length
    }

    get uploadStatus() {
        if (this.upload_status) {
            return this.upload_status
        }
        if (this.children?.some(child => child?.uploadStatus == 'pending')) {
            return 'pending'
        }
        return 'done'
    }

    static casts() {
        return {
            created_at: DateCast,
            updated_at: DateCast
        }
    }
}

export default Resource;