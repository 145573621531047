import { Template } from '@/services/templates/models/TemplateHierarchy'

export class PromptTemplate extends Template {
    static entity = 'prompttemplate'
    static baseEntity = 'template'

    static fields() {
        return {
            ...super.fields(),
            type: this.string('PROMPTTEMPLATE'),
            mode: this.string(''),
            content: this.string(''),
        }
    }

    get isUsable() {
        return this.mode && this.content
    }
}

export default PromptTemplate;