<script setup>
import { useRepo, Case, Consolidation, Document } from '@/models';
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbLink, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { MainLayout, NavbarLayout } from '@/layouts';
import { Editor } from '@/components/editor';
import { useQuery } from '@tanstack/vue-query';
import { Compass, Gavel, Scale } from 'lucide-vue-next';
import { Loading } from '@/components/loading';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { CaseIcon, RegulationIcon } from '@/components/icons';
import { ResourceBreadcrumb } from '@/components/resources';

const route = useRoute();
const type = computed(() => {
    if (route.params.type?.includes('case')) return 'case';
    if (route.params.type?.includes('regulation')) return 'regulation';
    if (route.name.includes('document')) return 'document';
    return null;
});
const resourceId = computed(() => route.params.resourceId);

const { data: resource, isLoading } = useQuery({
    queryKey: [type, resourceId],
    queryFn: async () => {
        if (type.value === 'case') {
            const response = await useRepo(Case).api().get(`/legal/cases/${resourceId.value}`, {
                dataTransformer: (response) => {
                    response.data.id = response.data.uid;
                    return response.data;
                }
            });
            return response.entities[0];
        }
        if (type.value === 'regulation') {
            const response = await useRepo(Consolidation).api().get(`/legal/regulations/consolidations/${resourceId.value}`, {
                dataTransformer: (response) => {
                    response.data.id = response.data.uid;
                    return response.data;
                }
            });
            return response.entities[0];
        }
        if (type.value === 'document') {
            await useRepo(Document).api().get(`/documents/${resourceId.value}`);
            return useRepo(Document).withAllRecursive().find(resourceId.value);
        }
    }
});
</script>

<template>
    <Loading v-if="isLoading" />
    <MainLayout v-else>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <ResourceBreadcrumb v-if="type === 'document'" :folder-id="resource.parent_id"
                        :project-id="resource.project_id" :resource="resource" />
                    <Breadcrumb v-else>
                        <BreadcrumbList>
                            <BreadcrumbItem>
                                <BreadcrumbLink>
                                    <RouterLink class="flex flex-row space-x-2 items-center" :to="{ name: 'explore' }">
                                        <Compass />
                                        <span>Explore</span>
                                    </RouterLink>
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <BreadcrumbLink>
                                    <RouterLink v-if="type === 'case'" class="flex flex-row space-x-2 items-center"
                                        :to="{ name: 'type-explore', params: { type: 'cases' } }">
                                        <Gavel />
                                        <span>Case Law</span>
                                    </RouterLink>
                                    <RouterLink v-else-if="type === 'regulation'"
                                        class="flex flex-row space-x-2 items-center"
                                        :to="{ name: 'type-explore', params: { type: 'regulations' } }">
                                        <Scale />
                                        <span>Laws & Regulations</span>
                                    </RouterLink>
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbSeparator />
                            <BreadcrumbItem>
                                <BreadcrumbPage class="flex flex-row space-x-2 items-center">
                                    <CaseIcon v-if="type === 'case'" class="size-4" />
                                    <RegulationIcon v-else-if="type === 'regulation'" class="size-4" />
                                    <span>{{ resource.display?.title.split(' ').slice(0, 5).join(' ') }}</span>
                                </BreadcrumbPage>
                            </BreadcrumbItem>
                        </BreadcrumbList>
                    </Breadcrumb>
                </template>
            </NavbarLayout>
        </template>
        <template #content>
            <Editor :content="resource.pageContent" />
        </template>
    </MainLayout>
</template>