<script setup>
import { Select, SelectTrigger, SelectContent, SelectItem } from '@/components/ui/select';
import { computed, defineModel, defineExpose, defineProps } from 'vue';
import { Button } from '@/components/ui/button';
import { ChevronDown } from 'lucide-vue-next';

const selectedOption = defineModel({ default: 'all' })
const props = defineProps({
    options: { type: Array, required: true }
})

const availableOptions = [
    { value: 'all', label: 'Type' },
    ...props.options
]
const displayValue = computed(() => availableOptions.find(t => t.value === selectedOption.value)?.label || 'Type')

defineExpose({
    displayValue
})
</script>

<template>
    <Select v-model="selectedOption">
        <SelectTrigger asChild>
            <slot>
                <Button variant="outline" size="sm" class="flex items-center gap-2 w-full">
                    <component v-if="availableOptions.find(t => t.value === selectedOption)?.icon"
                        :is="availableOptions.find(t => t.value === selectedOption)?.icon" class="size-4 shrink-0" />
                    <span>{{ displayValue }}</span>
                    <ChevronDown class="size-4 shrink-0 text-muted-foreground" />
                </Button>
            </slot>
        </SelectTrigger>
        <SelectContent>
            <SelectItem v-for="option in availableOptions" :key="option.value" :value="option.value"
                check-position="right">
                <div class="flex items-center gap-2">
                    <component v-if="option.icon" :is="option.icon" class="size-4 shrink-0" />
                    <span>{{ option.label === 'Type' ? 'All' : option.label }}</span>
                </div>
            </SelectItem>
        </SelectContent>
    </Select>
</template>