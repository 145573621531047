<script setup>
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Play } from 'lucide-vue-next';
import { defineProps, ref } from 'vue';
import { cn } from '@/lib/utils';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import { Badge } from '@/components/ui/badge';

const dialogOpen = ref(false)

defineProps({
    title: {
        type: String,
        required: false
    },
    description: {
        type: String,
        required: false
    },
    size: {
        type: String,
        required: false,
        default: 'full'
    },
    videoId: {
        type: String,
        required: false
    }
})
</script>

<template>
    <Card class="border-none" @click="dialogOpen = true"
        :class="cn('group relative text-left flex flex-col dark:bg-neutral-800 dark:hover:bg-neutral-700 bg-neutral-200 hover:bg-neutral-300 space-y-2 p-4 items-start h-full rounded-2xl cursor-pointer overflow-hidden', $attrs.class)">
        <div class="flex flex-row items-center justify-between w-full relative z-10">
            <slot name="left-badge">
                <Badge variant="default" class="text-xs font-medium">Video</Badge>
            </slot>
        </div>

        <div class="flex flex-row space-x-2 items-start relative z-10">
            <h1 class="text-lg font-semibold">{{ title }}</h1>
        </div>

        <div class="flex flex-grow items-end h-full relative z-10">
            <p class="text-sm text-muted-foreground text-left">{{ description }}</p>
        </div>

        <div class="absolute inset-0 hidden group-hover:flex items-center justify-center backdrop-blur-sm transition-all duration-300">
            <Button variant="ghost" size="icon" class="hover:bg-transparent bg-transparent border-none">
                <Play fill="currentColor" class="text-primary" />
            </Button>
        </div>
    </Card>

    <Dialog v-model:open="dialogOpen">
        <DialogContent class="sm:max-w-[800px]">
            <DialogHeader>
                <DialogTitle>{{ title }}</DialogTitle>
            </DialogHeader>
            <div class="aspect-video w-full">
                <iframe
                    v-if="videoId"
                    width="100%"
                    height="100%"
                    :src="`https://www.youtube.com/embed/${videoId}`"
                    :title="title"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
        </DialogContent>
    </Dialog>
</template>