<script setup>
import {
    EditableRoot,
    EditableEditTrigger,
    Primitive
} from 'radix-vue';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
    DropdownMenuSeparator,
    DropdownMenuGroup,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
    DropdownMenuPortal,
    DropdownMenuLabel
} from '@/components/ui/dropdown-menu';
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
    ContextMenuSeparator,
    ContextMenuGroup,
    ContextMenuSub,
    ContextMenuSubTrigger,
    ContextMenuSubContent,
    ContextMenuLabel
} from '@/components/ui/context-menu';
import {
    FolderPlus,
    PenLine,
    Copy,
    FolderOutput,
    Trash2,
    UserPlus,
    Save,
    Download,
    Text,
    Layout,
    Plus,
    CopyPlus,
    FileUp,
    FolderUp
} from 'lucide-vue-next';
import {
    ref,
    defineModel,
    defineProps,
    toValue,
    watch,
    defineEmits,
    computed
} from 'vue';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { LibraryTableDialog } from '@/services/library';
import { IconPicker } from '@/components/controls';
import * as icons from 'lucide-vue-next';
import { debounce } from 'lodash';
import { useRouter } from 'vue-router';
import { PromptTemplate, SheetTemplate } from '@/models';
import { TemplateDialog } from '@/services/templates';
import { ThreadService } from '@/services/threads';
import { SheetService } from '@/services/sheets';

const router = useRouter()

const props = defineProps({
    variant: {
        type: String,
        default: 'dropdown'
    },
    editableIcon: {
        type: Boolean,
        default: false
    },
    editableName: {
        type: Boolean,
        default: false
    },
    onCopy: {
        type: Function,
        default: null
    },
    onMove: {
        type: Function,
        default: null
    },
    onDelete: {
        type: Function,
        required: false,
        default: null
    },
    onShare: {
        type: Function,
        default: null
    },
    onSaveAsTemplate: {
        type: Function,
        default: null
    },
    onDownload: {
        type: Function,
        default: null
    },
    onCreate: {
        type: Function,
        default: null
    },
    onUpload: {
        type: Function,
        default: null
    },
    disabled: {
        type: Boolean,
        default: false
    },
    item: {
        type: Object,
        required: false,
        default: null
    },
    projectId: {
        type: String,
        required: false,
        default: null
    },
    folderId: {
        type: String,
        required: false,
        default: null
    },
    items: {
        type: Array,
        required: false,
        default: () => []
    }
});

if (!['dropdown', 'context'].includes(props.variant)) {
    throw new Error('Invalid variant');
}

const name = defineModel('name');
const icon = defineModel('icon');
const color = defineModel('color', { default: 'primary' });
const iconConfig = ref({ icon: toValue(icon), color: toValue(color) });

const fileInput = ref(null);
const folderInput = ref(null);


const emitChangeIconDebounced = debounce(() => {
    emit('change-icon');
}, 2000);

watch(iconConfig, () => {
    icon.value = iconConfig.value.icon;
    color.value = iconConfig.value.color;
    emitChangeIconDebounced.cancel();
    emitChangeIconDebounced();
}, { deep: true });

const emit = defineEmits(['rename', 'change-icon', 'update:state']);

async function createThread(template = null) {
    await ThreadService.createThread({ name: template?.name || 'New Chat', projectId: props.projectId, parentId: props.folderId, attachments: props?.items.length ? props?.items : [props.item] }, { router, query: { templateId: template?.id } })
}

async function createSheet(template = null) {
    if (template) {
        await SheetService.createFromTemplate({ template, parentId: props.folderId, projectId: props.projectId }, { router, attachments: props?.items.length ? props?.items : [props.item] })
    } else {
        await SheetService.createSheet({ name: 'New Sheet', projectId: props.projectId, parentId: props.folderId, attachments: props?.items.length ? props?.items : [props.item] }, { router })
    }
}

async function applyTemplate(template) {
    if (template instanceof PromptTemplate) {
        await createThread(template)
    } else if (template instanceof SheetTemplate) {
        await createSheet(template)
    }
}

const displayName = computed(() => {
    if (props.items.length && props.items.some(el => el.id === props.item.id)) {
        return `${props.items.length} items selected`
    }
    return null
})

const displayIcon = computed(() => {
    if (props.items.length && props.items.some(el => el.id === props.item.id)) {
        return 'List'
    }
    return null
})
</script>


<template>
    <component :is="props.variant === 'dropdown' ? DropdownMenu : ContextMenu">
        <component :is="editableName ? EditableRoot : Primitive" @submit="emit('rename')" activation-mode="none"
            submit-mode="both" v-model="name" v-slot="slots" as-child @update:state="emit('update:state', $event)">
            <component :is="props.variant === 'dropdown' ? DropdownMenuTrigger : ContextMenuTrigger"
                :disabled="disabled" as-child>
                <slot v-if="slots" v-bind="slots" />
                <slot v-else />
            </component>
            <component :is="props.variant === 'dropdown' ? DropdownMenuContent : ContextMenuContent">
                <template v-if="editableName || editableIcon">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuLabel : ContextMenuLabel">
                        <IconPicker v-if="editableIcon" v-model="iconConfig" />
                        <component v-else :is="icons[displayIcon || icon]"
                            :class="displayIcon ? 'text-primary' : `text-${color}`" class="h-full" />
                        <span>{{ displayName || name }}</span>
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="onCreate">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <component @click="onCreate('folder')"
                            :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                            <FolderPlus />
                            <span>New folder</span>
                        </component>
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="editableName || onCopy || onMove">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <EditableEditTrigger as-child v-if="editableName && !displayName">
                            <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                <PenLine />
                                <span>Rename</span>
                            </component>
                        </EditableEditTrigger>
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            v-if="onCopy">
                            <Copy />
                            <span>Duplicate</span>
                        </component>
                        <LibraryTableDialog v-if="onMove" @submit="onMove" :default-project-id="projectId"
                            :default-folder-id="folderId">
                            <component @click.prevent.stop @select.prevent
                                :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                <FolderOutput />
                                <span>Move to</span>
                            </component>
                            <template #footer>
                                <div class="flex items-center gap-1">
                                    <span class="text-sm text-muted-foreground">Select a destination for:</span>
                                    <template v-if="!items.length">
                                        <component :is="icons[icon]" :class="`text-${color}`" class="h-4" />
                                        <span class="text-sm">{{ name }}</span>
                                    </template>
                                    <template v-else>
                                        <span class="text-sm text-muted-foreground">
                                            {{ items.length }} items selected
                                        </span>
                                    </template>
                                </div>
                            </template>
                        </LibraryTableDialog>
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="onUpload">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            @click="fileInput.click()">
                            <FileUp />
                            <span>Upload files</span>
                        </component>
                        <input type="file" ref="fileInput" @change="onUpload" class="hidden"
                            accept="pdf,docx,doc,txt,md,jpg,jpeg,png,gif,webp,pptx,ppt,xls,xlsx,msg" multiple />
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            @click="folderInput.click()">
                            <FolderUp />
                            <span>Upload folder</span>
                        </component>
                        <input type="file" ref="folderInput" @change="onUpload" class="hidden"
                            accept="pdf,docx,doc,txt,md,jpg,jpeg,png,gif,webp,pptx,ppt,xls,xlsx,msg" webkitdirectory
                            directory multiple />
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="onShare || onSaveAsTemplate || onDownload">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            v-if="onShare">
                            <UserPlus />
                            <span>Share</span>
                        </component>
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            v-if="onSaveAsTemplate">
                            <Save />
                            <span>Save as template</span>
                        </component>
                        <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                            v-if="onDownload">
                            <Download />
                            <span>Download</span>
                        </component>
                    </component>
                    <component :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <template v-if="onCreate">
                    <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup">
                        <component :is="props.variant === 'dropdown' ? DropdownMenuSub : ContextMenuSub">
                            <component @click="onCreate('thread')"
                                :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem" as-child>
                                <component
                                    :is="props.variant === 'dropdown' ? DropdownMenuSubTrigger : ContextMenuSubTrigger">
                                    <Text />
                                    <span>Assistant</span>
                                </component>
                            </component>
                            <component :is="props.variant === 'dropdown' ? DropdownMenuPortal : 'div'">
                                <component
                                    :is="props.variant === 'dropdown' ? DropdownMenuSubContent : ContextMenuSubContent">
                                    <component @click="onCreate('thread')"
                                        :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                        <Plus />
                                        <span>Start chat</span>
                                    </component>
                                    <TemplateDialog @apply="applyTemplate" :types="['threads']">
                                        <component @select.prevent
                                            :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                            <CopyPlus />
                                            <span>From template</span>
                                        </component>
                                    </TemplateDialog>
                                </component>
                            </component>
                        </component>
                        <component :is="props.variant === 'dropdown' ? DropdownMenuSub : ContextMenuSub">
                            <component @click="onCreate('sheet')"
                                :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem" as-child>
                                <component
                                    :is="props.variant === 'dropdown' ? DropdownMenuSubTrigger : ContextMenuSubTrigger">
                                    <Layout />
                                    <span>Sheets</span>
                                </component>
                            </component>
                            <component :is="props.variant === 'dropdown' ? DropdownMenuPortal : 'div'">
                                <component
                                    :is="props.variant === 'dropdown' ? DropdownMenuSubContent : ContextMenuSubContent">
                                    <component @click="onCreate('sheet')"
                                        :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                        <Plus />
                                        <span>Empty sheet</span>
                                    </component>
                                    <TemplateDialog @apply="applyTemplate" :types="['sheets']">
                                        <component @select.prevent
                                            :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem">
                                            <CopyPlus />
                                            <span>From template</span>
                                        </component>
                                    </TemplateDialog>
                                </component>
                            </component>
                        </component>
                    </component>
                    <component v-if="onDelete"
                        :is="props.variant === 'dropdown' ? DropdownMenuSeparator : ContextMenuSeparator" />
                </template>
                <component :is="props.variant === 'dropdown' ? DropdownMenuGroup : ContextMenuGroup" v-if="onDelete">
                    <AlertDialog>
                        <AlertDialogTrigger as-child>
                            <component :is="props.variant === 'dropdown' ? DropdownMenuItem : ContextMenuItem"
                                class="text-destructive" @select.prevent>
                                <Trash2 />
                                <span>Delete</span>
                            </component>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    This action cannot be undone. This will permanently delete the item.
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction @click="onDelete">Delete</AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </component>
            </component>
        </component>
    </component>
</template>
