<script setup>
import { SidebarMenuItem, SidebarMenuButton, SidebarMenuSub, SidebarMenuSubItem, SidebarMenuSubButton } from '@/components/ui/sidebar';
import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible';
import { ResourceItem, ResourceName, ResourceIcon } from '@/components/resources';
import { LibraryButton } from '@/services/library';
import { useRouter, useRoute } from 'vue-router';
import { defineProps, computed } from 'vue';
import { Layers } from 'lucide-vue-next';
import { LibraryService } from '@/services/library';
import { Dropzone } from '@/components/behaviour/dropzone';

const router = useRouter();
const route = useRoute();

const props = defineProps({
    project: {
        type: Object,
        required: true
    }
})

const isActive = computed(() => route.params.projectId === props.project.id);
const isOverviewActive = computed(() => route.name === 'project' && isActive.value);
const openProject = () => router.push({ name: 'project', params: { projectId: props.project.id } })
</script>

<template>
    <Dropzone @drop="LibraryService.handleDrop(project, $event)" class="rounded-xl bg-transparent backdrop-blur-none"
        no-overlay>
        <SidebarMenuItem :class="{ 'bg-sidebar-accent rounded-xl': isActive }">
            <ResourceItem :item="project">
                <Collapsible :open="isActive" class="group/collapsible" as-child>
                    <SidebarMenuButton :tooltip="project.name" @click="openProject" size="lg">
                        <ResourceIcon :stroke-width="isActive ? 2.5 : 2" />
                        <ResourceName :class="{ 'font-semibold': isActive }" />
                    </SidebarMenuButton>
                    <CollapsibleContent>
                        <SidebarMenuSub class="border-none -ml-0 pb-2 mr-1">
                            <SidebarMenuSubItem>
                                <ResourceItem :item="project">
                                    <SidebarMenuSubButton @click="openProject" :is-active="isOverviewActive">
                                        <Layers :stroke-width="isOverviewActive ? 2.5 : 2" />
                                        <span>Overview</span>
                                    </SidebarMenuSubButton>
                                </ResourceItem>
                            </SidebarMenuSubItem>
                            <SidebarMenuSubItem>
                                <ResourceItem :item="project">
                                    <LibraryButton :project="project" sub-button />
                                </ResourceItem>
                            </SidebarMenuSubItem>
                        </SidebarMenuSub>
                    </CollapsibleContent>
                </Collapsible>
            </ResourceItem>
        </SidebarMenuItem>
    </Dropzone>
</template>