<script setup>
import { Run } from '../components'
import { CommandInput } from '@/components/command-input'
import { Loading } from '@/components/loading';
import { useThread } from '../composables'
import { onMounted, computed, ref, toValue, watch } from 'vue'
import { MainLayout, NavbarLayout } from '@/layouts'
import { ResourceBreadcrumb } from '@/components/resources'
import { useRoute } from 'vue-router'
import { LibraryService } from '@/services/library'
import { useKVStore } from '@/stores'
import { toast } from 'vue-sonner'
import { useCompletions } from '@/composables'
import { useRepo, PromptTemplate } from '@/models'
import { useQuery } from '@tanstack/vue-query'

const route = useRoute()
const kvStore = useKVStore()
const threadId = computed(() => route.params?.threadId ?? null)
const templateId = computed(() => route.query?.templateId ?? null)

const { thread, isLoading: isThreadLoading, startRun, saveThread } = useThread(threadId)
const { prompt, completion } = useCompletions()

const attachments = ref([])
const { data: template, isLoading: isTemplateLoading } = useQuery({
    queryKey: ['thread', templateId],
    queryFn: async () => {
        if (!toValue(templateId) || thread.value?.runs?.length > 0) return null
        const response = await useRepo(PromptTemplate).api().get(`/threads/templates/${toValue(templateId)}`)
        return response.entities[0]
    }
})

const defaultOpen = computed(() => {
    if (thread?.runs?.length) {
        return false
    }
    return true
})

const defaultMode = computed(() => {
    if (thread.value?.runs?.length) {
        return thread.value.runs[thread.value.runs.length - 1].mode
    }
    if (template.value) {
        return template.value?.mode
    }
    if (route.query?.mode) {
        return route.query.mode
    }
    return 'review'
})

const isLoading = computed(() => {
    return isThreadLoading.value || isTemplateLoading.value
})


async function onSubmit(e) {
    const result = startRun(e)
    if (!toValue(thread).runs.length && (toValue(thread).name === 'New Chat' || toValue(thread).name === 'Untitled')) {
        prompt.value = `Write a short concise descriptive title (in the language of the prompt) for a thread with the following prompt (dont use quotes): PROMPT: ${e.prompt.value}`
    }
    await result
}


async function loadAttachmentsFromCache() {
    const items = kvStore.get(`thread-${toValue(threadId)}-items`)
    if (items) {
        toast.info('Loading attachments...')
        attachments.value = await LibraryService.listDescendants(items, { entities: ['document'] })
        if (attachments.value.length > 0) toast.success('Attachments loaded')
        kvStore.remove(`thread-${toValue(threadId)}-items`)
    }
}

watch(threadId, async () => {
    attachments.value = []
    await loadAttachmentsFromCache()
})

watch(completion, async (newCompletion) => {
    if (newCompletion) {
        toValue(thread).name = newCompletion
        await saveThread()
    }
})

onMounted(loadAttachmentsFromCache)
</script>

<template>
    <MainLayout>
        <template #navbar>
            <NavbarLayout>
                <template #content>
                    <ResourceBreadcrumb :folder-id="thread?.parent_id" :project-id="thread?.project_id"
                        :resource="thread" />
                </template>
            </NavbarLayout>
        </template>
        <template #content>
            <Loading class="h-full" v-if="isLoading" />
            <div v-else-if="thread?.runs.length > 0" class="flex flex-col h-full">
                <div class="flex flex-col flex-grow space-y-4 bg-transparent max-w-[55svw] w-full mx-auto"
                    :class="{ 'overflow-hidden 2xl:overflow-visible': shortcutOpen && !shortcut }">
                    <Run v-for="(run, i) in thread?.runs" :key="run.id" :run="run" :i="i"
                        :is-last="i == thread?.runs.length - 1" @submit="onSubmit" />
                </div>
            </div>
        </template>
        <template #footer>
            <div class="bg-background">
                <div class="mx-auto">
                    <div class="pb-2 max-w-[55svw] mx-auto">
                        <CommandInput v-if="!isLoading" v-model:attachments="attachments" :default-mode="defaultMode"
                            :default-shortcut="template" :default-open="defaultOpen" :is-running="thread.isRunning"
                            :strict="!thread?.runs?.length" @submit="onSubmit" />
                    </div>
                </div>
            </div>
        </template>
    </MainLayout>
</template>