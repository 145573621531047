import { useQuery } from '@tanstack/vue-query'
import { useRepo } from '@/models'
import { PromptTemplate } from '@/services/threads/models'
import { toValue, computed } from 'vue'
import { TemplateService } from '@/services/templates/service'
import { toast } from 'vue-sonner'

export function usePromptTemplate(template) {
    const templateId = computed(() => toValue(template).id);
    const { data, isLoading } = useQuery({
        queryKey: ['templates', 'prompts', templateId],
        queryFn: async () => {
            const response = await useRepo(PromptTemplate).api().get(`/threads/templates/${toValue(templateId)}`)
            return useRepo(PromptTemplate).withAllRecursive().find(response.entities[0].id)
        }
    })

    const saveTemplate = async (data) => {
        await useRepo(PromptTemplate).api().patch(
            `/threads/templates/${toValue(templateId)}`,
            data
        )
        toast.success('Template saved successfully')
    }

    return {
        template: data,
        isLoading,
        saveTemplate,
        deleteTemplate: async () => await TemplateService.deleteTemplate(template)
    }
}