import { Resource } from './ResourceHierarchy'

export class Folder extends Resource {
    static entity = 'folder'
    static baseEntity = 'resource'

    static fields() {
        return {
            ...super.fields(),
            type: this.string('FOLDER'),
            icon: this.string('Folder'),
            color: this.string('primary'),
        }
    }
}

export default Folder;
