<script setup>
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import ColumnForm from './ColumnForm'
import { defineProps, ref } from 'vue'
import { Plus } from 'lucide-vue-next'

const props = defineProps({
    onSubmit: {
        type: Function,
        required: false
    },
    disabled: {
        type: Boolean,
        required: false
    }
})

const isOpen = ref(false)

const onSubmit = async (data) => {
    await props.onSubmit?.(data)
    isOpen.value = false
}
</script>

<template>
    <Popover :modal="true" :open="isOpen">
        <div class="flex items-center w-full h-full">
            <PopoverTrigger asChild>
                <Button :disabled="disabled" @click="isOpen = !isOpen" size="xs" variant="link">
                    <Plus class="h-4 w-4 mr-1" />
                    <span>Add Column</span>
                </Button>
            </PopoverTrigger>
        </div>
        <PopoverContent class="w-full items-center mx-auto">
            <ColumnForm @submit="onSubmit" @cancel="isOpen = false" />
        </PopoverContent>
    </Popover>
</template>
