
import { useRepo } from '@/models'
import { Sheet, Row, Column, Cell } from '@/services/sheets/models'
import { onMounted, watch, onUnmounted, toValue } from 'vue'
import { useSocket } from '@/composables'
import { SheetService } from '../service'
import { debounce } from 'lodash'

export function useSheetSocket(sheet) {
    const socket = useSocket('sheets')

    onMounted(async () => {
        const promises = [
            socket.on('sheet', (data) => useRepo(Sheet).save(data)),
            socket.on('row', (data) => useRepo(Row).save(data)),
            socket.on('column', (data) => useRepo(Column).save(data)),
            socket.on('cell', (data) => {
                const debouncedSave = debounce((data) => useRepo(Cell).save(data), 300)
                debouncedSave(data)
            }),
        ]
        if (toValue(sheet)?.id) promises.push(SheetService.enterSheet({ socket, sheet }))
        await Promise.all(promises)
    })

    watch(sheet, async (newSheet, oldSheet) => {
        const newSheetId = toValue(newSheet)?.id
        const oldSheetId = toValue(oldSheet)?.id
        if (newSheetId !== oldSheetId) {
            if (newSheetId) await SheetService.enterSheet({ socket, sheet: newSheet })
            if (oldSheetId) await SheetService.leaveSheet({ socket, sheet: oldSheet })
        }
    }, { immediate: true, deep: true })

    onUnmounted(async () => {
        if (toValue(sheet)?.id) await SheetService.leaveSheet({ socket, sheet })
    })

    return socket
}