import { useRepo, Project } from '@/models';
import { Resource } from './models'
import { useUpload } from '@/composables';
import { toValue, computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { toast } from 'vue-sonner';



export async function move(target, items) {
    let projectId;
    if (target !== null && !(target?.type === 'FOLDER' || target instanceof Project)) {
        projectId = target?.project_id || target?.parent?.project_id || null;
        target = target?.parent || null;
    }

    if (target === undefined || !items || items.includes(target)) return;

    let payload = { parent_id: target?.id || null, project_id: target?.project_id || null };
    if (target instanceof Project) {
        payload = { project_id: target?.id || null };
    }
    if (target === null) {
        payload = { parent_id: null, project_id: projectId };
    }
    await useRepo(Resource).api().post('/library/move', {
        resource_ids: items.map(item => item.id),
        ...payload
    })
}

export async function copy(target, items, resourceTypes = ['sheet', 'thread', 'document']) {
    let projectId;
    if (target !== null && !(target?.type === 'FOLDER' || target instanceof Project)) {
        projectId = target?.project_id || target?.parent?.project_id || null;
        target = target?.parent || null;
    }

    if (target === undefined || !items || items.includes(target)) return;

    let payload = { parent_id: target?.id || null, project_id: target?.project_id || null };
    if (target instanceof Project) {
        payload = { project_id: target?.id || null };
    }
    if (target === null) {
        payload = { parent_id: null, project_id: projectId };
    }
    await useRepo(Resource).api().post('/library/copy', {
        resource_ids: items.map(item => item.id),
        ...payload,
        resource_types: resourceTypes
    })
}

async function save(target) {
    try {
        const type = toValue(target)?.type?.toLowerCase() || 'project';
        const response = await useRepo(type === 'project' ? Project : Resource)
            .api()
            .patch(`/${type}s/${target.id}`, toValue(target));
        toast.success(`Successfully updated '${response.entities[0].name}'`);
    } catch (error) {
        console.error(error);
        toast.error(`Failed to update '${target.name}'`);
    }
}

async function create(projectId, folderId, type, { name = 'New ' + type } = {}) {
    try {
        toast.info('Creating ' + type);
        if (type === 'thread') {
            name = 'New Chat';
        }
        const response = await useRepo(Resource).api().post('/' + type + 's/', {
            name: name,
            project_id: toValue(projectId),
            [type === 'folder' ? 'parent_id' : 'folder_id']: toValue(folderId)
        });
        toast.success('Successfully created ' + type);
        return response.entities[0];
    } catch (error) {
        console.error(error);
        toast.error('Failed to create ' + type);
    }
}

async function destroy(target) {
    try {
        await useRepo(Resource)
            .api()
            .delete(`/${toValue(target).type.toLowerCase()}s/${toValue(target).id}`, { delete: toValue(target).id });
        toast.success(`Successfully deleted '${toValue(target).name}'`);
    } catch (error) {
        console.error(error);
        toast.error(`Failed to delete '${toValue(target).name}'`);
    }
}

async function handleDrop(item, event, action = 'move') {
    try {
        if (event.dataTransfer.files.length > 0) {
            toast.info('Uploading files...');
            const { upload } = useUpload(
                item instanceof Project ? item.id : item?.project_id || null, 
                item?.type === 'FOLDER' ? item?.id : item?.parent_id || null
            );
            await upload(event.dataTransfer.items);
            toast.success('Files uploaded successfully');
        } else {
            const items = JSON.parse(event.dataTransfer.getData('text/plain'));
            if (action === 'move') {
                toast.info('Moving items...');
                await move(item, items);
            } else if (action === 'copy') {
                toast.info('Copying items...');
                await copy(item, items);
            } else {
                throw new Error('Invalid action');
            }
            toast.success(`Successfully ${action}ed ${items.length} item${items.length === 1 ? '' : 's'}`);
        }
    } catch (error) {
        console.error(error);
        toast.error('Failed to process dropped items');
    }
}

export function listResources(projectId, parentId, depth = 1) {
    const { isLoading, refetch } = useQuery({
        queryKey: ['library', 'children', toValue(parentId), toValue(projectId), depth],
        queryFn: async () => {
            const response = await useRepo(Resource).api().get('/library/', { params: { parent_id: toValue(parentId), project_id: toValue(projectId), depth }})
            return response.entities;
        }
    })
    return {
        isLoading,
        children: computed(() => useRepo(Resource).withAllRecursive().where('parent_id', toValue(parentId)).where('project_id', toValue(projectId)).get()),
        refetch
    }
}


export async function listDescendants(resources, { entities = ['document'] } = {}) {
    const response = await useRepo(Resource).api().get('/library/descendants', { params: { resource_ids: toValue(resources).map(resource => toValue(resource).id) }, paramsSerializer: { indexes: null } })
    const deduplicated = Array.from(new Map([...toValue(resources), ...toValue(response.entities)].map(resource => [toValue(resource).id, toValue(resource)])).values())
    return deduplicated.filter(resource => entities.includes(toValue(resource).type.toLowerCase()));
}

export async function listParents(resources) {
    const response = await useRepo(Resource).api().get('/library/parents', { params: { resource_ids: toValue(resources).map(resource => toValue(resource).id) }, paramsSerializer: { indexes: null } })
    return Array.from(new Map([...toValue(resources), ...toValue(response.entities)].map(resource => [toValue(resource).id, toValue(resource)])).values())
}

export async function minimalSubTree(resources) {
    if (toValue(resources).length === 0) return []
    const response = await useRepo(Resource).api().get('/library/minimal-subtree', { params: { resource_ids: toValue(resources).map(resource => toValue(resource).id) }, paramsSerializer: { indexes: null } })
    return Array.from(new Map([...toValue(resources), ...toValue(response.entities)].map(resource => [toValue(resource).id, toValue(resource)])).values())
}


export const LibraryService = {
    move,
    copy,
    save,
    create,
    destroy,
    handleDrop,
    listResources,
    minimalSubTree,
    listDescendants,
    listParents
}