<script setup>
import { defineProps } from 'vue'
import { Paperclip, ChevronDown, FileIcon } from 'lucide-vue-next'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
    DropdownMenuItem,
} from '@/components/ui/dropdown-menu'
import { Badge } from '@/components/ui/badge'
import { useSidepanel } from '@/components/sidepanel'

defineProps({
    run: {
        type: Object,
        required: true
    }
})
const sidepanel = useSidepanel()
</script>

<template>
    <div class="flex flex-col px-4 py-2 w-fit max-w-[75%] ml-auto text-left items-start space-y-1">
        <DropdownMenu v-if="run.attachments.length > 0">
            <DropdownMenuTrigger>
                <Badge variant="outline" class="flex items-center gap-1 cursor-pointer hover:bg-accent">
                    <Paperclip class="w-3.5 h-3.5 flex-shrink-0" />
                    <span>{{ run.attachments.length }} attachment{{ run.attachments.length > 1 ? 's' : '' }}</span>
                    <ChevronDown class="w-3.5 h-3.5 flex-shrink-0" />
                </Badge>
            </DropdownMenuTrigger>
            <DropdownMenuContent class="w-64">
                <DropdownMenuItem v-for="attachment in run.attachments" :key="attachment.id"
                    @click="sidepanel.addTab(attachment)" class="cursor-pointer">
                    <FileIcon class="w-3.5 h-3.5 flex-shrink-0" />
                    <span class="truncate">{{ attachment.name }}</span>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
        <div class="rounded-xl px-4 py-2 w-fit text-left bg-accent items-start">
            <p class="prose" :style="{ 'font-family': 'Inter, sans-serif', 'font-size': '0.98rem !important' }">
                {{ run.prompt }}
            </p>
        </div>
    </div>
</template>
