import { Model } from 'pinia-orm'
import { DateCast } from '@/models/utils'

export class Case extends Model {
    static entity = 'cases'

    static fields() {
        return {
            id: this.attr(null),
            coverage: this.string(''),
            kind: this.string(''),
            number: this.string(''),
            procedure: this.string(''),
            publication_date: this.attr(null),
            decision_date: this.attr(null),
            language: this.string(''),
            title: this.string(''),
            summary: this.string(''),
            text: this.string(''),
            origin: this.string(''),
            areas_of_law: this.attr(null),
            body_of_government: this.attr(null),
            references: this.attr(null),
            relates_to: this.attr(null),
        }
    }

    get name() {
        return this.id
    }

    get display() {
        return this.preview
    }

    get preview() {
        return {
            title: this.id.split('#')[0],
            description: this.kind + ' ' + new Date(this.decision_date).toLocaleDateString() + ' ' + this.body_of_government?.name,
            summary: this.summary || (this.text ? this.text.slice(0, 250) + '...' : 'No summary available'),
            author: this.body_of_government?.name,
            tags: this.areas_of_law?.map(area => area.name)
        }
    }

    get pageContent() {
        return this.text
    }

    set pageContent(value) {
        this.text = value
    }

    static casts() {
        return {
            publication_date: DateCast,
            decision_date: DateCast
        }
    }
}
