<script setup>
import { TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '@/components/ui/dropdown-menu';
import { ChevronDown, X } from 'lucide-vue-next';
import { useSidepanel } from './use-sidepanel';
import { storeToRefs } from 'pinia';

const sidepanel = useSidepanel();
const { tabs, shownTabs, hiddenTabs } = storeToRefs(sidepanel);


</script>


<template>
    <TabsList class="gap-1 bg-background justify-start min-w-0 !max-w-full py-0 my-0">
        <TabsTrigger v-for="tab in shownTabs" :key="tab.id"
            class="relative group select-text font-normal w-full min-w-0 hover:bg-muted data-[state=active]:bg-accent data-[state=active]:text-accent-foreground data-[state=active]:border-none data-[state=active]:shadow-none"
            :value="tab.id" :class="{ 'data-[state=active]:bg-accent': tabs.length === 1 }">

            <span class="text-sm truncate w-full pr-1">{{ tab.name }}</span>
            <Button v-if="tabs.length > 1" @click.stop="sidepanel.removeTab(tab)" variant="ghost" size="icon"
                class="absolute right-1 size-3.5 text-muted-foreground hover:text-primary hover:bg-transparent hover:border-transparent shrink-0 opacity-0 group-hover:opacity-100 transition-opacity">
                <X />
            </Button>

        </TabsTrigger>
        <DropdownMenu v-if="hiddenTabs.length > 0">
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="sm" class="h-6 text-muted-foreground w-fit">
                    <span class="text-xs">{{ hiddenTabs.length }} more</span>
                    <ChevronDown class="shrink-0 ml-2 -mr-2 size-3.5" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                <DropdownMenuItem @click="sidepanel.focusTab(tab)" class="cursor-pointer w-full justify-between"
                    v-for="tab in hiddenTabs" :key="tab.id" :value="tab.id">
                    <span class="text-xs">{{ tab.name }}</span>
                    <Button @click.stop="sidepanel.removeTab(tab)" variant="ghost" size="icon"
                        class="ml-2 size-3.5 text-muted-foreground hover:text-destructive">
                        <X />
                    </Button>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    </TabsList>
</template>