import { cva } from "class-variance-authority";

export { default as Badge } from "./Badge.vue";

export const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-background border border-destructive/40 text-destructive/80 hover:bg-destructive/30",
        outline: "text-foreground",
        accent: "bg-accent text-accent-foreground hover:bg-accent/80",
        pro: "bg-muted text-gradient gradient-border",
        assistant: "dark:bg-blue-900/30 dark:text-blue-500 dark:border-blue-800 bg-blue-100/30 text-blue-900 border border-blue-100",
        sheet: "dark:bg-teal-900/30 dark:text-teal-600 dark:border-teal-800 bg-green-100/30 text-teal-700 border border-green-500/10",
        beta: "dark:bg-purple-900 dark:text-purple-500 dark:border-purple-800 bg-purple-100/30 text-purple-500 border border-purple-200",
        label: "dark:bg-gray-900/30 dark:text-gray-500 dark:border-gray-800 bg-gray-200 text-gray-600 border border-gray-300",

      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);
