import { ref, computed, toValue, onMounted } from 'vue'
import { LibraryService } from '@/services/library/service'

export function useSelectable(items, selectedItems, { multiple = true, selectMode = 'replace', propagateSelection = false } = {}) {
    const lastSelected = ref(null)

    const getAllChildren = (item) => {

        if (!item?.children?.length) return [];
        
        // Use a recursive approach to get all descendants at any depth
        const allChildren = [...item.children];
        
        // Recursively add all descendants
        for (const child of item.children) {
            const childDescendants = getAllChildren(child);
            allChildren.push(...childDescendants);
        }
        
        return allChildren;
    };

    function toggleSelect(item, event) {
        item = toValue(item)
        if (!multiple) {
            if (selectedItems.value.includes(item)) {
                selectedItems.value = []
                lastSelected.value = null
            } else {
                selectedItems.value = [item]
                lastSelected.value = item
            }
            return
        }
        
        const isCtrlPressed = event.metaKey || event.ctrlKey
        const isShiftPressed = event.shiftKey
        
        // Helper function to deduplicate selection
        const deduplicateSelection = (items) => {
            return Array.from(new Map(items.map(i => [toValue(i).id, toValue(i)])).values())
        }

        if (isShiftPressed && lastSelected.value) {
            const currentIndex = toValue(items).findIndex(i => i.id === item.id)
            const lastIndex = toValue(items).findIndex(i => i.id === lastSelected.value.id)
            const [start, end] = [Math.min(currentIndex, lastIndex), Math.max(currentIndex, lastIndex)]
            
            // Get the range of items and combine with existing selection
            const rangeItems = toValue(items).slice(start, end + 1)
            selectedItems.value = deduplicateSelection([...toValue(selectedItems), ...rangeItems])
            lastSelected.value = item
        } else if (isCtrlPressed) {
            const isItemSelected = selectedItems.value.some(i => i.id === item.id)
            if (isItemSelected) {
                // If deselecting a parent, also deselect all its children
                const childrenToRemove = propagateSelection ? getAllChildren(item).map(child => child.id) : [];
                selectedItems.value = selectedItems.value.filter(i => 
                    i.id !== item.id && !childrenToRemove.includes(i.id)
                );
            } else {
                // If selecting a parent, also select all its children
                const children = propagateSelection ? getAllChildren(item) : [];
                selectedItems.value = deduplicateSelection([...selectedItems.value, item, ...children]);
            }
            lastSelected.value = item
        } else if (item?.id !== lastSelected.value?.id && selectMode === 'replace') {
            // If replacing with a parent, include all its children
            const children = propagateSelection ? getAllChildren(item) : [];
            selectedItems.value = deduplicateSelection([item, ...children]);
            lastSelected.value = item
        } else if (selectMode === 'toggle') {
            if (!isSelected(item)) {
                // If selecting a parent, also select all its children
                const children = propagateSelection ? getAllChildren(item) : [];
                selectedItems.value = deduplicateSelection([...selectedItems.value, item, ...children]);
                lastSelected.value = item
            } else {
                // If deselecting a parent, also deselect all its children
                const childrenToRemove = propagateSelection ? getAllChildren(item).map(child => child.id) : [];
                selectedItems.value = selectedItems.value.filter(i => 
                    i.id !== item.id && !childrenToRemove.includes(i.id)
                );
                lastSelected.value = null
            }
        } else {
            selectedItems.value = []
            lastSelected.value = null
        }
    }

    function toggleSelectAll() {
        if (isAllSelected.value === true) {
            selectedItems.value = []
        } else {
            const allItems = [...items.value, ...items.value.flatMap(item => propagateSelection ? getAllChildren(item) : [])]
            selectedItems.value = Array.from(new Map(allItems.map(i => [toValue(i).id, toValue(i)])).values())
        }
    }

    function isSelected(item) {
        item = toValue(item)
        
        // For folders, check if the folder itself is selected
        if (item?.type === 'FOLDER') {
            const folderSelected = selectedItems.value.some(i => i.id === item.id)
            
            // If the folder itself is selected, return true
            if (folderSelected) return true
            
            // If the folder isn't selected but has children, check if all children are selected
            if (item?.children?.length && propagateSelection) {
                return item.children.every(child => isSelected(child))
            }
            
            return false
        }
        
        // For non-folder items, simply check if the item is in the selection
        return selectedItems.value.some(i => i.id === item.id)
    }

    const isAllSelected = computed(() => {
        if (items.value.length === 0) {
            return false
        }
        if (items.value.every(item => isSelected(item))) {
            return true
        }
        if (selectedItems.value.length > 0) {
            return 'indeterminate'
        }
        return false
    })

    onMounted(async () => {
        await LibraryService.minimalSubTree(selectedItems.value)
    })

    return {
        selectedItems,
        toggleSelect,
        toggleSelectAll,
        isSelected,
        isAllSelected
    }
}