// stores/kvStore.js
import { ref, computed, onUnmounted } from 'vue'
import { defineStore } from 'pinia'

export const useKVStore = defineStore('kvStore', () => {
  // State
  const storage = ref({})  // Will store {value, expiresAt} objects
  const lastUpdated = ref(null)
  const totalEntries = ref(0)
  let expirationCheckInterval = null

  // Setup expiration checker on store creation
  const startExpirationChecker = () => {
    expirationCheckInterval = setInterval(() => {
      const now = new Date()
      const keysToDelete = Object.entries(storage.value)
        .filter((row) => row[1].expiresAt && row[1].expiresAt <= now)
        .map((row) => row[0])
      
      keysToDelete.forEach(key => remove(key))
    }, 1000) // Check every second
  }

  // Cleanup on store disposal
  onUnmounted(() => {
    if (expirationCheckInterval) {
      clearInterval(expirationCheckInterval)
    }
  })

  startExpirationChecker()

  // Getters
  const keys = computed(() => Object.keys(storage.value))
  const values = computed(() => Object.values(storage.value).map(item => item.value))
  const entries = computed(() => 
    Object.entries(storage.value).map(([key, item]) => [key, item.value])
  )
  const size = computed(() => totalEntries.value)

  // Actions
  function set(key, value, ttlSeconds = 60) {
    const expiresAt = ttlSeconds ? new Date(Date.now() + ttlSeconds * 1000) : null
    storage.value[key] = { value, expiresAt }
    lastUpdated.value = new Date()
    totalEntries.value = Object.keys(storage.value).length
  }

  function get(key) {
    const item = storage.value[key]
    if (!item) return undefined
    
    if (item.expiresAt && item.expiresAt <= new Date()) {
      remove(key)
      return undefined
    }
    
    return item.value
  }

  function has(key) {
    return key in storage.value
  }

  function remove(key) {
    if (has(key)) {
      delete storage.value[key]
      lastUpdated.value = new Date()
      totalEntries.value = Object.keys(storage.value).length
      return true
    }
    return false
  }

  function clear() {
    storage.value = {}
    lastUpdated.value = new Date()
    totalEntries.value = 0
  }

  function setMany(entries, ttlSeconds = 60) {
    const expiresAt = ttlSeconds ? new Date(Date.now() + ttlSeconds * 1000) : null
    for (const [key, value] of Object.entries(entries)) {
      storage.value[key] = { value, expiresAt }
    }
    lastUpdated.value = new Date()
    totalEntries.value = Object.keys(storage.value).length
  }

  return {
    // State
    storage,
    lastUpdated,
    totalEntries,
    
    // Getters
    keys,
    values,
    entries,
    size,
    
    // Actions
    set,
    get,
    has,
    remove,
    clear,
    setMany
  }
})
