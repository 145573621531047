<script setup>
import { HoverCard, HoverCardTrigger, HoverCardContent } from '@/components/ui/hover-card';
import { Badge } from '@/components/ui/badge';
import { defineProps, computed } from 'vue';
import { useRepo, Case, Document, Consolidation } from '@/models'
import { useSidepanel } from '@/components/sidepanel'
import { CaseIcon, RegulationIcon } from '@/components/icons'
import { FileIcon, ArrowUpRight } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'

const props = defineProps({
    order: {
        type: Number,
        required: true
    },
    id: {
        type: String,
        required: true
    },
    type: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    excerpt: {
        type: String,
        required: true
    }
})

const { addTab } = useSidepanel()

const icon = computed(() => {
    switch (props.type) {
        case 'case': return CaseIcon;
        case 'regulation': return RegulationIcon;
        case 'document': return FileIcon;
        default: return null;
    }
})

async function open() {
    let response;
    switch (props.type) {
        case 'case': response = await useRepo(Case).api().get(`/legal/cases/${props.id}`, {
            dataTransformer: (response) => {
                response.data.id = response.data.uid
                return response.data
            }
        }); break;
        case 'regulation': response = await useRepo(Consolidation).api().get(`/legal/regulations/consolidations/${props.id}`, {
            dataTransformer: (response) => {
                response.data.id = response.data.uid
                return response.data
            }
        }); break;
        case 'document': response = await useRepo(Document).api().get(`/documents/${props.id}`); break;
    }
    addTab({ ...response.entities[0], pageContent: response.entities[0].pageContent, selectedText: props.excerpt })
}
</script>

<template>
    <HoverCard v-if="props.type" :openDelay="0" :closeDelay="10">
        <HoverCardTrigger asChild>
            <Badge @click="open" variant="outline"
                class="cursor-pointer bg-backgroundSecondary hover:bg-accent w-fit h-5 px-1.5 -py-1 -my-1">
                <component :is="icon" class="h-3 w-3 mr-1" />
                <p class="font-medium">{{ order + 1 }}</p>
            </Badge>
        </HoverCardTrigger>
        <HoverCardContent class="w-80 rounded-lg pb-2">
            <div class="flex flex-col justify-between space-y-1">
                <h4 class="text-sm font-semibold">
                    {{ title }}
                </h4>
                <p class="prose text-sm leading-tight break-words line-clamp-5 w-full">
                    {{ excerpt }}
                </p>
                <div class="flex flex-row justify-between items-center space-x-2 w-full">
                    <Badge @click="open" variant="outline"
                        class="cursor-pointer bg-backgroundSecondary hover:bg-accent mr-1 w-fit h-fit">
                        <component :is="icon" class="h-3 w-3 mr-1" />
                        <p>{{ order + 1 }}</p>
                    </Badge>
                    <Button @click="open" variant="link" size="xs"
                        class="flex flex-row gap-1 items-center text-muted-foreground pr-0">
                        <span>Show more</span>
                        <ArrowUpRight class="h-4 w-4" />
                    </Button>
                </div>
            </div>
        </HoverCardContent>
    </HoverCard>
</template>