<script setup>
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover';
import ColumnForm from './ColumnForm.vue';
import { ref, defineProps, toRefs } from 'vue';

const open = ref(false);

const props = defineProps({
    column: {
        type: Object,
        required: false,
        default: null
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    }
})

const { column } = toRefs(props);
</script>

<template>
    <Popover v-model:open="open">
        <PopoverTrigger>
            <slot />
        </PopoverTrigger>
        <PopoverContent class="w-full">
            <ColumnForm @submit="$emit('submit', { ...(column ?? {}), ...$event }); open = false" @cancel="open = false"
                @delete="$emit('delete'); open = false" :default-value="column" :disabled="disabled" />
        </PopoverContent>
    </Popover>
</template>