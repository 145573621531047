import { useSheetSocket } from './use-sheet-socket';
import { SheetService } from '../service';
import { useCompletions } from '@/composables';
import { watch } from 'vue';

export function useSheet(sheetId) {
    const {
        sheet,
        isLoading
    } = SheetService.getSheet(sheetId)

    const socket = useSheetSocket(sheet)
    const { prompt, completion, isLoading: isGenerating } = useCompletions()

    watch(completion, async (newCompletion) => {
        if (newCompletion) {
            sheet.name = newCompletion
            await SheetService.updateSheet(sheet)
        }
    })

    watch(isGenerating, async (newIsGenerating) => {
        if (!newIsGenerating) {
            await SheetService.updateSheet(sheet)
        }
    })

    function generateTitle(attachments) {
        prompt.value = `Write a short consise descriptive title (in the language of the document titles) for a sheet with the following documents (dont use quotes): ${attachments.map(doc => doc.name).join(', ')}. You should try to infer a general theme for the title since a sheet is an analysis of multiple documents.`
    }

    return {
        sheet,
        isLoading,
        addColumn: async (...columnSpecs) => await SheetService.addColumn({ socket, sheet, columnSpecs }),
        updateColumn: async (columnNumber, columnSpec) => await SheetService.updateColumn({ socket, sheet, columnNumber, columnSpec }),
        removeColumn: async (column) => await SheetService.removeColumn({ sheet, column }),
        addRow: async (...attachments) => await SheetService.addRow({ socket, sheet, attachments }),
        removeRow: async (row) => await SheetService.removeRow({ sheet, row }),
        saveSheet: async () => await SheetService.updateSheet(sheet),
        deleteSheet: async () => await SheetService.deleteSheet(sheet),
        exportSheet: (name = null) => SheetService.exportToExcel({ sheet, name }),
        saveAsTemplate: async (template = null) => await SheetService.saveAsTemplate({ sheet, template }),
        applyTemplate: async (template) => await SheetService.applyTemplate({ socket, sheet, template }),
        generateTitle,
        runCells: async (...cellIds) => await SheetService.runCells({ socket, sheet, cellIds })
    }
}