<script setup>
import { defineProps, computed, defineModel, defineEmits, toRefs } from 'vue'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/components/ui/table'
import { TextCursorInput, UserIcon, Clock, Users, Loader2 } from 'lucide-vue-next'
import { cn } from '@/lib/utils'
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar'
import { Checkbox } from '@/components/ui/checkbox'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '@/components/ui/select'
import { CollapsibleSearch } from '@/components/controls'
import { useSortable, SortTrigger } from '@/components/behaviour/sortable';
import { Selectable, SelectableItem } from '@/components/behaviour/selectable';
import { ResourceItem, ResourceIcon, ResourceName } from '@/components/resources';
import { Draggable, DraggableItem } from '@/components/behaviour/draggable';
import { useFilterable } from '@/composables';
import { Progress } from '@/components/ui/progress';
import { ref } from 'vue'

const emit = defineEmits(['drop', 'dblclick', 'long-dragover'])

const props = defineProps({
    items: {
        type: Array,
        required: true
    },
    timeColumnOptions: {
        type: Array,
        required: false,
        default: () => [
            { key: 'created_at', label: 'Created' },
            { key: 'updated_at', label: 'Last updated' }
        ]
    },
    isLoading: {
        type: Boolean,
        required: false,
        default: false
    },
    selectable: {
        type: [Boolean, String],
        required: false,
        default: false
    },
    multiple: {
        type: Boolean,
        required: false,
        default: false
    },
    draggable: {
        type: Boolean,
        required: false,
        default: false,
    },
    contextMenu: {
        type: Boolean,
        required: false,
        default: false
    },
    searchable: {
        type: Boolean,
        required: false,
        default: false
    },
    filters: {
        type: Object,
        required: false,
        default: () => ({})
    },
    root: {
        type: Object,
        required: false,
        default: null
    },
    propagateSelection: {
        type: Boolean,
        required: false,
        default: false
    }
})

const { items, timeColumnOptions } = toRefs(props)

const width = {
    checkbox: 'w-10',
    name: 'w-[60%]',
    owner: 'w-[20%]',
    time: 'w-[20%]'
}
const query = defineModel('query', {
    type: String,
    required: false,
    default: ''
})
const selectedItems = defineModel('selectedItems', {
    type: Array,
    required: false,
    default: () => []
})
const timeColumn = defineModel('timeColumn', {
    type: String,
    required: false,
    default: undefined
})

const timeColumnKey = computed(() => timeColumnOptions.value.find(option => option.label === timeColumn.value).key)

const { filteredItems } = useFilterable(items, {
    name: (item) => item.name.toLowerCase().includes(query.value.toLowerCase()),
    owner: (item) => props.filters?.members?.includes(item.owner) || !props.filters?.members?.length,
    type: (item) => [props.filters?.type, 'folders'].includes(item.type.toLowerCase() + 's') || props.filters?.type === 'all' || !props.filters?.type,
    dateRange: (item) => {
        const { start, end } = props.filters?.dateRange || { start: null, end: null }
        return (!start || item[timeColumnKey.value] >= start.toDate()) && (!end || item[timeColumnKey.value] <= end.toDate())
    }
})
const { sortedItems } = useSortable(filteredItems, {
    defaults: {
        by: 'time',
        dir: 'desc'
    },
    metrics: {
        name: (a, b) => a.name.localeCompare(b.name),
        time: (a, b) => a[timeColumnKey.value].getTime() - b[timeColumnKey.value].getTime()
    }
})

const isDraggedOver = ref(false)
</script>

<template>
    <Selectable v-model:selected-items="selectedItems" :propagate-selection="propagateSelection"
        v-slot="{ toggleSelectAll, isAllSelected }" :items="sortedItems"
        :select-mode="selectable == 'checkbox' ? 'toggle' : 'replace'" :multiple="multiple">
        <Draggable v-slot="{ isDragging }" v-model="selectedItems" :draggable="draggable" :root="root"
            @drop="(item, event) => emit('drop', item, event)">
            <div class="relative h-full">
                <Table :borderClass="cn([$attrs.class, '!overflow-visible h-full w-full'])">
                    <TableHeader class="sticky top-0 z-10 bg-background">
                        <TableRow>
                            <TableHead v-if="selectable === 'checkbox'" @click.stop="toggleSelectAll" role="checkbox"
                                class="w-15 items-center">
                                <Checkbox :checked="isAllSelected" class="border-muted-foreground" />
                            </TableHead>
                            <TableHead :class="width.name">
                                <div class="flex items-center gap-2">
                                    <div class="flex items-center gap-2">
                                        <TextCursorInput />
                                        <span>Name</span>
                                        <SortTrigger field="name" />
                                    </div>
                                    <CollapsibleSearch v-if="searchable" v-model="query" />
                                </div>
                            </TableHead>
                            <TableHead :class="width.owner">
                                <div class="flex items-center gap-2">
                                    <UserIcon />
                                    <span>Owner</span>
                                </div>
                            </TableHead>
                            <TableHead :class="width.time">
                                <div v-if="timeColumnOptions.length > 1" class="flex items-center">
                                    <Select v-model="timeColumn">
                                        <SelectTrigger
                                            class="w-fit h-fit px-1 py-2 gap-2 border-none bg-transparent hover:bg-accent rounded-md focus-visible:ring-0 focus:ring-offset-0 focus:ring-0 focus-visible:outline-transparent focus-visible:border-none">
                                            <Clock />
                                            <SelectValue :placeholder="timeColumn" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem v-for="column in timeColumnOptions" :key="column.key"
                                                :value="column.label">
                                                {{ column.label }}
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                    <SortTrigger field="time" />
                                </div>
                                <div v-else class="flex items-center gap-2">
                                    <Clock />
                                    <span>{{ timeColumn }}</span>
                                </div>
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <slot name="first-row" />
                        <template v-if="!isLoading">
                            <ResourceItem v-slot="{ isEditing }" :key="item.id" v-for="item in sortedItems" :item="item"
                                action-menu="context"
                                :action-menu-disabled="!contextMenu || item?.uploadStatus != 'done'"
                                :items="selectedItems">
                                <SelectableItem v-slot="{ isSelected, toggleSelect }" :item="item"
                                    :prevent-select="selectable === 'checkbox' || item?.uploadStatus != 'done' || isEditing">
                                    <DraggableItem v-slot="{ isDraggedOver }" :item="item"
                                        @long-dragover="emit('long-dragover', item)" :prevent-drag="isEditing">
                                        <TableRow
                                            @drop.prevent.stop="item?.uploadStatus == 'done' && emit('drop', item, $event)"
                                            @dblclick="item?.uploadStatus == 'done' && emit('dblclick', item)"
                                            class="group rounded"
                                            :class="{ 'bg-muted': isSelected || isDraggedOver, 'hover:bg-accent': !isDragging && item?.uploadStatus != 'pending', 'cursor-pointer': item?.uploadStatus != 'pending', 'text-muted-foreground': item?.uploadStatus == 'pending' }">
                                            <TableCell v-if="selectable === 'checkbox'" role="checkbox"
                                                :class="width.checkbox">
                                                <Checkbox :checked="isSelected" @update:checked="toggleSelect"
                                                    :disabled="item?.uploadStatus != 'done'" />
                                            </TableCell>
                                            <TableCell :class="width.name">
                                                <div class="flex items-center gap-2 select-none">
                                                    <Loader2 v-if="item?.uploadStatus == 'pending'"
                                                        class="w-4 h-4 animate-spin" />
                                                    <ResourceIcon v-else />
                                                    <ResourceName />
                                                    <Users v-if="item?.isShared"
                                                        class="!size-3 text-muted-foreground" />
                                                    <Progress
                                                        v-if="item?.uploadStatus == 'pending' && item?.children?.length"
                                                        v-model="item.uploadProgress" :max="100" class="w-24 h-3" />
                                                </div>
                                            </TableCell>
                                            <TableCell :class="width.owner">
                                                <div class="flex items-center gap-2 select-none">
                                                    <Avatar class="size-6">
                                                        <AvatarImage :src="item?.owner?.profile_picture_url" />
                                                        <AvatarFallback>{{
                                                            item?.owner?.first_name?.charAt(0) +
                                                            item?.owner?.last_name?.charAt(0)
                                                            }}</AvatarFallback>
                                                    </Avatar>
                                                    <span>{{ item?.owner?.name }}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell :class="width.time" class="select-none">
                                                {{ item[timeColumnKey].toLocaleDateString('nl-NL', {
                                                    day: 'numeric',
                                                    month: 'short',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                }) }}
                                            </TableCell>
                                        </TableRow>
                                    </DraggableItem>
                                </SelectableItem>
                            </ResourceItem>
                        </template>
                        <TableRow v-if="isLoading || !sortedItems?.length">
                            <TableCell colspan="4" class="p-0">
                                <div v-if="isLoading" class="flex items-center justify-center gap-2 py-12">
                                    <Loader2 class="animate-spin" />
                                    <span>Loading...</span>
                                </div>
                                <div v-else
                                    class="flex flex-col items-center justify-center gap-3 py-12 text-muted-foreground h-full relative"
                                    :class="{ 'border-2 border-dashed backdrop-blur-sm border-dotted border-primary/10 rounded-lg ': isDraggedOver && draggable }"
                                    @dragenter.prevent="draggable && (isDraggedOver = true)"
                                    @dragleave.prevent="draggable && (isDraggedOver = false)" @dragover.prevent
                                    @drop.prevent="draggable && (isDraggedOver = false)">
                                    <slot name="is-empty" :is-dragged-over="isDraggedOver">
                                        <span>Nothing here yet...</span>
                                    </slot>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        </Draggable>
    </Selectable>
</template>

<style scoped>
.table-cell-transition {
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        background-color 0.2s ease,
        width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
}

.backdrop-blur-sm {
    backdrop-filter: blur(8px);
}
</style>
