<script setup>
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { defineModel, defineProps, ref } from 'vue'
import { Button } from '@/components/ui/button'
import ColumnForm from './ColumnForm'

const props = defineProps({
    onSubmit: {
        type: Function,
        required: false
    },
    onDelete: {
        type: Function,
        required: false
    },
    disabled: {
        type: Boolean,
        required: false
    }
})

const column = defineModel()

const isOpen = ref(false)

const onSubmit = async (data) => {
    column.value = { ...column.value, ...data }
    await props.onSubmit?.(data)
    isOpen.value = false
}

const onDelete = async () => {
    await props.onDelete?.()
    isOpen.value = false
}
</script>

<template>
    <Popover :modal="true" :open="isOpen">
        <PopoverTrigger asChild>
            <Button :disabled="disabled" @click="isOpen = !isOpen" variant="ghost"
                class="w-full h-full px-2 m-0 rounded-none flex-grow justify-start">
                <div class="flex items-center gap-2">
                    <component :is="column.icon" v-if="column.icon" class="w-4 h-4 flex-shrink-0" />
                    <span>{{ column.label }}</span>
                </div>
            </Button>
        </PopoverTrigger>
        <PopoverContent class="w-full items-center mx-auto">
            <ColumnForm :default-value="column" @submit="onSubmit" @cancel="isOpen = false" @delete="onDelete" />
        </PopoverContent>
    </Popover>
</template>
