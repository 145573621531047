<script setup>
import { defineModel, computed, defineProps, toValue } from 'vue';
import { TrashIcon, SaveIcon, ArrowLeft, SaveAllIcon } from 'lucide-vue-next';
import { Button } from '@/components/ui/button';
import { useTemplate } from '../composables';
import { DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { SheetTemplateForm } from '@/services/sheets';
import { PromptTemplateForm } from '@/services/threads';
import { useAuth } from '@/auth'
import { Loading } from '@/components/loading';

const { organization } = useAuth()
const selectedTemplate = defineModel();

defineProps({
    onSaveAsTemplate: {
        type: Function,
        required: false
    }
})

const {
    template,
    saveTemplate,
    deleteTemplate,
    isLoading
} = useTemplate(selectedTemplate);
const disabled = computed(() => toValue(template)?.default || toValue(template)?.organization_id !== organization.id);

const header = computed(() => {
    if (toValue(template)?.type === 'SHEETTEMPLATE') {
        return 'Sheet Template';
    } else if (toValue(template)?.type === 'PROMPTTEMPLATE') {
        return 'Assistant Template';
    } else {
        return 'Template';
    }
});

const formComponent = computed(() => {
    if (toValue(template)?.type === 'SHEETTEMPLATE') {
        return SheetTemplateForm;
    } else if (toValue(template)?.type === 'PROMPTTEMPLATE') {
        return PromptTemplateForm;
    } else {
        return null;
    }
});


const isUsable = computed(() => {
    if (toValue(template)?.type === 'SHEETTEMPLATE') {
        return toValue(template)?.columns?.length > 0;
    } else if (toValue(template)?.type === 'PROMPTTEMPLATE') {
        return toValue(template)?.mode && toValue(template)?.content;
    }
    return false;
});
</script>

<template>
    <DialogHeader>
        <DialogTitle>
            <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <button class="text-sm text-primary" @click="selectedTemplate = null">
                        <ArrowLeft class="mr-2 size-5" />
                    </button>
                    <p class="text-sm">{{ header }}</p>
                </div>
            </div>
        </DialogTitle>
    </DialogHeader>
    <div class="flex flex-col h-full">
        <Loading v-if="isLoading" />
        <component v-else v-slot="{ disabled: saveDisabled }" :is="formComponent" :initial-values="template"
            :disabled="disabled" @submit="saveTemplate">
            <DialogFooter class="flex flex-row justify-between w-full items-center pt-4 mt-auto border-t">
                <div class="flex flex-row items-center space-x-2">
                    <Button v-if="!template.default" @click="deleteTemplate(); selectedTemplate = null"
                        variant="outline" size="sm">
                        <TrashIcon class="size-3 mr-2" />
                        <span class="text-sm">Delete</span>
                    </Button>
                    <Button v-if="onSaveAsTemplate && !template.default" @click="onSaveAsTemplate(template)"
                        variant="outline" size="sm">
                        <SaveAllIcon class="size-3 mr-2" />
                        <span class="text-sm">Update from current sheet</span>
                    </Button>
                    <Button v-if="!disabled" :disabled="saveDisabled" type="submit" variant="outline" size="sm">
                        <SaveIcon class="size-3 mr-2" />
                        <span class="text-sm">Save</span>
                    </Button>
                </div>
                <Button @click="$emit('apply', template)" variant="default" size="sm" :disabled="!isUsable">
                    <span class="text-sm">Use Template</span>
                </Button>
            </DialogFooter>
        </component>
    </div>
</template>
