<script setup>
import { LockKeyhole } from 'lucide-vue-next';
import { api } from '@/plugins/api';
import { useSubscription } from '@/composables';


const { subscription } = useSubscription();
const openAdminPortal = async (intent) => {
    const response = await api.get(`/organizations/admin/${intent}`)
    window.open(response.data, '_blank');
}
</script>

<template>
    <div class="flex flex-col w-full  space-y-4">
        <div @click="subscription?.features?.sso && openAdminPortal('sso')"
            class="flex flex-col space-y-2 border border-border rounded-xl p-4"
            :class="{ 'cursor-pointer hover:bg-accent': subscription?.features?.sso, 'opacity-50': !subscription?.features?.sso }">
            <div class="flex space-x-2 flex-row">
                <LockKeyhole class="w-4 h-4 text-primary " />
                <p class="text-sm font-medium">SSO</p>
            </div>
            <p class="text-sm text-muted-foreground">Manage your SSO settings in the admin panel.</p>
        </div>

        <div @click="subscription?.features?.domain_verification && openAdminPortal('domain_verification')"
            class="flex flex-col space-y-2 border border-border rounded-xl p-4"
            :class="{ 'cursor-pointer hover:bg-accent': subscription?.features?.domain_verification, 'opacity-50': !subscription?.features?.domain_verification }">
            <div class="flex space-x-2 flex-row">
                <LockKeyhole class="w-4 h-4 text-primary " />
                <p class="text-sm font-medium">Domain Verification</p>
            </div>
            <p class="text-sm text-muted-foreground">Manage your domain verification settings in the admin panel.</p>
        </div>
    </div>
</template>
