<script setup>
import { List, Plus, Loader2 } from 'lucide-vue-next';
import ProjectSidebarMenuItem from './ProjectSidebarMenuItem.vue';
import { useRouter, useRoute } from 'vue-router';
import { CreateProjectDialog } from '@/services/projects';
import { useRepo, Project } from '@/models';
import {
    SidebarMenu,
    SidebarMenuItem,
    SidebarMenuButton,
    SidebarGroup,
    SidebarGroupLabel,
    SidebarGroupContent,
    SidebarGroupAction
} from '@/components/ui/sidebar';
import { useQuery } from '@tanstack/vue-query';

const router = useRouter();
const route = useRoute();
const projectRepo = useRepo(Project);

const { isLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: async () => await projectRepo.api().get('/projects/')
})
</script>


<template>
    <SidebarGroup>
        <SidebarGroupLabel>Projects</SidebarGroupLabel>
        <CreateProjectDialog>
            <SidebarGroupAction>
                <Plus />
            </SidebarGroupAction>
        </CreateProjectDialog>
        <SidebarGroupContent>
            <SidebarMenu>
                <SidebarMenuItem v-if="isLoading">
                    <SidebarMenuButton disabled>
                        <Loader2 class="animate-spin" />
                        <span>Loading...</span>
                    </SidebarMenuButton>
                </SidebarMenuItem>
                <template v-else>
                    <ProjectSidebarMenuItem
                        v-for="project in projectRepo.where('status', 'active').orderBy('updated_at', 'desc').get()"
                        :key="project.id" :project="project" />
                    <SidebarMenuItem>
                        <SidebarMenuButton tooltip="All Projects" size="lg" @click="router.push({ name: 'projects' })"
                            :is-active="route.name === 'projects'">
                            <List />
                            <span>All Projects</span>
                        </SidebarMenuButton>
                    </SidebarMenuItem>
                </template>
            </SidebarMenu>
        </SidebarGroupContent>
    </SidebarGroup>
</template>