<script setup>
import ColumnPopover from './ColumnPopover.vue'
import { Button } from '@/components/ui/button'
import { PlusIcon, SaveIcon } from 'lucide-vue-next'
import { Badge } from '@/components/ui/badge'
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'
import * as z from 'zod'
import { defineProps, defineEmits, watch } from 'vue'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Input } from '@/components/ui/input'
import { toValue } from 'vue'
import { ColumnTemplate, columnTypes } from '@/services/sheets/models'
const props = defineProps({
    initialValues: { type: Object, required: true },
    disabled: { type: Boolean, default: false }
})

const emit = defineEmits(['submit'])

const formSchema = toTypedSchema(z.object({
    name: z.string().min(1, 'Name is required'),
    description: z.string().min(1, 'Description is required'),
    columns: z.object({
        id: z.any().optional(),
        label: z.string().min(1, 'Label is required'),
        type: z.enum(columnTypes.map(type => type.value)),
        icon: z.any().optional(),
        instructions: z.string().min(1, 'Instructions are required'),
        options: z.preprocess((value) => {
            return value ?? []
        }, z.array(z.string()).default([]))
    }).array()
        .refine(
            (columns) => {
                const labels = columns.map(col => col.label.trim())

                return new Set(labels).size === labels.length
            },
            { message: 'Column names must be unique' }
        )
}))

const form = useForm({
    validationSchema: formSchema,
    initialValues: {
        name: props.initialValues?.name ?? '',
        description: props.initialValues?.description ?? '',
        columns: toValue(props.initialValues?.columns) ?? []
    },
})

const onSubmit = form.handleSubmit((data) => {
    emit('submit', { ...props.initialValues, ...data })
    form.resetForm({
        values: data,
        touched: false,
        dirty: false
    })
})

watch(
    () => props.initialValues,
    (newValues, oldValues) => {
        if (newValues && newValues?.id !== oldValues?.id) {
            form.resetForm({
                values: newValues,
                touched: false,
                dirty: false,
            })
        }
    },
    { deep: true }
)
</script>

<template>
    <form @submit="onSubmit" class="flex flex-col gap-4 h-full">
        <div class="grid grid-cols-2 gap-6 h-full">
            <!-- Left Column -->
            <div class="flex flex-col gap-4">
                <FormField name="name" v-slot="{ componentField }">
                    <FormItem>
                        <FormLabel>Name</FormLabel>
                        <p class="text-sm text-muted-foreground mb-2">
                            Give your sheet template a clear, descriptive name
                        </p>
                        <FormControl>
                            <Input v-bind="componentField" :disabled="disabled" placeholder="Enter template name..." />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                </FormField>

                <FormField name="description" v-slot="{ componentField }">
                    <FormItem>
                        <FormLabel>Description</FormLabel>
                        <p class="text-sm text-muted-foreground mb-2">
                            Provide a brief summary of what this sheet template is used for
                        </p>
                        <FormControl>
                            <Textarea v-bind="componentField" :disabled="disabled"
                                placeholder="Enter template description..." class="min-h-[120px]" />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                </FormField>
            </div>

            <!-- Right Column -->
            <div class="flex flex-col">
                <FormField name="columns" v-slot="{ value: columns }">
                    <FormItem>
                        <div class="flex items-end justify-between mb-2">
                            <div class="flex flex-col gap-4">
                                <FormLabel>Columns</FormLabel>
                                <p class="text-sm text-muted-foreground">
                                    Add columns to your sheet template
                                </p>
                            </div>
                            <ColumnPopover type="button" v-if="!disabled" @submit="(newColumn) => {
                                form.setValues({ columns: new Array(...columns, new ColumnTemplate(newColumn)) })
                            }">
                                <Button type="button" variant="link" size="xs" class="flex flex-row items-center gap-2">
                                    <PlusIcon class="size-3" :stroke-width="2.5" />
                                    <p class="text-sm">Add column</p>
                                </Button>
                            </ColumnPopover>
                        </div>
                        <FormControl>
                            <ScrollArea class="h-[450px] border rounded-lg p-2">
                                <div class="flex flex-col gap-2">
                                    <ColumnPopover type="button" v-for="(column, index) in columns" :column="column"
                                        @submit="($event) => {
                                            const updatedColumns = [...columns]
                                            updatedColumns[index] = new ColumnTemplate({ ...column, ...$event })
                                            form.setValues({ columns: updatedColumns })
                                        }" @delete="() => {
                                            form.setValues({ columns: columns.filter(c => c.id !== column.id) })
                                        }" :key="column.id" :disabled="disabled">
                                        <div
                                            class="flex flex-col space-y-1 bg-muted hover:bg-accent transition-colors items-start p-2 rounded-lg w-full">
                                            <div class="flex items-center justify-between space-x-2">
                                                <component :is="column.icon" class="size-3 text-primary" />
                                                <p class="text-sm text-primary">
                                                    {{ column.label }}
                                                </p>
                                            </div>
                                            <Badge variant="outline" class="text-xs" v-for="option in column.options"
                                                :key="option">
                                                {{ option }}
                                            </Badge>
                                            <p class="text-xs text-left text-muted-foreground">
                                                {{ column.instructions }}
                                            </p>
                                        </div>
                                    </ColumnPopover>
                                </div>
                            </ScrollArea>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                </FormField>
            </div>
        </div>

        <div v-if="!disabled || $slots.default" class="flex items-center gap-2">
            <slot :disabled="!form.meta.value.dirty || form.meta.value.isSubmitting">
                <Button type="submit" :disabled="!form.meta.value.dirty || form.meta.value.isSubmitting"
                    :class="{ 'border-red-500': form.meta.dirty }" variant="outline" size="sm">
                    <SaveIcon class="w-4 h-4 mr-2" />
                    Save
                </Button>
            </slot>
        </div>
    </form>
</template>